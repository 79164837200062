<div class="tutorial-body" [ngStyle]="{ 'padding-top': '25px' }">
    <img *ngIf="contentArr[step].img" class="tutorial-img" [src]="contentArr[step].img">

    <video *ngIf="contentArr[step].mp4" width="560" playsinline="" muted="muted" loop="loop" autoplay="autoplay">
        <source [src]="contentArr[step].mp4" type="video/mp4">
    </video>


    <h1 [innerHtml]="contentArr[step].title"></h1>
    <h3 [innerHtml]="contentArr[step].text"></h3>

    <img style="display: none" [src]="'assets/screenshots/' +  settingsService.getMap().Skin + '/range_image.jpg?v=3'"
        alt="charging station" />
    <img style="display: none"
        [src]="'assets/screenshots/' +  settingsService.getMap().Skin + '/search_click_image.jpg?v=3'"
        alt="charging station" />
    <img style="display: none"
        [src]="'assets/screenshots/' +  settingsService.getMap().Skin + '/voice_instruction_image.jpg?v=3'"
        alt="charging station" />

    <div class="commands" *ngIf="step==3">
        <div class="command" *ngFor="let command of contentArr[step].commands">{{command}}</div>
    </div>

    <button *ngIf="!welcomeDialog" id="prev-btn" class="appbutton" mat-button (click)="onPrevClick()" [disabled]="step <= 0">
        <div>{{ languageService.languageJSON.Tutorial_Previous }}</div>
    </button>
    <button *ngIf="!welcomeDialog" id="next-btn" class="appbutton" mat-button (click)="onNextClick()"
        [disabled]="step > contentArr.length - 2">
        <div>{{ languageService.languageJSON.Global_Next }}</div>
    </button>
</div>