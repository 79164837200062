import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ECar } from 'src/app/models/ecar';
import { Subscription } from 'src/app/models/subscription';
import { AccountService } from 'src/app/services/account.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { LanguageService } from 'src/app/services/language.service';
import { SettingsService } from 'src/app/services/settings.service';

/**
   * adding, managing user subscriptions
   */
@Component({
  selector: 'app-subscriptions-dialog',
  templateUrl: './subscriptions-dialog.component.html',
  styleUrls: ['./subscriptions-dialog.component.scss']
})
export class SubscriptionsDialogComponent implements OnInit {

  public activeStepIndex: number = 0;
  public SubscriptionsDialogTitles: string[] = [];

  public activeSubscriptions: Subscription[] = [];
  public inactiveSubscriptions: Subscription[] = [];
  public newSubscription: Subscription = new Subscription(null, null, null, null, null);

  public compatibilityError: string = null;

  public subscriptionsLoader: boolean = false;
  public selectedSubscriptionplan: number = 2;
  public subscriptionPlans: { type: string, monthlyPrice: number, yearlyPrice: number }[] = [];

  public secondStepFormGroup: FormGroup;
  public voucherIdForm: FormGroup;
  public voucherError: string = null;

  public transferSubscriptionVehicleId: number = null;
  public assignSubscriptionVehicleId: number = null;
  public addNewSubscriptionError: boolean = false;
  public transferSubscriptionError: string = null;

  constructor(public dialogRef: MatDialogRef<SubscriptionsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public languageService: LanguageService, private fb: FormBuilder, public settingsService: SettingsService,
    public accountService: AccountService, private consoleLoggerService: ConsoleLoggerService,
    private _ngZone: NgZone, private matDialog: MatDialog) {
  }

  ngOnInit(): void {
    this.SubscriptionsDialogTitles = [
      this.languageService.languageJSON.SubscriptionsDialog_MySubscriptions,
      this.languageService.languageJSON.SubscriptionsDialog_AddNew,
      this.languageService.languageJSON.SubscriptionsDialog_AddNew,
      this.languageService.languageJSON.SubscriptionsDialog_AddNew,
      this.languageService.languageJSON.SubscriptionsDialog_AddNew,
      this.languageService.languageJSON.SubscriptionsDialog_AddNew,
      this.languageService.languageJSON.SubscriptionsDialog_AddNew,
      this.languageService.languageJSON.SubscriptionsDialog_AddNew,
      this.languageService.languageJSON.CarSelectorDialog_SmartConnection
    ];
    
    this.subscriptionPlans = [{
      "type": this.languageService.languageJSON.SubscriptionsDialog_Plan_Monthly,
      "monthlyPrice": 15,
      "yearlyPrice": 180
    },
    {
      "type": this.languageService.languageJSON.SubscriptionsDialog_Plan_Yearly,
      "monthlyPrice": 10,
      "yearlyPrice": 120
    }];

    if (this.data?.process && this.data?.process == "newSubscriptionPromoCode") {
      this.activeStepIndex = 1;
    }

    if (this.data?.process && this.data?.process == "addNewSubscription") {
      this.setSelectedUserVehicle(this.data.selectedECar);
      this.activeStepIndex = 2;
    }

    if (this.data?.process && this.data?.process == "connectionSuccess") {
      this.activeStepIndex = 8;
    }

    this.secondStepFormGroup = this.fb.group({
      vinValue: ['', [
        Validators.required
      ]]
    });

    this.voucherIdForm = new FormGroup({
      voucherValue: new FormControl()
    });

    this.activeSubscriptions = this.accountService.user.Subscriptions.filter(el => el.Active);
    this.activeSubscriptions = this.activeSubscriptions.map(el => {
      el.CarName = this.accountService.user.getCarNameById(el.UserVehicleId);
      return el;
    });
    this.inactiveSubscriptions = this.accountService.user.Subscriptions.filter(el => !el.Active);
    this.inactiveSubscriptions = this.inactiveSubscriptions.map(el => {
      el.CarName = this.accountService.user.getCarNameById(el.UserVehicleId);
      return el;
    });
    this.addNewSubscriptionError = this.activeSubscriptions.filter(el => el.CarName != null).length >= this.accountService.user.Ecars.length;
  }

  ngAfterViewInit(): void {
    const voucherPromoCode: string = "TRYCONNECT2023";
    this.voucherIdForm.get("voucherValue").setValue(voucherPromoCode);
  }

  public setSelectedUserVehicle(selectedECar: ECar): void {
    if (selectedECar) {
      this.newSubscription.UserVehicleId = selectedECar.userVehicleID;
      this.newSubscription.CarName = this.accountService.user.getCarNameById(selectedECar.userVehicleID);
      this.newSubscription.CarIcon = selectedECar.ECarType.Icon;
    }
  }

  private checkCompatibility(): void {
    this.compatibilityError = null;
    const VIN = this.secondStepFormGroup.get("vinValue").value.replace(/\s+/g, '');
    if (VIN) {

    }
    else {
      this.compatibilityError = this.languageService.languageJSON.SubscriptionsDialog_CompatibilityError_MissingVin;
    }
  }

  // getting plans from backend
  private initSubscriptionPlans(): void {
    this.accountService.getSubscriptionPlans().subscribe((resp) => {

    });
  }

  public activateVoucher(): void {
    if (!this.voucherIdForm.get('voucherValue').value) {
      this.voucherError = "VOUC_ERR_011";
      return;
    }

    this.voucherError = null;
    this.subscriptionsLoader = true;
    this.accountService.activateVoucher(this.voucherIdForm.get('voucherValue').value, this.newSubscription.UserVehicleId.toString()).subscribe((resp) => {
      if (resp.success) {
        this.newSubscription.Expiration = resp.expired ? resp.expired.replace(/\s/, 'T') : resp.expired;
        this.activeStepIndex++;
      }
      else {
        if (resp.original?.failed?.error) {
          this.voucherError = resp.original.failed.error;
        }
      }
      this.subscriptionsLoader = false;
    });
  }

  public startTransferSubscription(subscription: Subscription): void {
    this.transferSubscriptionVehicleId = subscription.UserVehicleId;
    this.newSubscription.Expiration = subscription.Expiration;
    this.activeStepIndex = 1;
  }

  public startAssignSubscription(subscription: Subscription): void {
    this.assignSubscriptionVehicleId = subscription.UserVehicleId;
    this.newSubscription.Expiration = subscription.Expiration;
    this.activeStepIndex = 1;
  }

  public transferSubscription(subscriptionVehicleId): void {
    this.subscriptionsLoader = true;

    this.accountService.updateSubscription(subscriptionVehicleId, this.newSubscription.UserVehicleId).subscribe((resp) => {
      this.subscriptionsLoader = false;

      if (resp.success) {
        this.accountService.getUserSubscriptions().subscribe((resp) => {
          this.activeStepIndex = 7;
        });
      }
    }, (error) => {
      if (error?.error?.error) {
        this.transferSubscriptionError = error.error.error;
      }
      this.subscriptionsLoader = false;
    });
  }

  public getSubscriptionType(SubscriptionTypeId: number): string {
    return this.accountService.getSubscriptionType(SubscriptionTypeId);
  }

  public goBack(): void {
    if (this.activeStepIndex == 6) {
      this.activeStepIndex = 4;
      return;
    }

    if (this.activeStepIndex == 1) {
      this.transferSubscriptionVehicleId = null;
      this.assignSubscriptionVehicleId = null;
    }

    this.activeStepIndex--;
  }

  public nextStep(): void {
    if (this.activeStepIndex == 2) {
      this.checkCompatibility();
      return;
    }

    if (this.activeStepIndex == 4 && this.selectedSubscriptionplan < 2) {
      this.activeStepIndex = 5;
      return;
    }

    if (this.activeStepIndex == 4 && this.selectedSubscriptionplan == 2) {
      this.activeStepIndex = 6;
      return;
    }

    if (this.activeStepIndex == 6) {
      this.activateVoucher();
      return;
    }

    this.activeStepIndex++;
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public finish(): void {
    this.dialogRef.close();
  }
}
