<div class="roadlanes-outer" [ngClass]="{'mobile': !Desktop}" *ngIf="roadLanes.length>0 && roadLanesVisible">
    <div class="roadlanes-panel">
        <div class="roadlane-icon" *ngFor="let roadlane of roadLanes; let i = index"
            [ngClass]="{'highlighted': roadlane.Highlighted}">
            <div class="roadlane-icon-images" *ngIf="!roadlane.Highlighted">
                <img *ngFor="let image of roadlane.Images" class="icon-img" [src]="'assets/icons/' + settingsService.getMap().Skin + '/lane_info/' + image" />
            </div>
            <div class="roadlane-icon-images" *ngIf="roadlane.Highlighted">
                <img *ngFor="let image of roadlane.Images" class="icon-img" [src]="'assets/icons/dark/lane_info/' + image" />
            </div>
            <img class="lane-separator" *ngIf="i!=0" [src]="'assets/icons/' + settingsService.getMap().Skin + '/lane_info/dashed_line.svg'" />
        </div>
    </div>
</div>