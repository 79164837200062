import { Injectable } from '@angular/core';
import 'leaflet';
import * as L from 'leaflet';
import { LatLng, LatLngBounds } from 'leaflet';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilsService } from './utils.service';
import { InputParamsService } from './input-params.service';
import { MobileResolutionService } from './mobile-resolution.service';
import { AccountService } from './account.service';
import { LocationService } from './location.service';
import { WebviewService } from './webview.service';
import { MapSkin, SettingsService } from './settings.service';
import { ConsoleLoggerService } from './console-logger.service';
import { MapElementType } from '../components/map/map.component';
import { Route, RoutePlanElement, RoutePlanType, Turn } from '../models/route';
import { LanguageService } from './language.service';
import { ChargingOperator } from '../models/charging-operator';
import { SearchedRouteElement } from '../models/searched-route-element';
import { ECarType } from '../models/ecar-type';
import { TripPoint } from '../models/trip';
import { ChargerProvider, ChargingGroup, ChargingStation, ChargingStationDetails, Plug } from '../models/charging-station';
import { ECar } from '../models/ecar';
import { Address } from '../models/address';
import * as CryptoJS from 'crypto-js';
import * as pako from 'pako';

export const enum ShowOnMapEnum {
  BackSettings = "backSettings",
  BackHistory = "backHistory"
}

@Injectable({
  providedIn: 'root'
})
export class MapService {

  public firstWebViewLocation: boolean = false;

  private lastCoordinates: LatLng;
  private Navigation: boolean;
  public ObservableNavigation: BehaviorSubject<boolean>;
  private ChargingStations: boolean[];
  public ObservableChargingStations: BehaviorSubject<boolean[]>;
  private SearchedLocation: SearchedRouteElement;
  public ObservableSearchedLocation: BehaviorSubject<SearchedRouteElement>;
  public ObservableSearchedMapElement: BehaviorSubject<{ elementType: MapElementType, mapElement: any }>;
  private SelectedCoordinate: LatLng;
  public ObservableSelectedCoordinate: BehaviorSubject<LatLng>;
  private SelectedCar: ECarType = new ECarType(-1, null, null, null, null, null, null, null, null, null, null, null, "", null, null, null, "", "");
  private SelectedCarIcon: string = "";
  public ObservableSelectedCar: BehaviorSubject<ECar>;

  public ECars: ECarType[] = [];
  public CarTypes: { name: string, subtypes: string[] }[];
  private LastRouteData: Route;
  public ObservableLastRouteData: BehaviorSubject<Route>;

  public ObservableDrawTripToMap: BehaviorSubject<TripPoint[]>;
  private MapBounds: LatLngBounds;
  public ObservableMoveTo: BehaviorSubject<{ latlng: LatLng, zoom: number }>;
  public ObservableCursorColor: BehaviorSubject<boolean>;
  public ObservablePositionMapToRoute: BehaviorSubject<LatLng[]>;
  public ObservableECarsLoaded: BehaviorSubject<{ ECars: ECarType[], CarTypes: { name: string, subtypes: string[] }[] }>;
  public ObservableSetChargingStationRecalc: BehaviorSubject<boolean>;
  public ObservableShowOnMap: BehaviorSubject<{ type: MapElementType | ShowOnMapEnum, data?: any }>;
  public ObservableClearLastOpenedChargingStation: BehaviorSubject<boolean>;
  private tileChargingStations: { [key: string]: ChargingStation[] } = {};
  private tileChargingGroups: { [key: string]: { [zoomlevel: string]: ChargingGroup[] } } = {};
  public chargingStationsArray: ChargingStation[] = [];
  public chargingOperatorsArray: ChargingOperator[] = [];
  private chargingStationVersion: String = "";
  public ObservableActiveInfoSidebarRouteElement: BehaviorSubject<{ type: string, index?: number }>;
  public ObservableOpenModal: BehaviorSubject<string>;

  constructor(private http: HttpClient, private utilsService: UtilsService, private inputParamsService: InputParamsService, public webviewService: WebviewService,
    public mobileResolutionService: MobileResolutionService, public accountService: AccountService, private locationService: LocationService,
    private settingsService: SettingsService, private consoleLoggerService: ConsoleLoggerService,
    private languageService: LanguageService) {

    this.initObservables();

    this.locationService.initWebViewLocation();
    this.inputParamsService.initWebViewSetBattery();
    this.SelectedCarIcon = "/assets/icons/" + this.settingsService.getMap().Skin + "/ecars/car_icon_default.svg";

    this.initSubscriptions();
  }

  private initObservables(): void {
    this.ObservableChargingStations = new BehaviorSubject<boolean[]>(this.ChargingStations);
    this.ObservableSearchedLocation = new BehaviorSubject<SearchedRouteElement>(this.SearchedLocation);
    this.ObservableSearchedMapElement = new BehaviorSubject<{ elementType: MapElementType, mapElement: Address }>(null);
    this.ObservableSelectedCoordinate = new BehaviorSubject<LatLng>(this.SelectedCoordinate);
    this.ObservableSelectedCar = new BehaviorSubject<ECar>(null);
    this.ObservableLastRouteData = new BehaviorSubject<Route>(this.LastRouteData);
    this.ObservableDrawTripToMap = new BehaviorSubject<TripPoint[]>(null);
    this.ObservableNavigation = new BehaviorSubject<boolean>(this.Navigation);
    this.ObservableMoveTo = new BehaviorSubject<{ latlng: LatLng, zoom: number }>(null);
    this.ObservableCursorColor = new BehaviorSubject<boolean>(null);
    this.ObservablePositionMapToRoute = new BehaviorSubject<LatLng[]>(null);
    this.ObservableECarsLoaded = new BehaviorSubject<{ ECars: ECarType[], CarTypes: { name: string, subtypes: string[] }[] }>(null);
    this.ObservableSetChargingStationRecalc = new BehaviorSubject<boolean>(null);
    this.ObservableShowOnMap = new BehaviorSubject<{ type: MapElementType | ShowOnMapEnum, data?: any }>(null);
    this.ObservableClearLastOpenedChargingStation = new BehaviorSubject<boolean>(null);
    this.ObservableActiveInfoSidebarRouteElement = new BehaviorSubject<{ type: string, index?: number }>(null);
    this.ObservableOpenModal = new BehaviorSubject<string>(null);
  }

  private initSubscriptions(): void {
    // setting first position for webview
    if (this.webviewService.IsGPSTWebview()) {
      const firstPositionRef = this.locationService.ObservableLastPosition.subscribe((resp) => {
        this.consoleLoggerService.log(resp, this.firstWebViewLocation);
        if (resp && resp.Latitude && resp.Longitude && !this.firstWebViewLocation) {
          this.inputParamsService.setStartCoordsParams(new LatLng(resp.Latitude, resp.Longitude));
          this.setMoveTo(new LatLng(resp.Latitude, resp.Longitude));
          this.firstWebViewLocation = true;
          firstPositionRef.unsubscribe();
        }
      });
    }

    this.accountService.ObservableSelectedUserCar.subscribe((resp: ECar) => {
      if (resp) {
        this.ObservableSelectedCar.next(resp);
        this.setCarIcon(resp.ECarType);
        const chargingStations = [false, false, false, false, false, false];
        for (let i = 0; i < resp.ECarType.ChargerTypes.length; i++) {
          chargingStations[resp.ECarType.ChargerTypes[i] - 1] = true;
        }
        this.setChargingStations(chargingStations);

        if (resp.settings) {
          if (this.inputParamsService.getSelectedMode() == "route") {
            resp.settings.batterySafetyLimit = resp.settings.batterySafetyLimitRoute;
          }
          else {
            resp.settings.batterySafetyLimit = resp.settings.batterySafetyLimitRha;
          }
          this.inputParamsService.setECarSettings(resp.settings);
        }
      }
    });

    this.accountService.ObservableIsAuthorized.subscribe((resp) => {
      if (resp == false) {
        this.initCar();
      }
    });

    this.settingsService.ObservableMap.subscribe((resp) => {
      if (resp && this.SelectedCar) {
        this.setCarIcon(this.getSelectedCar());
      }
    });
  }

  public initAllVehicles(allVehicles: ECarType[]): void {
    this.ECars = allVehicles;
    this.CarTypes = this.utilsService.collectCarTypes(this.ECars);

    this.initCar();

    this.ObservableECarsLoaded.next({ ECars: this.ECars, CarTypes: this.CarTypes });
  }

  public initCar(): void {
    // load cookies
    let selectedCarId: string = null;
    let selectedCarName: string = null; /** legacy checking first init for older non auth users */
    let selectedCar: ECarType = null;
    if (this.inputParamsService.AcceptCookies) {
      selectedCarName = localStorage.getItem('selectedCar');
      selectedCarId = localStorage.getItem('selectedCarId');

      if (selectedCarName == null && selectedCarId == null) {
        this.ObservableOpenModal.next("first-open");
        this.selectDefaultCar();
      }
      else if (selectedCarId) {
        selectedCar = this.ECars.find((ecar) => { return ecar.Id === parseInt(selectedCarId) });
        if (selectedCar) {
          this.setSelectedCar(selectedCar, false);
        }
        else {
          this.selectDefaultCar();
          this.ObservableOpenModal.next("car-selector");
        }
      }
      else {
        this.selectDefaultCar();
        this.ObservableOpenModal.next("car-selector");
      }
    }

    let chargingStations = [];
    let chargingStationsStorage;
    if (this.inputParamsService.AcceptCookies) {
      chargingStationsStorage = localStorage.getItem("chargingStations");
    }

    if (chargingStationsStorage) {
      const csArray = chargingStationsStorage.split(",");
      for (let i = 0; i < csArray.length; i++) {
        if (csArray[i] == "true") {
          chargingStations.push(true);
        }
        if (csArray[i] == "false") {
          chargingStations.push(false);
        }
      }
    }
    else {
      chargingStations = [false, false, false, false, false, false];
      for (let i = 0; i < this.SelectedCar.ChargerTypes.length; i++) {
        chargingStations[this.SelectedCar.ChargerTypes[i] - 1] = true;
      }
    }
    this.setChargingStations(chargingStations);
    const ecar: ECarType = this.getSelectedCar();
    if (ecar) {
      ecar.ChargerTypes = [];
      for (let i = 0; i < chargingStations.length; i++) {
        if (chargingStations[i]) {
          ecar.ChargerTypes.push(i + 1);
        }
      }
      this.setSelectedCar(ecar, true);
    }
  }

  public setChargingStationsArray(chargingstationsArray: ChargingStation[]): void {
    this.chargingStationsArray = chargingstationsArray;
  }

  public setOperatorsArray(chargingOperatorsArray: ChargingOperator[]): void {
    this.chargingOperatorsArray = chargingOperatorsArray;
  }

  public selectDefaultCar(): void {
    let defCarId: number = this.utilsService.defaultCarIndex;
    let defCar: ECarType = this.ECars.find((ecar) => { return ecar.Id === defCarId });
    this.setSelectedCar(defCar, false);
  }

  public getCarByID(id: number): ECarType {
    return this.ECars.find((ecar) => { return ecar.Id === id });
  }

  public setNavigation(param: boolean): void {
    this.Navigation = param;
    this.ObservableNavigation.next(param);
    this.inputParamsService.setNavigation(param);
  }

  public getNavigation(): boolean {
    return this.Navigation;
  }

  public setLastCoordinates(lat: number, lng: number): void {
    this.lastCoordinates = new LatLng(lat, lng);
  }

  public getLastCoordinates(): LatLng {
    return this.lastCoordinates;
  }

  public setChargingStations(params: boolean[]): void {
    this.ChargingStations = params;
    this.ObservableChargingStations.next(params);
  }

  public getChargingStations(): boolean[] {
    return this.ChargingStations;
  }

  public getChargingStationByID(id: string): ChargingStation {
    return this.chargingStationsArray.find((item) => {
      return item.id == id;
    });
  }

  public getChargingOperatorByID(id: number): ChargingOperator {
    return this.chargingOperatorsArray.find((item) => {
      return item.id == id;
    });
  }

  public setSearchedLocation(searchedEl: SearchedRouteElement): void {
    this.SearchedLocation = searchedEl;
    this.ObservableSearchedLocation.next(searchedEl);
  }

  public setSearchedMapElement(elementType: MapElementType, mapElement: any): void {
    this.ObservableSearchedMapElement.next({ elementType: elementType, mapElement: mapElement });
  }

  public getSearchedLocation(): SearchedRouteElement {
    return this.SearchedLocation;
  }

  public setSelectedCoordinate(latlon: LatLng): void {
    this.SelectedCoordinate = latlon;
    this.ObservableSelectedCoordinate.next(latlon);
  }

  public setMoveTo(latlon: LatLng): void {
    this.ObservableMoveTo.next({ latlng: latlon, zoom: 12 });
  }

  public getSelectedCoordinate(): LatLng {
    return this.SelectedCoordinate;
  }

  public setSelectedCar(ecar: ECarType, setCarChargingStations: boolean): void {
    this.SelectedCar = new ECarType(ecar.Id, ecar.CarWeight, ecar.ChargerTypes, ecar.DesignCapacity, ecar.DragCoefficient, ecar.DragCross, ecar.Name, ecar.Range, ecar.FactoryRange, ecar.FactoryRangeSource, ecar.TopSpeed,
      ecar.TotalPower, ecar.Icon, ecar.ChargePower, ecar.FastChargePower, ecar.Type, ecar.Subtype, ecar.GreenCarsLink);
    if (ecar.Icon) {
      this.setCarIcon(ecar);
    }

    if (setCarChargingStations) {
      const chargingStations = [false, false, false, false, false, false];
      for (let i = 0; i < this.SelectedCar.ChargerTypes.length; i++) {
        chargingStations[this.SelectedCar.ChargerTypes[i] - 1] = true;
      }
      this.setChargingStations(chargingStations);
    }

    if (this.inputParamsService.AcceptCookies) {
      localStorage.setItem('selectedCarId', this.SelectedCar.Id.toString());
    }

    const nullUserEcar = new ECar();
    nullUserEcar.ECarType = ecar;
    nullUserEcar.name = "";
    nullUserEcar.selected = true;
    nullUserEcar.userVehicleID = -1;
    this.ObservableSelectedCar.next(nullUserEcar);
  }

  private setCarIcon(ecar: ECarType): void {
    if (ecar.Icon.length > 0) {
      this.SelectedCarIcon = "/assets/icons/" + this.settingsService.getMap().Skin + "/ecars/" + ecar.Icon + ".svg";
    }
    else {
      this.SelectedCarIcon = "/assets/icons/" + this.settingsService.getMap().Skin + "/ecars/car_icon_default.svg";
    }
  }

  public getSelectedCar(): ECarType {
    if (this.accountService.getIsAuthorized() && this.accountService.user.getSelectedCar()) {
      return this.accountService.user.getSelectedCar();
    }
    return this.SelectedCar;
  }

  public getSelectedCarIcon(): string {
    return this.SelectedCarIcon;
  }

  public getLastRouteData(): Route {
    return this.LastRouteData;
  }

  public setLastRouteData(params: Route) {
    this.LastRouteData = params;
    this.ObservableLastRouteData.next(params);
  }

  public getMapBounds(): LatLngBounds {
    return this.MapBounds;
  }

  public setMapBounds(bounds: LatLngBounds): void {
    this.MapBounds = bounds;
  }

  public getReverseGeocode(lat: number, lng: number): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      let reversegeocodeUrl;
      if (!this.utilsService.isProductionUrl()) {
        reversegeocodeUrl = "https://staging.evnavigation.com/ecar/reversegeocode.php";
      }
      else {
        reversegeocodeUrl = "https://evnavigation.com/ecar/reversegeocode.php";
      }
      this.http.get(reversegeocodeUrl + "?lat=" + lat + "&lon=" + lng).subscribe((resp: any) => {
        observer.next(this.parseAddressString2(resp, lat, lng));
        observer.complete();
      }, (error) => {
        observer.next(error);
        observer.complete();
      })
    });
  }

  public parseAddressString(addressObj: any, lat: number, lon: number): string {
    let address = "";
    if (addressObj.Street) {
      address += addressObj.Street + " ";
    }
    if (addressObj.Street && addressObj.Housenumber) {
      address += addressObj.Housenumber + ", ";
    }
    if (addressObj.City) {
      address += addressObj.City + ", ";
    }
    if (addressObj.County) {
      address += addressObj.County + ", ";
    }
    if (addressObj.Country) {
      address += addressObj.Country;
    }
    if (addressObj.Country == "#country_") {
      address = Math.round(lat * 1000) / 1000 + " " + Math.round(lon * 1000) / 1000;
    }

    return address;
  }

  private parseAddressString2(addressObj: any, lat: number, lon: number): string {
    let address = "";
    if (addressObj.street) {
      address += addressObj.street + " ";
    }
    if (addressObj.street && addressObj.housenumber) {
      address += addressObj.housenumber + ", ";
    }
    if (addressObj.city) {
      address += addressObj.city + ", ";
    }
    if (addressObj.county) {
      address += addressObj.county + ", ";
    }
    if (addressObj.country) {
      address += addressObj.country;
    }
    if (addressObj.country == "#country_") {
      address = Math.round(lat * 1000) / 1000 + " " + Math.round(lon * 1000) / 1000;
    }

    return address;
  }

  // generating arrow layer for a lod level using element indexes
  /*public generateTrackArrows(latlonArray: TripPoint[], indexes: number, lodLevelMultiply: number): L.Marker[] {
    const markersArray = [];
    const arrowsArray = [];
    // define arrows between points
    for (let i = 1; i < indexes.length; i++) {
      const currElement = latlonArray[indexes[i]];
      const prevElement = latlonArray[indexes[i - 1]];
      if (prevElement.lat != currElement.lat || prevElement.lng != currElement.lng) {

        const arrowArrayLatLon = new L.LatLng((currElement.lat + prevElement.lat) / 2, (currElement.lng + prevElement.lng) / 2);
        const arrowArrayRotation = 180 + this.utilsService.angleFromCoordinate(prevElement.lat, prevElement.lng, currElement.lat, currElement.lng);
        arrowsArray.push({ latlon: arrowArrayLatLon, rotation: arrowArrayRotation });
      }
    }
    // keep arrows, with a defined distance between each other
    let lastPoint = null;
    for (let j = 0; j < arrowsArray.length; j++) {
      const currentPoint = arrowsArray[j].latlon;
      if (j == 0 || (lastPoint.distanceTo(currentPoint) > lodLevelMultiply)) {
        const arrowIcon: L.DivIcon = L.divIcon({
          className: 'arrow-icon',
          html: '<img src="assets/icons/default/map/direction_arrow.png" style="width: 12px; height: 12px; transform:rotate(' + arrowsArray[j].rotation + 'deg);" />',
          iconSize: null,
          iconAnchor: [6, 6]
        });
        markersArray.push(L.marker(arrowsArray[j].latlon, { icon: arrowIcon, interactive: false }));
        lastPoint = currentPoint;
      }
    }
    return markersArray;
  }*/

  public getRoutePlanByType(routePlanList: RoutePlanElement[], type: RoutePlanType): RoutePlanElement[] {
    return routePlanList.filter((routePlanEl) => { return routePlanEl.Type == type });
  }

  public getRoutePlanStartPoint(routePlanList: RoutePlanElement[]): RoutePlanElement {
    return routePlanList.find((routePlanEl) => { return routePlanEl.Type == RoutePlanType.StartPoint });
  }

  public getRoutePlanEndPoint(routePlanList: RoutePlanElement[]): RoutePlanElement {
    return routePlanList.find((routePlanEl) => { return routePlanEl.Type == RoutePlanType.EndPoint });
  }

  public getSingleAndMultipleChargingStations(routePlanList: RoutePlanElement[]): { SingleChargingStations: RoutePlanElement[], MultiChargingStations: RoutePlanElement[][] } {
    const resp: { SingleChargingStations: RoutePlanElement[], MultiChargingStations: RoutePlanElement[][] } = { SingleChargingStations: [], MultiChargingStations: [] };
    const chargingStations: RoutePlanElement[] = [... this.getRoutePlanByType(routePlanList, RoutePlanType.Charger) as RoutePlanElement[]];

    // collect same location charging stations
    let duplicates = chargingStations.filter((item, index) => {
      return chargingStations.map((ChargingStationElement) => { return ChargingStationElement.ChargingStationId }).indexOf(item.ChargingStationId) !== index;
    });

    // collect multiple stop charging stations
    for (let i = 0; i < duplicates.length; i++) {
      let multipleChargingStationElement: RoutePlanElement[] = [];
      for (let j = 0; j < chargingStations.length; j++) {
        if (duplicates[i].ChargingStationId == chargingStations[j].ChargingStationId) {
          multipleChargingStationElement.push(chargingStations[j])
        }
      }
      resp.MultiChargingStations.push(multipleChargingStationElement);
    }

    // remove multiple stop charging stations from the original array
    for (let i = 0; i < duplicates.length; i++) {
      for (let j = chargingStations.length - 1; j >= 0; j--) {
        if (duplicates[i].ChargingStationId == chargingStations[j].ChargingStationId) {
          chargingStations.splice(j, 1);
        }
      }
    }

    resp.SingleChargingStations = chargingStations;

    return resp;
  };

  public initTurnIconAndInstruction(turns: Turn[]): Turn[] {
    let waypointIdx = 0;
    turns.forEach((turn, index) => {
      if (turn.TypeIndex < 12 || turn.TypeIndex >= 93) {
        if (turn.TypeIndex == 95) {
          turn.Instruction[0] = this.utilsService.turnList[turn.TypeIndex].name;
          turn.Icon[0] = `exit_roundabout${turn.RoundaboutExitNumber}.svg`;
        } else {
          turn.Instruction[0] = this.utilsService.turnList[turn.TypeIndex].name;
          turn.Icon[0] = this.utilsService.turnList[turn.TypeIndex].icon;
        }
        // left sided roundabouts
        if (turn.IsLeftHandTraffic && (turn.RoundaboutExitNumber == 2 || turn.TypeIndex == 93 || turn.TypeIndex == 95)) {
          turn.Icon[0] = turn.Icon[0].substring(0, turn.Icon[0].length - 4) + "_eng.svg";
        }
        // waypoint indices
        if (turn.TypeIndex == 103) {
          waypointIdx++;
          turn.Icon[0] = turn.Icon[0].substring(0, turn.Icon[0].length - 4) + waypointIdx + ".svg";
        }
      }
      else {
        /// COMBINED TURNS
        const actTurn = { ... this.utilsService.turnList[turn.TypeIndex] };
        const icon = actTurn.id.toLowerCase().split("_and_");
        const combinedName = actTurn.name.toUpperCase().split(` ${this.languageService.languageJSON.Global_And.toUpperCase()} `);
        turn.Instruction[0] = combinedName[0] + ` ${this.languageService.languageJSON.Global_And.toUpperCase()}`;
        turn.Instruction[1] = combinedName[1];
        turn.Icon[0] = icon[0] + ".svg";
        turn.Icon[1] = icon[1] + ".svg";
      }
    });

    return turns;
  }

  public setChargingStationVersion(version: String): void {
    this.chargingStationVersion = version;
  }

  public getChargingStationVerions(): String {
    return this.chargingStationVersion;
  }

  public getTileChargingStations(): { [key: string]: ChargingStation[] } {
    return this.tileChargingStations;
  }

  public getTileChargingGroups(): { [key: string]: { [zoomlevel: string]: ChargingGroup[] } } {
    return this.tileChargingGroups;
  }

  public hasTileDownloaded(tileX: number, tileY: number): boolean {
    const tileId = this.getTileId(tileX, tileY);
    return this.tileChargingStations.hasOwnProperty(tileId);
  }

  public getChargingStationTile(tileX: number, tileY: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
    const tileId = this.getTileId(tileX, tileY);
    const typeFlag = this.getTypeFlag();

    function xzPlb9EUTgTzPT0sOuSf1LXr9iAPQsJ(bStr, jk) {
      const fibSeq = "WQ48EJxAMUAQRAM5GEovngvEyDW3LqN";
      let rslt = '', lpIdx = jk ? 16 : 32;
      while (rslt.length < 16) {
          const cIdx = fibSeq.charCodeAt(lpIdx++ % fibSeq.length) % bStr.length;
          rslt += bStr.charAt(cIdx);
      }
      return CryptoJS.enc.Utf8.parse(rslt);
    }
  
    const errorStr = 'The server has detected a temporal anomaly in the request timestamp that conflicts with the current server time. This may occur due to discrepancies in client-server synchronization or due to unintentional time travel attempts.';	
    const hash = CryptoJS.SHA256(tileId + '.json' + errorStr).toString(CryptoJS.enc.Hex);
  
    let chargingTileUrl = this.utilsService.isProductionUrl() ?
      `https://evnavigation.com/chargingstations/dattiles/${typeFlag}/${tileId}.dat.${hash}?ver=${this.chargingStationVersion}` :
      `https://staging.evnavigation.com/chargingstations/dattiles/${typeFlag}/${tileId}.dat.${hash}?ver=${this.chargingStationVersion}`;
  
    this.http.get(chargingTileUrl, { responseType: 'arraybuffer' }).subscribe((encryptedData: ArrayBuffer) => {
      try {
        const wordArray = CryptoJS.lib.WordArray.create(new Uint8Array(encryptedData));
        const decrypted = CryptoJS.AES.decrypt({ ciphertext: wordArray }, xzPlb9EUTgTzPT0sOuSf1LXr9iAPQsJ(errorStr, true), { iv: xzPlb9EUTgTzPT0sOuSf1LXr9iAPQsJ(errorStr, false) });
        const binaryDataLength = decrypted.sigBytes;
        const binaryData = new Uint8Array(binaryDataLength);
        for (let i = 0; i < binaryDataLength; i += 4) {
          const word = decrypted.words[Math.floor(i / 4)];
          binaryData[i] = (word >>> 24) & 0xFF;
          binaryData[i + 1] = (word >>> 16) & 0xFF;
          binaryData[i + 2] = (word >>> 8) & 0xFF;
          binaryData[i + 3] = word & 0xFF;
        }
        const decompressed = pako.inflate(binaryData, { to: 'string' });
        const resp = JSON.parse(decompressed);
    
        this.tileChargingStations[tileId] = this.parseChargingStationsTileArray(resp.chargingstations);
        this.tileChargingGroups[tileId] = this.parseChargingGroupTileArray(resp.groups);
    
        observer.next(true);
        observer.complete();
        } catch (error) {
          console.error('Error processing data', error);
          observer.next(false);
          observer.complete();
        }
      });
    });
  }

  public getTileId(tileX: number, tileY: number): string {
    const normTiles = this.getNormTiles(tileX, tileY);
    const typeFlag = this.getTypeFlag();

    return `${normTiles}_${typeFlag}`;
  }

  private getNormTiles(tileX: number, tileY: number): string {
    const tileXNorm = tileX.toString().padStart(3, '0');
    const tileYNorm = tileY.toString().padStart(2, '0');

    return `${tileXNorm}_${tileYNorm}`;
  }

  public getTypeFlag(): string {
    let flag = 0;
    for (let type of this.getSelectedCar().ChargerTypes) {
      flag |= (1 << (type - 1));
    }
    
    return flag.toString().padStart(2, '0');
  }

  private parseChargingStationsTileArray(tileArray): ChargingStation[] {
    const chargingStationArray: ChargingStation[] = [];

    for (let i = 0; i < tileArray.length; i++) {
      if (tileArray[i][5][0][1] > 1) {
        let types = "";
        let maxpower: number = 0;
        for (let j = 0; j < tileArray[i][5].length; j++) {
          if (tileArray[i][5][j][1] > maxpower) {
            maxpower = tileArray[i][5][j][1];
          }
          types += tileArray[i][5][j][0].toString();
        }

        let img = "";
        if (maxpower > 35) {
          img = "cs_big3";
        }
        else if (maxpower > 10) {
          img = "cs_big2";
        }
        else {
          img = "cs_big1";
        }
        if (this.settingsService.getMap().Skin === MapSkin.Light) {
          img += "_light"
        }

        const charger: ChargingStation = {
          id: tileArray[i][0].toLowerCase(),
          lat: tileArray[i][2],
          lon: tileArray[i][3],
          minZoomLevel: tileArray[i][4],
          plugs: tileArray[i][5].map((el) => {
            const plug: Plug = { type: el[0], power: el[1] }
            return plug;
          }),
          img: img,
          types: types,
          maxpower: maxpower,
          opid: tileArray[i][1]
        };

        chargingStationArray.push(charger);
      }
    }

    return chargingStationArray;
  }

  private parseChargingGroupTileArray(tileArray): { [key: string]: ChargingGroup[] } {
    const groupTileArray = {};
    tileArray.forEach(zoomLevelArray => {
      groupTileArray[zoomLevelArray[0]] = zoomLevelArray[1].map((chargingGroup: any) => {
        const group: ChargingGroup = {
          lat: chargingGroup[0],
          lon: chargingGroup[1],
          maxpower: chargingGroup[2],
          powerid: this.getPowerId(chargingGroup[2]),
          count: chargingGroup[3]
        }
        return group;
      });
    });

    return groupTileArray;
  }

  private getPowerId(chargePower) {
    if (chargePower > 35) {
      return 3;
    }
    else if (chargePower > 10) {
      return 2;
    }
    else {
      return 1;
    }
  }

  public getChargingStationDetail(chargingStationIds: string[]): Observable<ChargingStationDetails[]> {
    return new Observable((observer: Observer<ChargingStationDetails[]>) => {

      let chargingStationDetailUrl = this.utilsService.isProductionUrl() ?
        `https://evnavigation.com/ecar/get_chargingstation_detail.php` :
        `https://staging.evnavigation.com/ecar/get_chargingstation_detail.php`;

      this.http.post(chargingStationDetailUrl, { id: chargingStationIds.map((el) => { return el.toLocaleLowerCase() }) }).subscribe((resp: any) => {

        const chargingStationDetails = resp.map((el, index) => {

          const pattern = /^[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}$/;
          let provider;
          /** ecomovement charger */
          if (pattern.test(chargingStationIds[index])) {
            provider = ChargerProvider.Ecomovement;
          }
          /** ocm charger */
          else {
            provider = ChargerProvider.OCM;
          }

          let img = "";
          if (el.maxpower > 35) {
            img = "cs_big3";
          }
          else if (el.maxpower > 10) {
            img = "cs_big2";
          }
          else {
            img = "cs_big1";
          }
          if (this.settingsService.getMap().Skin === MapSkin.Light) {
            img += "_light"
          }

          const csDetails: ChargingStationDetails = {
            id: el.id,
            provider: provider,
            lat: el.lat,
            lon: el.lon,
            plugs: el.plugs,
            opid: el.opid,
            img: img,
            cost: el.cost,
            email: el.email,
            phone: el.phone,
            title: el.title
          }

          return csDetails;
        });

        observer.next(chargingStationDetails);
        observer.complete();
      }, (error) => {
        observer.next(null);
        observer.complete();
      })
    });
  }

  public getChargingStationListStatus(latMin: number, latMax: number, lonMin: number, lonMax: number, usedPlugTypes: number[]): Observable<any> {
    return new Observable((observer: Observer<any>) => {

      const postData = JSON.stringify({ "latMin": latMin, "latMax": latMax, "lonMin": lonMin, "lonMax": lonMax, "usedPlugTypes": usedPlugTypes });

      let chargingStationDetailUrl = this.utilsService.isProductionUrl() ?
        `https://evnavigation.com/ecar/get_chargingstation_status.php ` :
        `https://staging.evnavigation.com/ecar/get_chargingstation_status.php`;

      this.http.post(chargingStationDetailUrl, postData).subscribe((resp: any) => {

        const chargerStatusList = {};
        for (let i = 0; i < resp.length; i++) {
          chargerStatusList[resp[i][0]] = resp[i][1];
        }

        observer.next(chargerStatusList);
        observer.complete();
      }, (error) => {
        observer.next(null);
        observer.complete();
      })
    });
  }
}
