<!-- debug on off --->
<!--<section class="debug-car" *ngIf="!production">
  <mat-checkbox [(ngModel)]="carNewCalc" (change)="getData(inputParamsService.getRangeParams())">New Car Calc</mat-checkbox>
</section>-->
<!--Top-right elements-->
<div class="top-right" [hidden]="navigation || showOnMap">
  <!--Profile-->
  <app-profile [loadingMap]="loadingMap"></app-profile>
</div>

<!-- Compare indicators -->
<div class="compare-green" *ngIf="compare">
  <div class="icon"></div> Range using terrain
</div>
<div class="compare-red" *ngIf="compare">
  <div class="icon"></div> Range not using terrain
</div>

<!-- Cookie policy -->
<div class="cookie-policy" *ngIf="cookiePolicyAgreed == false">
  <div class="cookie-text">
    <p>{{ languageService.languageJSON.App_Cookies }}<a (click)="openPrivacyPolicyDialog()">{{
        languageService.languageJSON.App_Cookies_Link }}</a>
    </p>
  </div>
  <div class="cookie-buttons">
    <div class="btn" (click)="acceptCookies()">{{ languageService.languageJSON.Global_Ok}}</div>
  </div>
</div>

<!-- Address -->
<app-address-panel *ngIf="showOnMap && showOnMap.type == MapElementType.Address"
  [addressType]="showOnMap.data"></app-address-panel>

<!-- Chargers -->
<app-charger-panel
  *ngIf="showOnMap && (showOnMap.type==MapElementType.FavoriteChargingStation || showOnMap.type==MapElementType.DisabledChargingStation)"
  [charger]="showOnMap.data" [chargerType]="showOnMap.type"></app-charger-panel>

<!-- History -->
<app-history-panel (loadingHistoryChanged)="loadingHistoryChanged($event)" [history]="showOnMap.data"
  *ngIf="showOnMap && showOnMap.type==MapElementType.RouteHistory"></app-history-panel>

<!-- Bottom -->
<div class="info-text-outer" *ngIf="!mobileResolution" [hidden]="showOnMap">
  <div class="info-text attach-right" *ngIf="!mobileResolution"
    [ngClass]="{ 'hide': mobileResolution && mobileVisiblePanel !='' && mobileVisiblePanel != undefined, 'mobileresolution': mobileResolution}">

    <div [matTooltip]="languageService.languageJSON.App_Tooltip_BackToYourLocation" matTooltipShowDelay="500"
      *ngIf="selectedMode == 'rha' || navigation" (click)="setStartCoordsToYourGeolocation()"
      matTooltipClass="google-tooltip" class="info locate-icon" target="_blank" aria-label="Back to your location">
      <img alt="locate" [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/locate_icon.svg'"
        alt="find location" />
    </div>

    <div class="info fullscreen-icon" *ngIf="isFullscreen"
      [matTooltip]="languageService.languageJSON.App_Tooltip_LeaveFullscreen" matTooltipShowDelay="500"
      matTooltipClass="google-tooltip" (click)="toggleFullscreen()">
      <img [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/map_icon_fullscreen_exit.svg'"
        alt="exit fullscreen" />
    </div>

    <div class="info fullscreen-icon" *ngIf="!isFullscreen"
      [matTooltip]="languageService.languageJSON.App_Tooltip_GoFullscreen" matTooltipShowDelay="500"
      matTooltipClass="google-tooltip" (click)="toggleFullscreen()">
      <img [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/map_icon_fullscreen.svg'"
        alt="enter fullscreen" />
    </div>

    <div class="info settings-icon" [hidden]="mobileResolution"
      [matTooltip]="languageService.languageJSON.App_Tooltip_OpenSettings" matTooltipShowDelay="500"
      matTooltipClass="google-tooltip">
      <img [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/settings_icon.svg'" (click)="openSettings()"
        alt="open unit" />
    </div>

    <div class="info info-icon" [matTooltip]="languageService.languageJSON.Global_Info" matTooltipShowDelay="500"
      matTooltipClass="google-tooltip">
      <img alt="info" [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/info_icon.svg'"
        (click)="openFeedbackDialog()" alt="Info" />
    </div>

    <div class="info info-icon" [matTooltip]="'Android Auto / Apple CarPlay'" matTooltipShowDelay="500"
      matTooltipClass="google-tooltip">
      <img alt="info" [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/dashboard_icon.svg'"
        (click)="openSubscriptionsPromo()" alt="Info" />
      <span class="blue-badge">New</span>
    </div>

  </div>
  <app-voice-input class="voice-button" [Desktop]="true"></app-voice-input>
</div>

<!--Left sidebar-->
<app-info-sidebar [hidden]="showOnMap || mobileVisiblePanel=='wind' || (mobileVisiblePanel=='tracking' && mobileResolution)
    || (navigation && mobileResolution)
    || (mobileVisiblePanel=='menudropdown' && mobileResolutionService.isPortrait() === true ) 
    || (mobileVisiblePanel!='' && !mobileResolutionService.getDevice().mobile() && mobileResolutionService.isPortrait() === false)
    || (mobileResolution &&  selectedMode=='rha')" [ngClass]="{'closed-info-sidebar': (mobileVisiblePanel!='' && mobileResolutionService.isPortrait() === true ) ||
  (mobileVisiblePanel!='' && mobileVisiblePanel!='menudropdown' && mobileResolutionService.isPortrait() === false)}"
  [windHider]=" windHider" [loadingMap]="loadingMap">
</app-info-sidebar>

<!-- Slider sidebar -->
<app-slider-sidebar [navigation]="navigation"
  [ngClass]="{'show': !mobileResolution || (mobileVisiblePanel=='car' || mobileVisiblePanel=='car-open' || mobileVisiblePanel=='manageCars')}"
  [hidden]="showOnMap">
</app-slider-sidebar>

<!-- Loading spinner -->
<mat-spinner *ngIf="loadingMap=='circle' || navigationRecalculateRoute" id="map-loading-spinner" strokeWidth="6">
</mat-spinner>
<div class="recalc-text" *ngIf="navigationRecalculateRoute">{{ languageService.languageJSON.Map_Recalculate }}</div>

<!-- Map component -->
<app-map #map *ngIf="!navigation" [ngClass]="{ 'disableclick': loadingMap!=null, 'route-mode' : selectedMode=='route'}"
  [showSliders]="!mobileResolution || (mobileVisiblePanel=='car' || mobileVisiblePanel=='car-open' || mobileVisiblePanel=='manageCars')">
</app-map>

<!-- Navigation component with map -->
<app-navigation #navigation *ngIf="navigation==true"
  [ngClass]="{'hud-opened': hudOpened, 'two-el-hud': hudComputersLength===2, 'one-el-hud': hudComputersLength===1}">
</app-navigation>

<!-- Search backdrop -->
<div [hidden]="mobileVisiblePanel!='search'" id="pac-backdrop" (click)="setMobileVisiblePanel('')"></div>

<!-- Wind/temp component -->
<app-wind-tab class="desktop-wind-tab" [hidden]="showOnMap || navigation || mobileResolution" #windTabComponent
  [ngClass]="{'route':  selectedMode=='route'}"></app-wind-tab>

<!-- Mobile Main Menu -->
<app-mobile-main-menu *ngIf="!navigation && mobileResolution" [loadingMap]="loadingMap" [noRoute]="noRoute"
  [failedPlan]="failedPlan" [hidden]="showOnMap || mobileVisiblePanel=='search' && selectedMode=='route'" [ngClass]="{'route' : selectedMode=='route',
    'closed-mobile-main-menu': mobileVisiblePanel!='' &&  mobileVisiblePanel!='menudropdown' && mobileVisiblePanel!='search',
    'mobile-fullscreen': mobileVisiblePanel=='search' }">
</app-mobile-main-menu>

<div class="mobile-bottom-left-menu-portrait" [hidden]="showOnMap" *ngIf="!navigation && mobileResolution">
  <img class="mobile-selected-car" [src]="mapService.getSelectedCarIcon()" (click)="setMobileVisiblePanel('car')"
    alt="car selector" />
</div>

<div class="mobile-bottom-right-menu-portrait" [hidden]="showOnMap" *ngIf="!navigation && mobileResolution">
  <app-voice-input></app-voice-input>
</div>

<div class="mobile-middle-right-menu-landscape" [hidden]="showOnMap || mobileVisiblePanel=='menudropdown'"
  [ngClass]="{'closed-middle-right-menu-landscape': mobileVisiblePanel!=''}">
  <img class="mobile-selected-car" [src]="mapService.getSelectedCarIcon()" (click)="setMobileVisiblePanel('car')"
    alt="car selector" />
  <app-voice-input></app-voice-input>
</div>

<div class="mobile-set-to-location" (click)="setStartCoordsToYourGeolocation()"
  [ngClass]="{'closed-mobile-set-to-location': mobileVisiblePanel!='' && mobileVisiblePanel!='menudropdown'  }"
  [hidden]="selectedMode!='rha'">
  <img alt="locate" [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/locate_icon.svg'"
    alt="find location" />
</div>

<!-- Mobile close button -->
<div class="close-button" (click)="setMobileVisiblePanel('')" [ngClass]="{'chargingstation': mobileVisiblePanel=='chargingstation', 'info': mobileVisiblePanel=='info',
    'infoscrollbar': mobileVisiblePanel=='itinerary' || mobileVisiblePanel=='chargingplan', 'tracking': mobileVisiblePanel=='tracking' && mobileResolution,
    'car': mobileVisiblePanel=='car', 'car-open': mobileVisiblePanel=='car-open', 'manage-cars': mobileVisiblePanel=='manageCars',
    'show': mobileResolution&& mobileVisiblePanel!='' && mobileVisiblePanel!='search' && mobileVisiblePanel!=undefined &&
    mobileVisiblePanel!='wind' && mobileVisiblePanel!='menudropdown' }">X</div>

<div class="close-button wind-button" (click)="setMobileVisiblePanel('')"
  [ngClass]="{'wind': mobileVisiblePanel=='wind'}">X</div>

<!-- Navigation hud -->
<app-navigation-hud (hudOpenedChangedEvent)="hudOpenedChangedEvent($event)"
  (computersChangedEvent)="computersChangedEvent($event)" *ngIf="navigation && mobileResolution">
</app-navigation-hud>