import { Injectable } from '@angular/core';
import { Observer, Observable, BehaviorSubject } from 'rxjs';
import { Position } from '../models/position';
import { ConsoleLoggerService } from './console-logger.service';
import { WebviewService } from './webview.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private watchPositionStatus: boolean = false;
  private watchPositionRef: number;
  private lastPosition: Position;
  public ObservableLastPosition: BehaviorSubject<Position> = new BehaviorSubject<Position>(null);
  private fakeGPSReplayMode: boolean = false;

  constructor(public webviewService: WebviewService, private consoleLoggerService: ConsoleLoggerService) {
    if (window.navigator.userAgent.includes("FB_IAB")) {
      this.initFBLocation();
    }
  }

  public initWebViewLocation(): void {
    this.webviewService.InitLocationFunction((timestamp, latitude, longitude, altitude, accuracy, altitudeAccuracy, heading, speed) => {
      if (this.fakeGPSReplayMode) {
        return;
      }
      if (latitude == null && longitude == null) {
        this.lastPosition = null;
      }
      else {
        this.lastPosition = new Position(latitude, longitude, speed, accuracy, altitude, altitudeAccuracy, heading, timestamp);
        this.ObservableLastPosition.next(this.lastPosition);
      }
    });
  }

  private initFBLocation(): void {
    this.startWatchPosition();
  }

  public getCurrentPosition(): Observable<GeolocationPosition> {
    return new Observable((observer: Observer<GeolocationPosition>) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.setLastPosition(new Position(position.coords.latitude, position.coords.longitude, position.coords.speed, position.coords.accuracy,
            position.coords.altitude, position.coords.altitudeAccuracy, position.coords.heading, position.timestamp));
          observer.next(position);
          observer.complete();
        }, (error) => {
          observer.error(error);
          observer.complete();
        }, { timeout: 1000, maximumAge: 20000 });
      }
      else {
        observer.error("ERROR_NOT_SUPPORTED");
        observer.complete();
      }
    });
  }

  startWatchPosition() {
    if (!this.watchPositionStatus) {
      if (this.isTeslaBrowser()) {
        if (navigator.geolocation) {
          this.watchPositionStatus = true;
          this.getPositionLoop();
        }
      }
      else {
        this.watchPositionRef = navigator.geolocation.watchPosition(
          (position) => {
            if (this.lastPosition === undefined || new Date().getTime() - this.lastPosition.Timestamp > 800) {
              this.setLastPosition(new Position(position.coords.latitude, position.coords.longitude, position.coords.speed, position.coords.accuracy,
                position.coords.altitude, position.coords.altitudeAccuracy, position.coords.heading, position.timestamp));
            }
          },
          (error) => {},
          { enableHighAccuracy: true, timeout: 1000, maximumAge: 0 });

        this.watchPositionStatus = true;
      }
    }
  }

  private getPositionLoop(): void {
    if (this.watchPositionStatus) {
      navigator.geolocation.getCurrentPosition((e) => {
        this.gpsPositionSuccess(e);
      }, () => { }, { enableHighAccuracy: false, maximumAge: 900, timeout: 900 });
      setTimeout(() => { this.getPositionLoop() }, 1000);
    }
  }

  private gpsPositionSuccess(position: any): void {
    if (position && position.coords) {
      this.setLastPosition(new Position(position.coords.latitude, position.coords.longitude, position.coords.speed, position.coords.accuracy,
        position.coords.altitude, position.coords.altitudeAccuracy, position.coords.heading, position.timestamp));
    }
  }

  public stopWatchPosition(): void {
    if (!this.isTeslaBrowser()) {
      navigator.geolocation.clearWatch(this.watchPositionRef);
    }
    this.watchPositionStatus = false;
  }

  public getLastPosition(): Position {
    return this.lastPosition;
  }

  public setLastPosition(position: Position): void {
    this.lastPosition = position;
    this.ObservableLastPosition.next(position);
  }

  public getWatchPositionStatus(): boolean {
    return this.watchPositionStatus;
  }

  public setFakeGPSReplayMode(fakeGPS: boolean): void {
    this.fakeGPSReplayMode = fakeGPS;
  }

  private isTeslaBrowser(): boolean {
    const isChromeVersionLessThan = (userAgent: string, version: number): boolean => {
      const match = userAgent.match(/Chrome\/(\d+(\.\d+)+)/);
      return match !== null && parseFloat(match[1]) < version;
    };

    const isLinux64UserAgent = (userAgent: string): boolean => userAgent.includes("X11; Linux x86_64");

    const isSpecialBrowser = (userAgent: string): boolean => userAgent.includes("QtCarBrowser") || userAgent.includes("Tesla");

    const userAgent = navigator.userAgent;
    const isAffectedVersion = isLinux64UserAgent(userAgent) && isChromeVersionLessThan(userAgent, 115);

    const result = isSpecialBrowser(userAgent) || isAffectedVersion;
    return result;
  }
}
