import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'turnValueInteger'
})
export class TurnValueIntegerPipe implements PipeTransform {

  transform(val: number): string {
      if (val !== undefined && val !== null) {
          if (val<10){
              return "10";
          }
          return (val / 10).toFixed(0) + "0";
      } else {
          return "";
      }
  }
}
