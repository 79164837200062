export class Position {
  Latitude: number;
  Longitude: number;
  Speed: number;
  Altitude: number;
  Accuracy: number;
  AltitudeAccuracy: number;
  Heading: number;
  Timestamp: number;

  constructor(latitude: number, longitude: number, speed: number, accuracy: number, altitude: number,
    altitudeAccuracy: number, heading: number, timestamp: number) {
    this.Latitude = latitude;
    this.Longitude = longitude;
    this.Speed = speed;
    this.Accuracy = accuracy;
    this.Altitude = altitude;
    this.AltitudeAccuracy = altitudeAccuracy;
    this.Heading = heading;
    this.Timestamp = timestamp;
  }
}