<div class="vehicle-dialog" [ngClass]="{'dialog-loader': dialogContentIsLoading}">
  <h1 mat-dialog-title>{{ languageService.languageJSON.CarSelectorDialog_Title }}</h1>
  <div mat-dialog-content>
    <div class="dialog-loader-outer" *ngIf="dialogContentIsLoading">
      <mat-spinner class="dialog-loading-spinner"></mat-spinner>
    </div>
    <ng-scrollbar [autoHeightDisabled]="false" [autoWidthDisabled]="true" #carSelectorScrollbar class="scrollable-body">
      <div class="car-selector-row-outer">
        <div class="car-selector-row">
          <div class="selected-car-icon">
            <img [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ecars/' + selectedCar.Icon + '.svg'" />
          </div>
          <div class="mat-selector-row">
            <h3>{{ languageService.languageJSON.CarSelectorDialog_Brand }}</h3>
            <mat-form-field>
              <mat-select disableRipple [(value)]="carType" class="brand-mat-select">
                <mat-option *ngFor="let type of CarTypes" [value]="type.name" class="brand-mat-option"
                  (click)="carTypeChanged()">
                  {{type.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="mat-selector-row" *ngIf="CarTypes[carTypeIndex].subtypes.length>0">
            <h3>{{ languageService.languageJSON.CarSelectorDialog_Model }}</h3>
            <mat-form-field>
              <mat-select disableRipple [(value)]="carSubType" class="subtype-mat-select">
                <mat-option *ngFor="let subtype of CarTypes[carTypeIndex].subtypes" [value]="subtype"
                  class="subtype-mat-option" (click)="carSubTypeChanged()">
                  {{subtype}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="mat-selector-row">
            <h3 *ngIf="CarTypes[carTypeIndex].subtypes.length==0">{{
              languageService.languageJSON.CarSelectorDialog_Model }}</h3>
            <h3 *ngIf="CarTypes[carTypeIndex].subtypes.length>0">{{ languageService.languageJSON.CarSelectorDialog_Type
              }}</h3>
            <mat-form-field *ngIf="multipleCarType">
              <mat-select disableRipple [(value)]="selectedCar.Name" class="model-mat-select">
                <mat-option *ngFor="let ecar of ECars | carFilter : carType : carSubType | orderBy: 'Name'" [value]="ecar.Name"
                  class="model-mat-option" (click)="carChanged(ecar)">
                  {{ ecar.Name | carNameFilter : carType : carSubType }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="car-selector-model" *ngIf="!multipleCarType && selectedCar">{{ selectedCar.Name }}</div>
          </div>

          <div class="vehicle-row">
            <div class="vehicle-tile-property">
              <div class="vehicle-tile-property-name">
                <img
                  [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/vehicle_settings_icon_capacity.svg'" />
              </div>
              <div class="vehicle-tile-property-value" name="capacity_value">{{ selectedCar.DesignCapacity }} kWh</div>
            </div>
            <div class="vehicle-tile-property">
              <div class="vehicle-tile-property-name">
                <img [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/vehicle_settings_icon_range.svg'" />
              </div>
              <div class="vehicle-tile-property-value" [hidden]="settingsService.getUnit().Distance!='metric'"
                name="range_value">
                {{ selectedCar.FactoryRange }} km ({{ selectedCar.FactoryRangeSource }})</div>
              <div class="vehicle-tile-property-value" [hidden]="settingsService.getUnit().Distance!='imperial'"
                name="range_value">
                {{ selectedCar.FactoryRange * 0.621371192 | valueInteger }} mi ({{ selectedCar.FactoryRangeSource }})
              </div>
            </div>
          </div>

          <div class="vehicle-row">
            <div class="vehicle-tile-property">
              <div class="vehicle-tile-property-name">
                <img
                  [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/vehicle_settings_icon_topspeed.svg'" />
              </div>
              <div class="vehicle-tile-property-value" [hidden]="settingsService.getUnit().Speed!='metric'"
                name="topspeed_value">
                {{ selectedCar.TopSpeed }}
                km/h</div>
              <div class="vehicle-tile-property-value" [hidden]="settingsService.getUnit().Speed!='imperial'"
                name="topspeed_value">
                {{ selectedCar.TopSpeed *0.621371192 | valueInteger }} mph</div>
            </div>
            <div class="vehicle-tile-property">
              <div class="vehicle-tile-property-name">
                <img
                  [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/vehicle_settings_icon_totalpower.svg?v=2'" />
              </div>
              <div class="vehicle-tile-property-value" name="totalpower_value">{{ selectedCar.TotalPower }} kW</div>
            </div>
          </div>

          <a class="greencars-link" (click)="openGreenCarsCompareLink()" *ngIf="selectedCar.GreenCarsLink">
            <div class="more-info-icon">i</div>
            {{ languageService.languageJSON.Map_Popup_MoreInfo }}</a>
        </div>

        <div class="car-selector-row fastcharge" *ngIf="selectedCar.FastChargePower!=0">
          <div class="title-row">
            <h3>{{ languageService.languageJSON.CarSelectorDialog_FastChargeLimit }}</h3>
            <div class="slider-value">
              {{ fastChargeLimit }}%
            </div>
          </div>
          <div class="vehicle-row">
            <app-rha-slider [imageSource]="fastchargeImg" [min]="fastChargeLimitMin" [max]="fastChargeLimitMax"
              [digit]="0" [step]="30" [altString]="'fast charge limit'" [(value)]="fastChargeLimit">
            </app-rha-slider>
          </div>
          <div class="slider-indicators">
            <div class="float-left">{{ fastChargeLimitMin }}%</div>
            <div class="float-right">{{ fastChargeLimitMax }}%</div>
          </div>
        </div>

        <div class="car-selector-row">
          <h3>{{ languageService.languageJSON.CarSelectorDialog_BatteryStateOfHealth }}</h3>
          <app-rha-slider id="health-slider" [imageSource]="batteryImg" [min]="batteryStateOfHealthMin"
            [max]="batteryStateOfHealthMax" [digit]="0" [step]="60" [altString]="'battery/range adjustment'"
            [(value)]="batteryStateOfHealth">
          </app-rha-slider>
          <div class="vehicle-row">
            <div class="vehicle-tile-property">
              <div class="vehicle-tile-property-name">
                <img
                  [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/vehicle_settings_icon_maxbattery.svg'" />
              </div>
              <div class="vehicle-tile-property-value" name="batteryStateOfHealth_value">
                {{ batteryStateOfHealth }}%</div>
            </div>
            <div class="vehicle-tile-property">
              <div class="vehicle-tile-property-name">
                <img
                  [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/vehicle_settings_icon_maxrange.svg'" />
              </div>
              <div class="vehicle-tile-property-value" [hidden]="settingsService.getUnit().Distance!='metric'"
                name="range_value">
                {{ selectedCar.FactoryRange * batteryStateOfHealth / 100 }} km ({{ selectedCar.FactoryRangeSource }})
              </div>

              <div class="vehicle-tile-property-value" [hidden]="settingsService.getUnit().Distance!='imperial'"
                name="range_value">
                {{ selectedCar.FactoryRange * 0.621371192 * batteryStateOfHealth / 100 | valueInteger }} mi ({{
                selectedCar.FactoryRangeSource }})
              </div>
            </div>
          </div>
        </div>

        <div class="car-selector-row">
          <h3>{{ languageService.languageJSON.CarSelectorDialog_ChargerTypes }}</h3>
          <div class="charger-types-outer">
            <div class="charger-type" *ngFor="let charger of chargerTypes; index as i"
              [ngClass]="{ active: selectedChargerTypes[i]==true }"
              (click)="selectedChargerTypes[i]=!selectedChargerTypes[i]; checkChargerTypes()">
              <div class="charger-icon"><img
                  [src]="'/assets/icons/'+ settingsService.getMap().Skin +'/plug/' + charger.icon"></div>
              <div class="charger-name">{{ charger.name }}</div>
            </div>
          </div>
          <div class="cs-error" *ngIf="csError">{{ languageService.languageJSON.CarSelectorDialog_ChargerTypes_Error }}
          </div>
        </div>

        <div class="car-selector-row" [formGroup]="customCarNameForm" *ngIf="isAuthorized">
          <h3>{{ languageService.languageJSON.CarSelectorDialog_CustomName }}</h3>
          <div class="input-el custom-name-input">
            <input required formControlName="customCarName" type="text" [placeholder]="selectedCar.Name">
          </div>
        </div>

        <div class="car-selector-row" *ngIf="isAuthorized">
          <h3>{{ languageService.languageJSON.CarSelectorDialog_Settings_RecordingOptions }}</h3>
          <div class="valuebox">
            <label for="setting-track-logging">{{ languageService.languageJSON.CarSelectorDialog_Settings_TripRecording
              }}</label>
            <app-switch class="value-switch" [onImage]="'./assets/icons/default/settings/settings_switch_icon_on.svg'"
              [offImage]="'./assets/icons/default/settings/settings_switch_icon_off.svg'"
              [(value)]="tripRecordingSettings" (valueChangeEvent)="tripRecordingValueChanged()"></app-switch>
          </div>
        </div>

        <div class="error-outer" *ngIf="carSelectorError">
          <h5 class="error-text">{{ languageService.languageJSON.Global_SomethingWentWrong }}</h5>
        </div>
      </div>
    </ng-scrollbar>
  </div>
  <div mat-dialog-actions>
    <button name="ok_button" mat-button (click)="clickOk()" [disabled]="csError" [ngClass]="{'inactive': csError}">{{
      languageService.languageJSON.Global_Ok }}</button>
    <button name="cancel_button" mat-button (click)="clickCancel()">{{ languageService.languageJSON.Global_Cancel
      }}</button>
  </div>
</div>