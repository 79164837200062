<div class="subscriptions-promo-dialog">
    <h1 mat-dialog-title>Important Update:<br> Re-Add Your Favorite and<br> Disabled Charging Stations</h1>
    <div mat-dialog-content>
        <ng-scrollbar [autoHeightDisabled]="false" [autoWidthDisabled]="true" #subscriptionsScrollbar
            class="scrollable-body">
            <div class="header-image"></div>
            <p class="desc1">
                We’ve upgraded our charging station database to provide more options and better accuracy!
            </p>
            <p>
                Please note: you must re-add your favourite and disabled charging stations and operators.
            </p>
            <p>
                Thank you for your understanding, and enjoy the enhanced charging experience!
            </p>
        </ng-scrollbar>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="cancel()">{{ languageService.languageJSON.Global_Cancel }}</button>
    </div>
</div>