import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class LanguageService {

    public languageJSON;
    public ObservableLanguageLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    constructor(private settingsService: SettingsService, private http: HttpClient) {
        this.settingsService.ObservableLanguage.subscribe((resp) => {
            if (resp) {
                this.http.get(`/assets/json/language_${resp}.json`).subscribe((resp) => {
                    this.languageJSON = resp;
                    this.ObservableLanguageLoaded.next(true);
                });
            }
        });
    }

    public onlyEnglish = {
        "VoiceInputInfoDialog_Title": "Voice Instructions",
        "VoiceInputInfoDialog_Description": "Voice instructions gives you the freedom to set parameters or give command hand-free. After pressing the <img class='voice-icon' src='assets/icons/dark/ui/voice_recording_icon.svg'> button try these commands:",
        "Tutorial_Step_4_Command_SkipWaypoint": "Skip waypoint",
        "Tutorial_Step_4_Command_SkipChargingStation": "Skip charging station",
        "Tutorial_Step_4_Command_StopNavigation": "Stop navigation",
        "Tutorial_Step_4_Command_NumberOfPersons": "Number of persons x",
        "Tutorial_Step_4_Command_NavigateHome": "Navigate to home",
        "Tutorial_Step_4_Command_NavigateWork": "Navigate to work",
        "Tutorial_Step_4_Command_RemainingDistance": "Remaining distance",
        "Tutorial_Step_4_Command_ETE": "ETE",
        "Tutorial_Step_4_Command_BatteryLevel": "Set Battery level",
        "SUBS_ERR_001": "The subscription id field is required.",
        "SUBS_ERR_002": "The selected old vehicle id is required.",
        "SUBS_ERR_003": "The selected old vehicle id is invalid.",
        "SUBS_ERR_004": "The selected new vehicle id is required.",
        "SUBS_ERR_005": "The selected new vehicle id is invalid.",
        "SUBS_ERR_006": "The selected vehicle id is required.",
        "SUBS_ERR_007": "The selected user vehicle id is required."
    }
}