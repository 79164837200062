import { Component, OnInit } from '@angular/core';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

  public languages: { code: string, name: string }[];
  public activeLanguage: { code: string, name: string };

  constructor(public settingsService: SettingsService, private consoleLoggerService: ConsoleLoggerService) {
  }

  ngOnInit(): void {
    this.languages = this.settingsService.getLanguageList();
    this.activeLanguage = this.languages.find((el) => { return el.code == "en" });
    this.consoleLoggerService.log(this.languages, this.activeLanguage);
  }

  public setActiveLanguage(language: { code: string, name: string }): void {
    this.activeLanguage = language;
  }

  ngOnDestroy(): void {
    this.settingsService.setLanguage(this.activeLanguage.code);
  }
}
