import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AudioService } from './audio.service';
import { InputParamsService } from './input-params.service';
import { SettingsService, Unit } from './settings.service';
import { ConsoleLoggerService } from './console-logger.service';
import { WebviewService } from './webview.service';
import { GPSLocation } from '../models/gps-location';
import { NextTurn, Route, RoutePlanElement, RoutePlanType, RoutePoint, Turn } from '../models/route';
import { StackedModalsService } from './stacked-modals.service';
import { NavigationSetBatteryDialogComponent } from '../components/modals/navigation-set-battery-dialog/navigation-set-battery-dialog.component';
import { Waypoint } from '../models/range-params';
import { ChargeState } from '../models/charge-state';
import { LocationService } from './location.service';
const INVALID_TARGET = -1;
const REPLAN_TARGET_IDX = -2;
const NumberMaxValue = 9007199254740991;

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private TargetIdx: number = INVALID_TARGET;
  private NextChargingStationIdx: number = INVALID_TARGET;
  private TargetWithCommandIdx: number = INVALID_TARGET;
  private LastTargetWithCommandIdx: number = INVALID_TARGET;
  private ReplanningNow: boolean = false;
  private LastCommandMinDist: number = NumberMaxValue;
  private LastCommandDistToShow: number = 0;
  private CommandNowDistance: number = 45;
  private Last5Speed: [number, number, number, number, number] = [50, 50, 50, 50, 50];
  private ChargeState: ChargeState = ChargeState.None;

  private NavigationRoute: Route = null;
  private MinDist: number = NumberMaxValue;
  public CurrentPoint: GPSLocation = null;
  private avg5Speed: number = 51;
  private NextTurnInstruction: Turn = null;
  public slidersValueChangedIn15seconds: boolean = false;

  public ObservableChangeNavigation: BehaviorSubject<string>;

  public ObservableNextTurn: BehaviorSubject<NextTurn>;
  public ObservableShowRoadLane: BehaviorSubject<boolean>;
  public ObservableSetCenter: BehaviorSubject<boolean>;
  public ObservableMapDragIn5Seconds: BehaviorSubject<boolean>;
  public ObservableNextWaypointIdx: BehaviorSubject<number>;
  public ObservableCannotSkipMoreChargingStation: BehaviorSubject<boolean>;
  public ObservableFloatingInfoVisible: BehaviorSubject<boolean>;
  public ObservableUserTriggeredReplan: BehaviorSubject<boolean>;
  public ObservableOpenNavigationRestartDialog: BehaviorSubject<boolean>;
  public ObservableArrivedToCharger: BehaviorSubject<RoutePlanElement>;
  public ObservableChargeStateChanged: BehaviorSubject<ChargeState>;
  private NextWaypointIdx: number = 0;
  public LastReplanTime: number;
  private floatingInfoInterval: ReturnType<typeof setTimeout>;

  private ClosingInOnTarget: boolean = false;
  public selectedReplayTrack: Document = null;
  private subscriptions: Subscription[] = [];
  public navigation: boolean = false;
  public skipChargingStation: boolean = false;
  private keepAliveTimeStamp: number = new Date().getTime();

  private commandNowSaid: boolean = false;
  private command300mSaid: boolean = false;
  private command800mSaid: boolean = false;
  private commandDistanceSchema: number = 0;
  private lastCommandDist: number = NumberMaxValue;

  constructor(private utilsService: UtilsService, private audioService: AudioService, private inputParamsService: InputParamsService,
    private settingsService: SettingsService, private consoleLoggerService: ConsoleLoggerService, public webviewService: WebviewService,
    private stackedModalsService: StackedModalsService) {
    this.ObservableChangeNavigation = new BehaviorSubject<string>(null);
    this.ObservableNextTurn = new BehaviorSubject<NextTurn>(null);
    this.ObservableShowRoadLane = new BehaviorSubject<boolean>(null);
    this.ObservableSetCenter = new BehaviorSubject<boolean>(null);
    this.ObservableMapDragIn5Seconds = new BehaviorSubject<boolean>(false);
    this.ObservableNextWaypointIdx = new BehaviorSubject<number>(null);
    this.ObservableCannotSkipMoreChargingStation = new BehaviorSubject<boolean>(null);
    this.ObservableFloatingInfoVisible = new BehaviorSubject<boolean>(null);
    this.ObservableUserTriggeredReplan = new BehaviorSubject<boolean>(null);
    this.ObservableOpenNavigationRestartDialog = new BehaviorSubject<boolean>(null);
    this.ObservableArrivedToCharger = new BehaviorSubject<RoutePlanElement>(null);
    this.ObservableChargeStateChanged = new BehaviorSubject<ChargeState>(this.ChargeState);

    this.subscriptions.push(this.ObservableChangeNavigation.subscribe((resp) => {
      if (resp != undefined && resp == "start") {
        this.navigation = true;
        clearTimeout(this.floatingInfoInterval);
        this.floatingInfoInterval = setTimeout(() => {
          this.ObservableFloatingInfoVisible.next(false);
        }, 10000);
        localStorage.setItem("navigation", "true");
      }
      if (resp != undefined && resp == "exit") {
        this.setBattery(this.CurrentPoint, true);
        this.navigation = false;
        localStorage.setItem("navigation", "false");
      }
      if (resp != undefined && resp == "arrive") {
        this.navigation = false;
        localStorage.setItem("navigation", "false");
      }
    }));

    this.checkOngoingNavigation();
  }

  checkOngoingNavigation() {
    if (localStorage.getItem("navigation") == "true") {
      this.ObservableChangeNavigation.next("start");
      this.ObservableOpenNavigationRestartDialog.next(true);
    }
  }

  setSlidersValueChangedIn15seconds(slidersValueChangedIn15seconds: boolean): void {
    this.slidersValueChangedIn15seconds = slidersValueChangedIn15seconds;
  }

  private DegreeDiff(gpsLocation: GPSLocation, idx: number): number {
    const bearing = this.Bearing(gpsLocation.Latitude, gpsLocation.Longitude, this.NavigationRoute.RoutePoints[idx].Location.lat, this.NavigationRoute.RoutePoints[idx].Location.lng);
    const course = gpsLocation.Course;
    const angle = Math.abs(bearing - course);
    const normAngle = this.NormAngle(angle);
    return normAngle;
  }

  /*private RefreshNavigation(gpsData) {
    if (this.Points.length > 0) {
      this.Last5Speed += gpsData.Location.Speed;

      this.RefreshTarget(gpsData);

      if (this.TargetIdx >= 0 && this.TargetIdx < this.Points.length && this.NextTurnInstruction.RoutePointIdx != NumberMaxValue) {
        const val, unit;
        this.ConvertHorizontalDistanceString(this.NextTurnDistance(gpsData), val, unit);
      }
    }
  }*/

  private ClearSaidCommands(): void {
    this.commandNowSaid = false;
    this.command300mSaid = false;
    this.command800mSaid = false;
    this.commandDistanceSchema = 0;
    this.lastCommandDist = NumberMaxValue;
  }

  // CURR COMMAND DIST
  private ReadNextCommand(currCommandDist: number): void {
    //const currCommandDist = Math.round(this.utilsService.distanceBetweenCoordinates([Pos.Location.Latitude, Pos.Location.Longitude], [NextTurn.Location.Latitude, NextTurn.Location.Longitude]) / 10) * 10;

    let andCommand: boolean = false;
    let commandNow: boolean = false;
    let sayPureCommand: boolean = false;

    if (this.settingsService.getUnit().Speed == Unit.Metric) {
      if (this.avg5Speed < 60) {
        if (currCommandDist < 300 * 3 / 2) {
          andCommand = true;
          this.commandDistanceSchema = 300;
          this.command800mSaid = true;
          if (currCommandDist < 200) {
            this.command300mSaid = true;
            if (currCommandDist > this.CommandNowDistance * this.SpeedMul()) {
              sayPureCommand = true;
            }
          }
          if (currCommandDist < this.CommandNowDistance * this.SpeedMul()) {
            this.commandNowSaid = true;
            commandNow = true;
          }
        }
      }
      else if (this.avg5Speed < 100) {
        if (currCommandDist < 800 * 3 / 2) {
          andCommand = true;
          this.commandDistanceSchema = 800;
          this.command800mSaid = true;
          if (currCommandDist < 500) {
            this.command300mSaid = true;
            if (currCommandDist > this.CommandNowDistance * this.SpeedMul()) {
              sayPureCommand = true;
            }
          }
          if (currCommandDist < this.CommandNowDistance * this.SpeedMul()) {
            this.commandNowSaid = true;
            commandNow = true;
          }
        }
      }
      else {
        if (currCommandDist < 3000 * 3 / 2) {
          andCommand = true;
          this.commandDistanceSchema = 3000;
          this.command800mSaid = true;
          if (currCommandDist < 1500) {
            this.command300mSaid = true;
            if (currCommandDist > this.CommandNowDistance * this.SpeedMul()) {
              sayPureCommand = true;
            }
          }
          if (currCommandDist < this.CommandNowDistance * this.SpeedMul()) {
            this.commandNowSaid = true;
            commandNow = true;
          }
        }
      }
    }
    else {
      if (this.avg5Speed < 60) {
        if (currCommandDist < this.utilsService.yardToM(300 * 3 / 2)) {
          andCommand = true;
          this.commandDistanceSchema = 300;
          this.command800mSaid = true;
          if (currCommandDist < this.utilsService.yardToM(200)) {
            this.command300mSaid = true;
            if (currCommandDist > this.CommandNowDistance * this.SpeedMul()) {
              sayPureCommand = true;
            }
          }
          if (currCommandDist < this.CommandNowDistance * this.SpeedMul()) {
            this.commandNowSaid = true;
            commandNow = true;
          }
        }
      }
      else if (this.avg5Speed < 100) {
        if (currCommandDist < this.utilsService.yardToM(800 * 3 / 2)) {
          andCommand = true;
          this.commandDistanceSchema = 800;
          this.command800mSaid = true;
          if (currCommandDist < this.utilsService.yardToM(500)) {
            this.command300mSaid = true;
            if (currCommandDist > this.CommandNowDistance * this.SpeedMul()) {
              sayPureCommand = true;
            }
          }
          if (currCommandDist < this.CommandNowDistance * this.SpeedMul()) {
            this.commandNowSaid = true;
            commandNow = true;
          }
        }
      }
      else {
        if (currCommandDist < this.utilsService.mileToM(2 * 3 / 2)) {
          andCommand = true;
          this.commandDistanceSchema = 3000;
          this.command800mSaid = true;
          if (currCommandDist < this.utilsService.mileToM(0.65 * 3 / 2)) {
            this.command300mSaid = true;
            if (currCommandDist > this.CommandNowDistance * this.SpeedMul()) {
              sayPureCommand = true;
            }
          }
          if (currCommandDist < this.CommandNowDistance * this.SpeedMul()) {
            this.commandNowSaid = true;
            commandNow = true;
          }
        }
      }
    }

    if (!sayPureCommand) {
      if (!commandNow) {
        if (andCommand) {
          this.audioService.readCommand(currCommandDist, this.NextTurnInstruction, this.settingsService.getUnit());
          this.ObservableShowRoadLane.next(true);
        }
        else {
          this.audioService.readFollowTheRoute(currCommandDist, this.settingsService.getUnit());
        }
      }
      else {
        this.audioService.readCommand(0, this.NextTurnInstruction, this.settingsService.getUnit());
        this.ObservableShowRoadLane.next(true);
      }
    }
    else {
      this.audioService.readCommand(currCommandDist, this.NextTurnInstruction, this.settingsService.getUnit());
      this.ObservableShowRoadLane.next(true);
    }
  }

  private HandleArrive(): void {
    this.audioService.readCommand(0, this.NextTurnInstruction, this.settingsService.getUnit());
    this.ObservableChangeNavigation.next("arrive");
    this.ObservableShowRoadLane.next(true);
    this.commandNowSaid = true;
    this.setBattery(this.CurrentPoint, true);
    this.inputParamsService.paramsUpdate();

    this.TargetIdx = INVALID_TARGET;
    this.NextChargingStationIdx = INVALID_TARGET;
    this.TargetWithCommandIdx = INVALID_TARGET;
    this.LastTargetWithCommandIdx = INVALID_TARGET;
    this.NavigationRoute = null;
    this.NextTurnInstruction = null;

    this.ClosingInOnTarget = false;
  }

  private CheckArrive(currCommandDist: number): boolean {
    if ((this.NextTurnInstruction && this.TargetIdx > INVALID_TARGET && this.NextTurnInstruction.Id == "ARRIVE") || this.TargetIdx >= this.NavigationRoute.RoutePoints.length) {
      if ((this.ClosingInOnTarget && this.lastCommandDist < currCommandDist) || currCommandDist < 5) {
        this.HandleArrive();
        return true;
      }
      if (!this.ClosingInOnTarget && currCommandDist < 30) {
        this.ClosingInOnTarget = true;
      }
    }
    else {
      this.ClosingInOnTarget = false;
    }
    return false;
  }

  private ReadCommands(currCommandDist: number): void {
    if (this.lastCommandDist > currCommandDist) {
      if (currCommandDist < this.CommandNowDistance * this.SpeedMul() && !this.commandNowSaid) {
        if (this.NextTurnInstruction && this.NextTurnInstruction.Id != "ARRIVE") {
          this.audioService.readCommand(0, this.NextTurnInstruction, this.settingsService.getUnit());
          this.ObservableShowRoadLane.next(true);
          this.commandNowSaid = true;
        }
      }
      else {
        if (this.settingsService.getUnit().Distance == "metric") {
          if (this.commandDistanceSchema == 0) {
            if (this.avg5Speed < 60) {
              if (currCommandDist < 300 * 3 / 2) {
                this.commandDistanceSchema = 300;
              }
            }
            else if (this.avg5Speed < 100) {
              if (currCommandDist < 800 * 3 / 2) {
                this.commandDistanceSchema = 800;
              }
            }
            else {
              if (currCommandDist < 3000 * 3 / 2) {
                this.commandDistanceSchema = 3000;
              }
            }
          }
          else if (this.commandDistanceSchema == 300) {
            if (currCommandDist > 100 && currCommandDist < 100 + 35 * this.SpeedMul() && !this.command300mSaid) {
              this.audioService.readCommand(100, this.NextTurnInstruction, this.settingsService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command300mSaid = true;
            }
            else if (currCommandDist > 300 && currCommandDist < 300 + 35 * this.SpeedMul() && !this.command800mSaid) {
              this.audioService.readCommand(300, this.NextTurnInstruction, this.settingsService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command800mSaid = true;
            }
          }
          else if (this.commandDistanceSchema == 800) {
            if (currCommandDist > 300 && currCommandDist < 300 + 35 * this.SpeedMul() && !this.command300mSaid) {
              this.audioService.readCommand(300, this.NextTurnInstruction, this.settingsService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command300mSaid = true;
            }
            else if (currCommandDist > 800 && currCommandDist < 800 + 35 * this.SpeedMul() && !this.command800mSaid) {
              this.audioService.readCommand(800, this.NextTurnInstruction, this.settingsService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command800mSaid = true;
            }
          }
          else if (this.commandDistanceSchema == 3000) {
            if (currCommandDist > 1000 && currCommandDist < 1000 + 35 * this.SpeedMul() && !this.command300mSaid) {
              this.audioService.readCommand(1000, this.NextTurnInstruction, this.settingsService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command300mSaid = true;
            }
            else if (currCommandDist > 3000 && currCommandDist < 3000 + 35 * this.SpeedMul() && !this.command800mSaid) {
              this.audioService.readCommand(3000, this.NextTurnInstruction, this.settingsService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command800mSaid = true;
            }
          }
        }
        else {
          if (this.commandDistanceSchema == 0) {
            if (this.avg5Speed < 60) {
              if (currCommandDist < this.utilsService.yardToM(300 * 3 / 2)) {
                this.commandDistanceSchema = 300;
              }
            }
            else if (this.avg5Speed < 100) {
              if (currCommandDist < this.utilsService.mileToM(0.5 * 3 / 2)) {
                this.commandDistanceSchema = 800;
              }
            }
            else {
              if (currCommandDist < this.utilsService.mileToM(2 * 3 / 2)) {
                this.commandDistanceSchema = 3000;
              }
            }
          }
          else if (this.commandDistanceSchema == 300) {
            if (currCommandDist > this.utilsService.yardToM(100) && currCommandDist < this.utilsService.yardToM(100) + 35 * this.SpeedMul() && !this.command300mSaid) {
              this.audioService.readCommand(100, this.NextTurnInstruction, this.settingsService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command300mSaid = true;
            }
            else if (currCommandDist > this.utilsService.yardToM(300) && currCommandDist < this.utilsService.yardToM(300) + 35 * this.SpeedMul() && !this.command800mSaid) {
              this.audioService.readCommand(300, this.NextTurnInstruction, this.settingsService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command800mSaid = true;
            }
          }
          else if (this.commandDistanceSchema == 800) {
            if (currCommandDist > this.utilsService.yardToM(300) && currCommandDist < this.utilsService.yardToM(300) + 35 * this.SpeedMul() && !this.command300mSaid) {
              this.audioService.readCommand(300, this.NextTurnInstruction, this.settingsService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command300mSaid = true;
            }
            else if (currCommandDist > this.utilsService.mileToM(0.5) && currCommandDist < this.utilsService.mileToM(0.5) + 35 * this.SpeedMul() && !this.command800mSaid) {
              this.audioService.readCommand(500, this.NextTurnInstruction, this.settingsService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command800mSaid = true;
            }
          }
          else if (this.commandDistanceSchema == 3000) {
            if (currCommandDist > this.utilsService.mileToM(1) && currCommandDist < this.utilsService.mileToM(1) + 35 * this.SpeedMul() && !this.command300mSaid) {
              this.audioService.readCommand(1000, this.NextTurnInstruction, this.settingsService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command300mSaid = true;
            }
            else if (currCommandDist > this.utilsService.mileToM(2) && currCommandDist < this.utilsService.mileToM(2) + 35 * this.SpeedMul() && !this.command800mSaid) {
              this.audioService.readCommand(2000, this.NextTurnInstruction, this.settingsService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command800mSaid = true;
            }
          }
        }
      }
    }
    this.lastCommandDist = currCommandDist;
  }

  private checkChargerArrive(gpsData: GPSLocation): Boolean {
    const nextChargerIdx = this.GetNextChargingStationIndexOnRoute();
    const dist = this.NextRoutePointDistance(gpsData, nextChargerIdx);
    if (nextChargerIdx != INVALID_TARGET && dist < 50 && this.arriveChargerSpeedThreshold()) {
      this.setChargeState(ChargeState.ArriveToCharger);
      const routePlanChargers: RoutePlanElement[] = this.NavigationRoute.RoutePlan.filter((routePlanEl) => { return routePlanEl.Type == RoutePlanType.Charger });
      const nextChargingStationIndexFromChargers = this.GetNextChargingStationIndexFromChargers();
      this.ObservableArrivedToCharger.next(routePlanChargers[nextChargingStationIndexFromChargers])
      return true;
    }
    return false;
  }

  private arriveChargerSpeedThreshold(): boolean {
    return this.avg5Speed < 10;
  }

  private checkChargerExceed(): Boolean {
    const nextChargerIdx = this.GetNextChargingStationIndexOnRoute();
    if (nextChargerIdx != INVALID_TARGET && nextChargerIdx < this.TargetIdx) {
      if (this.arriveChargerSpeedThreshold()) {
        this.setChargeState(ChargeState.ArriveToCharger);
      }
      this.TargetIdx = nextChargerIdx;
      return true;
    }
    return false;
  }

  private FindNextTargetWidthCommand(observeWayPoint: boolean): void {
    if (this.checkChargerExceed()) {
      return;
    }

    const LastTargetWithCommandIdx = this.TargetWithCommandIdx;
    this.TargetWithCommandIdx = this.TargetIdx;

    observeWayPoint = observeWayPoint && this.NextTurnInstruction && this.NextTurnInstruction.Id == "ARRIVE_TO_WAYPOINT";

    for (let i = 0; i < this.NavigationRoute.Turns.length; i++) {
      if (this.NavigationRoute.Turns[i].RoutePointIdxStart >= this.TargetWithCommandIdx) {
        if (this.NavigationRoute.Turns[i].Id == "ARRIVE") {
        }
        this.TargetWithCommandIdx = this.NavigationRoute.Turns[i].RoutePointIdxStart;
        this.NextTurnInstruction = this.NavigationRoute.Turns[i];
        break;
      }
    }

    if (LastTargetWithCommandIdx != INVALID_TARGET && LastTargetWithCommandIdx != this.TargetWithCommandIdx) {
      if (observeWayPoint){
        this.NextWaypointIdx++;
        this.inputParamsService.setNextWaypointIdx(this.NextWaypointIdx);
        this.ObservableNextWaypointIdx.next(this.NextWaypointIdx);
      }
      
      this.ObservableShowRoadLane.next(false);
    }
  }

  private IsWaitingForUserInteraction(): Boolean {
    return this.ChargeState != ChargeState.None
  }

  private getMaxTargetIdx(): number {
    const nextWp = this.GetNextWaypointIndexOnRoute();
    const nextCh = this.GetNextChargingStationIndexOnRoute();
    return (nextCh != INVALID_TARGET) ? Math.min(nextCh, nextWp) : nextWp;
  }


  public setChargeState(chargeState: ChargeState): void {
    this.ChargeState = chargeState;
    this.ObservableChargeStateChanged.next(this.ChargeState);
  }

  public RefreshTarget(gpsLocation: GPSLocation): void {
    /*if (this.webviewService.IsGPSTWebview() && (new Date().getTime() - this.keepAliveTimeStamp > 30000)) {
      this.keepAliveTimeStamp = new Date().getTime();
      this.audioService.playKeepAlive();
    }*/

    // set actual location, calculating lastavgerage speed, distance from routeline, replanningtolerance
    this.CurrentPoint = gpsLocation;
    this.avg5Speed = this.getLastAverageSpeed(gpsLocation);
    let distFromRouteLine: number = this.RoadCurrSegmentAndPosMinDist(gpsLocation, true);
    let replanningTolerance = 0.0003;

    if (this.IsWaitingForUserInteraction() || !this.navigation || !this.NavigationRoute.RoutePoints) {
      return;
    }

    if (this.CurrentPoint.FirstRoutePoint) {
      return;
    }

    // replaning
    if (this.TargetIdx == REPLAN_TARGET_IDX) {
      if (distFromRouteLine > replanningTolerance && this.LastReplanningEarlier()) {
        this.Replan(gpsLocation);
      }
      return;
    }
    // not valid targetidx
    else if (this.TargetIdx == INVALID_TARGET) {
      let minDist: number = NumberMaxValue;

      // find the nearest route point
      const maxTargetIdx = this.getMaxTargetIdx();

      for (let i = 0; i < maxTargetIdx; i++) {

        if (this.NextChargingStationIdx != INVALID_TARGET && i > this.NextChargingStationIdx) {
          break;
        }

        const dist = this.utilsService.distanceBetweenCoordinates([gpsLocation.Latitude, gpsLocation.Longitude], [this.NavigationRoute.RoutePoints[i].Location.lat, this.NavigationRoute.RoutePoints[i].Location.lng]);

        if (dist < minDist) {
          minDist = dist;
          this.TargetIdx = i;
        }
      }
      if (this.NextChargingStationIdx != INVALID_TARGET && minDist > replanningTolerance && this.LastReplanningEarlier()) {
        this.Replan(gpsLocation);
        this.NextChargingStationIdx = INVALID_TARGET;
        return;
      }

      this.NextChargingStationIdx = INVALID_TARGET;
      // havent reached the last coordinate of the route, and the deviation from the direction of travel greater than 140
      if (this.NextTurnInstruction && (this.TargetIdx >= this.NavigationRoute.RoutePoints.length - 2 || this.DegreeDiff(gpsLocation, this.TargetIdx + 1) > 140)) {
        /* REPLAN
        if (LastReplanningEarlier(geoPosition.Location)) {
          Replan(true, geoPosition.Location);
        }*/
      }
      else {
        if (this.TargetIdx == -1) {
          this.TargetIdx++;
        }

        // find the acutal targetidx
        if (this.DegreeDiff(gpsLocation, this.TargetIdx) > 90 && this.TargetIdx < this.NavigationRoute.RoutePoints.length - 1 && this.DegreeDiff(gpsLocation, this.TargetIdx + 1) < 90) {
          this.TargetIdx++;
        }
        this.TargetIdx = Math.min(maxTargetIdx, this.TargetIdx);

        const roadAndPointMinDist: number = this.RoadAndPosMinDist(gpsLocation, true);

        this.TargetWithCommandIdx = this.TargetIdx;

        this.FindNextTargetWidthCommand(false);

        this.ClearSaidCommands();
        this.LastTargetWithCommandIdx = this.TargetWithCommandIdx;
        if (roadAndPointMinDist < 0.00015) {
          this.ReadNextCommand(this.NextTurnDistance(gpsLocation));
        }
        this.MinDist = NumberMaxValue;
      }
    }
    // valid idx
    else if (this.TargetIdx < this.NavigationRoute.RoutePoints.length) {
      if (this.checkChargerArrive(gpsLocation)) {
        return
      }

      let currDist = this.utilsService.distanceBetweenCoordinates([gpsLocation.Latitude, gpsLocation.Longitude], [this.NavigationRoute.RoutePoints[this.TargetIdx].Location.lat, this.NavigationRoute.RoutePoints[this.TargetIdx].Location.lng]);

      if (this.MinDist > currDist) {
        this.MinDist = currDist;
      }

      if (this.LastTargetWithCommandIdx != this.TargetWithCommandIdx) {
        // remove show road lane parameter
        const distanceCommands = this.utilsService.distanceBetweenCoordinates([this.NavigationRoute.RoutePoints[this.TargetWithCommandIdx].Location.lat, this.NavigationRoute.RoutePoints[this.TargetWithCommandIdx].Location.lng],
          [this.NavigationRoute.RoutePoints[this.LastTargetWithCommandIdx].Location.lat, this.NavigationRoute.RoutePoints[this.LastTargetWithCommandIdx].Location.lng]);

        let max: number = distanceCommands - Math.max(150, 50 * this.SpeedMul());
        max = this.Clamp(max, 12.5, 500);

        const distance = this.utilsService.distanceBetweenCoordinates([gpsLocation.Latitude, gpsLocation.Longitude], [this.NavigationRoute.RoutePoints[this.LastTargetWithCommandIdx].Location.lat, this.NavigationRoute.RoutePoints[this.LastTargetWithCommandIdx].Location.lng]);
        const distanceNew = this.NextTurnDistance(gpsLocation);

        if (distance < this.LastCommandMinDist) {
          this.LastCommandMinDist = distance;
        }


        if (distance > this.Clamp(50 * this.SpeedMul(), 10, max) && distanceNew < this.LastCommandDistToShow && distance > this.LastCommandMinDist + 12.5) {
          this.ClearSaidCommands();
          this.ReadNextCommand(distanceNew);
          this.LastTargetWithCommandIdx = this.TargetWithCommandIdx;
        }
        else {
          //this.ObservableShowRoadLane.next(true);
        }

        this.LastCommandDistToShow = distanceNew;
      }
      // targetidx > routepoints length
      else {
        const currCommandDist = this.NextTurnDistance(gpsLocation);

        if (this.CheckArrive(currCommandDist)) {
          return;
        }

        this.ReadCommands(currCommandDist);
      }
      if (currDist < this.Clamp(gpsLocation.Speed * 0.8, 55, 500) && currDist > this.MinDist + 3.5) {
        const targerPos = [this.NavigationRoute.RoutePoints[this.TargetIdx].Location.lat, this.NavigationRoute.RoutePoints[this.TargetIdx].Location.lng];
        this.TargetIdx++;

        const maxTargetIdx = this.getMaxTargetIdx();

        while (this.TargetIdx < maxTargetIdx && this.utilsService.distanceBetweenCoordinates(targerPos, [this.NavigationRoute.RoutePoints[this.TargetIdx].Location.lat, this.NavigationRoute.RoutePoints[this.TargetIdx].Location.lng]) < gpsLocation.Speed / 3.6) {
          this.TargetIdx++;
        }
        if (this.TargetIdx < this.NavigationRoute.RoutePoints.length) {
          this.MinDist = NumberMaxValue;
          if (this.LastTargetWithCommandIdx == this.TargetWithCommandIdx) {
            this.LastTargetWithCommandIdx = this.TargetWithCommandIdx;
          }
          this.FindNextTargetWidthCommand(true);

          if (this.LastTargetWithCommandIdx != this.TargetWithCommandIdx) {
            if (this.LastTargetWithCommandIdx == -1) this.LastTargetWithCommandIdx = this.TargetWithCommandIdx;
            this.LastCommandDistToShow = 0;
            this.LastCommandMinDist = NumberMaxValue;
          }

          distFromRouteLine = this.RoadCurrSegmentAndPosMinDist(gpsLocation, true);
        }
        else {
          if (this.checkChargerExceed()) {
            return;
          }
          // Arrived
          this.HandleArrive();
          return;
        }
      }

      else if (currDist > this.MinDist + this.Clamp(gpsLocation.Speed * 0.8, 85, 500)) {
        this.SearchNearestPoint(gpsLocation);
      }

      let NextTurnDistance: any = this.NextTurnDistance(gpsLocation);
      if (this.NextTurnInstruction && NextTurnDistance != NumberMaxValue && this.TargetIdx > INVALID_TARGET) {
        // calculate distance to dest with last routepoints sum + dist to the next routepoint
        let distToDest = 0;
        for (let i = this.TargetIdx + 1; i < this.NavigationRoute.RoutePoints.length; i++) {
          distToDest += this.NavigationRoute.RoutePoints[i].DistanceFromPreviousPoint;
        }

        currDist = this.utilsService.distanceBetweenCoordinates([gpsLocation.Latitude, gpsLocation.Longitude], [this.NavigationRoute.RoutePoints[this.TargetIdx].Location.lat, this.NavigationRoute.RoutePoints[this.TargetIdx].Location.lng]);
        distToDest += currDist;
        // calculate time to dest
        let timeToDest = 0;
        for (let i = this.TargetIdx + 1; i < this.NavigationRoute.RoutePoints.length; i++) {
          timeToDest += this.NavigationRoute.RoutePoints[i].TimeFromPreviousPoint;
        }
        let timeToNextTarget = 0;
        if (this.NavigationRoute.RoutePoints[this.TargetIdx].EVSpeedLimit != 0) {
          timeToNextTarget = (currDist / 1000) / this.NavigationRoute.RoutePoints[this.TargetIdx].EVSpeedLimit * 60;
        }
        else {
          timeToNextTarget = (currDist / 1000) / this.NavigationRoute.RoutePoints[this.TargetIdx].RouteSpeedLimit * 60;
        }
        timeToDest += timeToNextTarget;

        // calculate distance & time to next waypoint
        let distToWp: number = 0;
        let timeToWp: number = 0;
        let nextWaypointDisplayName = "next waypoint";
        let nextWaypointRouteIdx = this.GetNextWaypointIndexOnRoute();
        if (nextWaypointRouteIdx == this.NavigationRoute.RoutePoints.length - 1) {
          distToWp = null;
          timeToWp = null;
        }
        else {
          for (let i = this.TargetIdx + 1; i <= nextWaypointRouteIdx; i++) {
            distToWp += this.NavigationRoute.RoutePoints[i].DistanceFromPreviousPoint;
            timeToWp += this.NavigationRoute.RoutePoints[i].TimeFromPreviousPoint;
          }
          distToWp += currDist;
          timeToWp += timeToNextTarget;

          nextWaypointDisplayName = this.GetNextWaypointDisplayName();
        }

        let distToChargingStation: number = 0;
        let timeToChargingStation: number = 0;
        let nextChargingStationRouteIdx = this.GetNextChargingStationIndexOnRoute();
        if (nextChargingStationRouteIdx == INVALID_TARGET) {
          distToChargingStation = null;
          timeToChargingStation = null;
        }
        else {
          for (let i = this.TargetIdx + 1; i < nextChargingStationRouteIdx; i++) {
            distToChargingStation += this.NavigationRoute.RoutePoints[i].DistanceFromPreviousPoint;
            timeToChargingStation += this.NavigationRoute.RoutePoints[i].TimeFromPreviousPoint;
          }
          distToChargingStation += currDist;
          timeToChargingStation += timeToNextTarget;
        }

        /*let predictedBattery = this.getBattery();
        if (predictedBattery == -1) {
          predictedBattery = this.inputParamsService.getBattery();
          this.Replan(gpsLocation, false, true);
        }*/
        const predictedBattery = this.getPredictedBattery();

        this.ObservableNextTurn.next({
          NextTurnInstruction: this.NextTurnInstruction, NextTurnDistance: NextTurnDistance, SpeedLimit: this.NavigationRoute.RoutePoints[this.TargetIdx].EVSpeedLimit,
          DistToDest: Math.round(distToDest), TimeToDest: Math.round(timeToDest), EstimatedBatteryLevel: Math.round(predictedBattery),
          DistToNextWaypoint: distToWp, TimeToNextWaypoint: timeToWp, NextWaypointDisplayName: nextWaypointDisplayName,
          DistToNextChargingStation: distToChargingStation, TimeToNextChargingStation: timeToChargingStation,
          NextWaypointBatteryReservedWarning: distToWp != null ? this.NavigationRoute.RoutePoints[nextWaypointRouteIdx].Soc * 100 < this.inputParamsService.getECarSettings().batterySafetyLimitRoute : false,
          NextChargingStationBatteryReservedWarning: distToChargingStation != null ? this.NavigationRoute.RoutePoints[nextChargingStationRouteIdx].Soc * 100 < this.inputParamsService.getECarSettings().batterySafetyLimitRoute : false,
          DestBatteryReservedWarning: this.NavigationRoute.RoutePoints[this.NavigationRoute.RoutePoints.length - 1].Soc * 100 < this.inputParamsService.getECarSettings().batterySafetyLimitRoute
        });
      }
    }

    if (distFromRouteLine > replanningTolerance && this.LastReplanningEarlier()) {
      this.Replan(gpsLocation);
    }
  }

  public SkipNextWaypoint(): void {
    this.NextWaypointIdx++;
    this.inputParamsService.setNextWaypointIdx(this.NextWaypointIdx);
    this.ObservableNextWaypointIdx.next(this.NextWaypointIdx);
    this.Replan(this.CurrentPoint, true);
    this.ObservableUserTriggeredReplan.next(true);
  }

  public SkipNextChargingStation(): void {
    if (this.inputParamsService.AcceptCookies) {
      let disabledStorage: any = localStorage.getItem("skippedChargingStation");
      if (disabledStorage && disabledStorage != 'null') {
        disabledStorage = JSON.parse(disabledStorage);
      } else {
        disabledStorage = [];
      }

      const nextChargingStationId = this.GetNextChargingStationIdOnRoute();
      if (nextChargingStationId != "") {
        disabledStorage.push(nextChargingStationId.toString());
        localStorage.setItem("skippedChargingStation", JSON.stringify(disabledStorage));
        this.skipChargingStation = true;
      }
    }

    this.Replan(this.CurrentPoint, true);
    this.ObservableUserTriggeredReplan.next(true);
  }

  private getLastAverageSpeed(geoPosition: GPSLocation): number {
    if (this.Last5Speed == null) {
      this.Last5Speed = [geoPosition.Speed, geoPosition.Speed, geoPosition.Speed, geoPosition.Speed, geoPosition.Speed];
    }
    else {
      this.Last5Speed.shift();
      this.Last5Speed.push(geoPosition.Speed)
    }
    let last5speedSum = 0;
    for (let i = 0; i < this.Last5Speed.length; i++) {
      last5speedSum += this.Last5Speed[i];
    }
    return last5speedSum / this.Last5Speed.length;
  }

  private SearchNearestPointNoParam(): void {
    this.TargetIdx = INVALID_TARGET;
    this.TargetWithCommandIdx = INVALID_TARGET;
    this.LastTargetWithCommandIdx = INVALID_TARGET;
    this.ObservableShowRoadLane.next(false);
    if (this.CurrentPoint != null) {
      this.SearchNearestPoint(this.CurrentPoint);
    }
  }

  private SearchNearestPoint(geoPosition): void {
    this.NextChargingStationIdx = this.GetNextChargingStationIndexOnRoute();
    this.TargetIdx = INVALID_TARGET;
    this.TargetWithCommandIdx = INVALID_TARGET;
    this.LastTargetWithCommandIdx = INVALID_TARGET;
    this.ObservableShowRoadLane.next(false);
    this.RefreshTarget(geoPosition);
  }

  public StartNavigation(Points: Route): void {
    this.StopNavigation();
    if (this.ChargeState != ChargeState.None) {
      this.setChargeState(ChargeState.None);
    }
    this.NavigationRoute = Points;
    this.NextWaypointIdx = 0;
    this.ObservableNextWaypointIdx.next(null);
    this.inputParamsService.setNextWaypointIdx(0);
    this.ClosingInOnTarget = false;
    this.ClearSaidCommands();

    this.RefreshTarget({
      Latitude: this.NavigationRoute.RoutePoints[0].Location.lat,
      Longitude: this.NavigationRoute.RoutePoints[0].Location.lng,
      Speed: 0,
      HorizontalAccuracy: 3,
      Course: this.inputParamsService.getBearing(),
      FirstRoutePoint: true
    });
  }

  private StopNavigation(): void {
    this.TargetIdx = INVALID_TARGET;
    this.NextChargingStationIdx = INVALID_TARGET;
    this.TargetWithCommandIdx = INVALID_TARGET;
    this.LastTargetWithCommandIdx = INVALID_TARGET;
    this.ObservableShowRoadLane.next(false);
    this.NavigationRoute = null;
    this.NextTurnInstruction = null;
    this.ClosingInOnTarget = false;
  }

  private NextTurnDistance(gpsData: GPSLocation): number {
    return this.NextRoutePointDistance(gpsData, this.TargetWithCommandIdx);
  }

  private NextRoutePointDistance(gpsData: GPSLocation, index: number): number {
    if (this.NavigationRoute.RoutePoints && this.TargetIdx < this.NavigationRoute.RoutePoints.length && this.TargetIdx > INVALID_TARGET && this.NavigationRoute.RoutePoints.length > index) {
      let distance = this.utilsService.distanceBetweenCoordinates([gpsData.Latitude, gpsData.Longitude],
        [this.NavigationRoute.RoutePoints[this.TargetIdx].Location.lat, this.NavigationRoute.RoutePoints[this.TargetIdx].Location.lng]);
      for (let i = this.TargetIdx; i < index; i++) {
        distance += this.utilsService.distanceBetweenCoordinates([this.NavigationRoute.RoutePoints[i].Location.lat, this.NavigationRoute.RoutePoints[i].Location.lng],
          [this.NavigationRoute.RoutePoints[i + 1].Location.lat, this.NavigationRoute.RoutePoints[i + 1].Location.lng]);
      }
      return distance;
    }
    return NumberMaxValue;
  }

  private SpeedMul(): number {
    return this.Clamp(this.avg5Speed / 35, 1, 5);
  }

  private Replan(loc: GPSLocation, backgroundReplan: boolean = false, onGoingReplan: boolean = false): void {
    if (!this.ReplanningNow) {
      this.inputParamsService.setWaypointParams(this.inputParamsService.getWaypointsParams());

      if (this.NavigationRoute && this.NavigationRoute.RoutePoints &&
        this.NavigationRoute.RoutePoints.length > 0 && !this.slidersValueChangedIn15seconds && !onGoingReplan) {
        this.setBattery(loc, false);
      }
      this.ReplanningNow = true;
      this.audioService.mergeAndPlayAudio([this.audioService.getAudioSource() + "/Commands/Replan.mp3"]);
      this.inputParamsService.setBearing(loc.Course);

      if (!backgroundReplan) {
        this.TargetIdx = REPLAN_TARGET_IDX;
      }

      this.ObservableChangeNavigation.next("replan");
    }
    this.ReplanningNow = false;
  }

  private GetBatteryBeforeAndAfterNextCharge(): [number, number] {
    const nextChargingStationIdx = this.GetNextChargingStationIndexOnRoute();
    if (this.NavigationRoute && this.NavigationRoute.RoutePoints) {
      if (nextChargingStationIdx != -1 && this.NavigationRoute.RoutePoints.length > nextChargingStationIdx + 1) {
        return [Math.max(this.NavigationRoute.RoutePoints[nextChargingStationIdx].Soc, 1),
        this.NavigationRoute.RoutePoints[nextChargingStationIdx + 1].Soc]
      }
    }
    return [this.inputParamsService.getBattery() ? this.inputParamsService.getBattery() : 1, 100]
  }

  public getPredictedBattery(): number {
    if (this.NavigationRoute?.RoutePoints) {
      if (this.TargetIdx >= this.NavigationRoute.RoutePoints.length) {
        return this.NavigationRoute.RoutePoints[this.NavigationRoute.RoutePoints.length - 1].Soc * 100;
      }
      if (this.TargetIdx <= 0) {
        return this.NavigationRoute.RoutePoints[0].Soc * 100;
      }
      // interpolate battery level between route points
      const routePointsDist: number = this.utilsService.distanceBetweenCoordinates([this.NavigationRoute.RoutePoints[this.TargetIdx - 1].Location.lat, this.NavigationRoute.RoutePoints[this.TargetIdx - 1].Location.lng], [this.NavigationRoute.RoutePoints[this.TargetIdx].Location.lat, this.NavigationRoute.RoutePoints[this.TargetIdx].Location.lng]);
      const locationToTargetDist: number = this.utilsService.distanceBetweenCoordinates([this.CurrentPoint.Latitude, this.CurrentPoint.Longitude], [this.NavigationRoute.RoutePoints[this.TargetIdx].Location.lat, this.NavigationRoute.RoutePoints[this.TargetIdx].Location.lng]);
      let alpha: number = locationToTargetDist / routePointsDist;
      if (alpha > 1) { alpha = 1 }

      return this.utilsService.Lerpd(this.NavigationRoute.RoutePoints[this.TargetIdx - 1].Soc * 100, this.NavigationRoute.RoutePoints[this.TargetIdx].Soc * 100, 1 - alpha);
    }
    else {
      return null;
    }
  }

  private getBattery(): number {
    const predictedBattery = this.getPredictedBattery();
    if (Math.abs(this.inputParamsService.getBattery() - predictedBattery) < 10) {
      return predictedBattery;
    }
    else {
      return -1;
    }
  }

  private setBattery(loc: GPSLocation, exitNavigation: boolean = false) {
    const predictedBattery = this.getPredictedBattery();
    if (exitNavigation) {
      this.inputParamsService.ObservableBatteryChanged.next(this.inputParamsService.getBattery());
    }
    else if (predictedBattery < this.inputParamsService.getBattery()) {
      this.inputParamsService.setBattery(predictedBattery);
      this.inputParamsService.ObservableBatteryChanged.next(predictedBattery);
    }
  }

  private LastReplanningEarlier(): boolean {
    const dif = Date.now() - new Date(this.LastReplanTime).getTime();
    const Seconds_from_T1_to_T2 = dif / 1000;
    const Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);

    return Seconds_Between_Dates > 5;
  }

  private RoadCurrSegmentAndPosMinDist(geoPosition: GPSLocation, onlyBeforeWaypoint: boolean): number {
    if (this.TargetWithCommandIdx != -1 && this.TargetWithCommandIdx > 0) {
      let minDist = NumberMaxValue;
      let segment = 0;
      const nextChargingStationIdx = this.GetNextChargingStationIndexOnRoute();
      const nextWaypointIdx = this.GetNextWaypointIndexOnRoute() - 1;
      this.inputParamsService.setNextWaypointIdx(this.NextWaypointIdx);
      const maxPtIndex = nextChargingStationIdx != INVALID_TARGET ? Math.min(nextWaypointIdx, nextChargingStationIdx - 1) : nextWaypointIdx;

      for (let i = maxPtIndex; i >= 0; i--) {
        const dist = this.LineDist(this.NavigationRoute.RoutePoints[i].Location.lng * Math.cos(this.utilsService.toRad(this.NavigationRoute.RoutePoints[i].Location.lat)),
          this.NavigationRoute.RoutePoints[i].Location.lat,
          this.NavigationRoute.RoutePoints[i + 1].Location.lng * Math.cos(this.utilsService.toRad(this.NavigationRoute.RoutePoints[i + 1].Location.lat)),
          this.NavigationRoute.RoutePoints[i + 1].Location.lat,
          geoPosition.Longitude * Math.cos(this.utilsService.toRad(geoPosition.Latitude)),
          geoPosition.Latitude);
        if (dist < minDist) {
          minDist = dist;
          segment = i;
        }
      }
      return minDist;
    }
    else {
      return this.RoadAndPosMinDist(geoPosition, onlyBeforeWaypoint);
    }
  }

  private RoadAndPosMinDist(geoPosition: GPSLocation, onlyBeforeWaypoint: boolean): number {
    let minDist = NumberMaxValue;
    let segment = 0;
    let maxPtIndex = onlyBeforeWaypoint ? (this.GetNextWaypointIndexOnRoute() - 1) : (this.NavigationRoute.RoutePoints.length - 1);
    for (let i = 0; i < maxPtIndex; i++) {
      const dist = this.LineDist(this.NavigationRoute.RoutePoints[i].Location.lng * Math.cos(this.utilsService.toRad(this.NavigationRoute.RoutePoints[i].Location.lat)),
        this.NavigationRoute.RoutePoints[i].Location.lat,
        this.NavigationRoute.RoutePoints[i + 1].Location.lng * Math.cos(this.utilsService.toRad(this.NavigationRoute.RoutePoints[i + 1].Location.lat)),
        this.NavigationRoute.RoutePoints[i + 1].Location.lat,
        geoPosition.Longitude * Math.cos(this.utilsService.toRad(geoPosition.Latitude)),
        geoPosition.Latitude);
      if (dist < minDist) {
        minDist = dist;
        segment = i;
      }
    }
    return minDist;
  }

  public RoadAndPosMinDistCoords(geoPosition: GPSLocation): number[] {
    let minDist = NumberMaxValue;
    let segment = 0;
    for (let i = 0; i < this.NavigationRoute.RoutePoints.length - 2; i++) {
      const dist = this.LineDist(this.NavigationRoute.RoutePoints[i].Location.lng * Math.cos(this.utilsService.toRad(this.NavigationRoute.RoutePoints[i].Location.lat)),
        this.NavigationRoute.RoutePoints[i].Location.lat,
        this.NavigationRoute.RoutePoints[i + 1].Location.lng * Math.cos(this.utilsService.toRad(this.NavigationRoute.RoutePoints[i + 1].Location.lat)),
        this.NavigationRoute.RoutePoints[i + 1].Location.lat,
        geoPosition.Longitude * Math.cos(this.utilsService.toRad(geoPosition.Latitude)),
        geoPosition.Latitude);
      if (dist < minDist) {
        minDist = dist;
        segment = i;
      }
    }
    return [this.NavigationRoute.RoutePoints[segment].Location.lat, this.NavigationRoute.RoutePoints[segment].Location.lng];
  }

  public GetClosestPoint(geoPosition: [number, number]): [number[], number] {
    if (this.TargetIdx > 0 && this.TargetIdx < this.NavigationRoute.RoutePoints.length - 2) {
      let iMin = 0;
      let iMax = 1;
      if (this.TargetIdx > 1) {
        iMin = -1;
      }

      let minDist = NumberMaxValue;
      let resp = null;
      for (let i = iMin; i <= iMax; i++) {
        const lastTarget = [this.NavigationRoute.RoutePoints[this.TargetIdx + i - 1].Location.lat, this.NavigationRoute.RoutePoints[this.TargetIdx + i - 1].Location.lng];
        const actTarget = [this.NavigationRoute.RoutePoints[this.TargetIdx + i].Location.lat, this.NavigationRoute.RoutePoints[this.TargetIdx + i].Location.lng];
        const distAndPoint: any = this.LineDistAndPoint(lastTarget[0], lastTarget[1], actTarget[0], actTarget[1], geoPosition[0], geoPosition[1]);
        if (distAndPoint[0] < minDist) {
          minDist = distAndPoint[0];
          resp = [distAndPoint[1], i];
        }
      }
      if (this.utilsService.distanceBetweenCoordinates(geoPosition, resp[0]) <= 50) {
        return resp;
      }
    }

    return [geoPosition, -2];
  }

  private GetNextWaypointIndexOnRoute(): number {
    let passedWaypointsCount = this.NextWaypointIdx;
    /*for (let i = 0; i < this.ActiveWaypoints.length; i++) {
      passedWaypointsCount += ((this.ActiveWaypoints[i] == false) ? 1 : 0);
    }*/

    if (this.NavigationRoute && this.NavigationRoute.Turns) {
      for (let i = 0; i < this.NavigationRoute.Turns.length - 1; i++) {
        if (this.NavigationRoute.Turns[i].Id == "ARRIVE_TO_WAYPOINT") {
          if ((--passedWaypointsCount) < 0) {
            return this.NavigationRoute.Turns[i].RoutePointIdxStart;
          }
        }
      }
    }

    if (this.NavigationRoute) {
      return this.NavigationRoute.RoutePoints.length - 1;
    } else {
      return 0;
    }
  }

  private GetNextWaypointDisplayName(): string {
    const routePlanWayPoints: RoutePlanElement[] = this.NavigationRoute.RoutePlan.filter((routePlanEl) => { return routePlanEl.Type == RoutePlanType.WayPoint });
    if (routePlanWayPoints.length > this.NextWaypointIdx) {
      return routePlanWayPoints[this.NextWaypointIdx].Name;
    }
    else {
      return "";
    }
  }

  private GetNextChargingStationIndexOnRoute(): number {
    if (this.NavigationRoute) {
      for (let i = 0; i < this.NavigationRoute.Turns.length; i++) {
        if (this.NavigationRoute.Turns[i].RoutePointIdxStart >= this.TargetWithCommandIdx) {
          if (this.utilsService.isChargeId(this.NavigationRoute.Turns[i].Id)) {
            return this.NavigationRoute.Turns[i].RoutePointIdxStart;
          }
        }
      }
    }
    return INVALID_TARGET;
  }

  private GetNextChargingStationIndexFromChargers(): number {
    let nextChargerIdx = -1;

    if (this.NavigationRoute) {
      for (let i = 0; i < this.NavigationRoute.Turns.length; i++) {
        if (this.utilsService.isChargeId(this.NavigationRoute.Turns[i].Id)) {
          nextChargerIdx++;
        }
        if (this.NavigationRoute.Turns[i].RoutePointIdxStart >= this.TargetWithCommandIdx) {
          if (this.utilsService.isChargeId(this.NavigationRoute.Turns[i].Id)) {
            break;
          }
        }
      }
    }

    return nextChargerIdx;
  }

  private GetNextChargingStationIdOnRoute(): string {
    const routePlanWayChargers: RoutePlanElement[] = this.NavigationRoute.RoutePlan.filter((routePlanEl) => { return routePlanEl.Type == RoutePlanType.Charger });
    let nextChargerIdx = -1;

    if (this.NavigationRoute) {
      for (let i = 0; i < this.NavigationRoute.Turns.length; i++) {
        if (this.utilsService.isChargeId(this.NavigationRoute.Turns[i].Id)) {
          nextChargerIdx++;
        }
        if (this.NavigationRoute.Turns[i].RoutePointIdxStart >= this.TargetWithCommandIdx) {
          if (this.utilsService.isChargeId(this.NavigationRoute.Turns[i].Id)) {
            break;
          }
        }
      }
    }

    if (routePlanWayChargers.length > nextChargerIdx) {
      return routePlanWayChargers[nextChargerIdx].OperatorId;
    }
    else {
      return "";
    }
  }

  //calculate navigation angle
  public GetNavigationCursorAngle(index: number): number {
    let lastTargetIdx = this.TargetIdx + index - 1;
    let actTargetIdx = this.TargetIdx + index;
    if (lastTargetIdx <= -1) {
      lastTargetIdx = 0;
      actTargetIdx = 1;
    }
    const lastTarget = [this.NavigationRoute.RoutePoints[lastTargetIdx].Location.lat, this.NavigationRoute.RoutePoints[lastTargetIdx].Location.lng];
    const actTarget = [this.NavigationRoute.RoutePoints[actTargetIdx].Location.lat, this.NavigationRoute.RoutePoints[actTargetIdx].Location.lng];
    return this.utilsService.angleFromCoordinate(lastTarget[0], lastTarget[1], actTarget[0], actTarget[1]);
  }


  private Clamp(x: number, min: number, max: number): number {
    return x < min ? min : (x < max ? x : max);
  }

  private Bearing(lat_1: number, lon_1: number, lat_2: number, lon_2: number): number {
    let degree = Math.atan2(-1 * Math.sin(this.utilsService.toRad(lon_1 - lon_2)) * Math.cos(this.utilsService.toRad(lat_2)),
      Math.cos(this.utilsService.toRad(lat_1)) * Math.sin(this.utilsService.toRad(lat_2)) - Math.sin(this.utilsService.toRad(lat_1)) * Math.cos(this.utilsService.toRad(lat_2)) * Math.cos(this.utilsService.toRad(lon_1 - lon_2))) * 180 / Math.PI;
    degree = this.NormDegree(degree);
    return degree;
  }

  private NormDegree(degree: number): number {
    if (degree < 0) {
      return degree + 360;
    }
    else {
      if (degree >= 360) {
        return degree - 360;
      }
      else {
        return degree;
      }
    }
  }

  private NormAngle(angle: number): number {
    if (angle > 180) {
      angle -= 360;
      angle = Math.abs(angle);
    }
    return angle;
  }

  private LineDist(x1: number, y1: number, x2: number, y2: number, x: number, y: number): number {
    const A = x - x1;
    const B = y - y1;
    const C = x2 - x1;
    const D = y2 - y1;

    const dot = A * C + B * D;
    const len_sq = C * C + D * D;
    const param = dot / len_sq;

    let xx, yy;

    if (param < 0) {
      xx = x1;
      yy = y1;
    }
    else if (param > 1) {
      xx = x2;
      yy = y2;
    }
    else {
      xx = x1 + param * C;
      yy = y1 + param * D;
    }

    return this.dist(x, y, xx, yy);
  }

  private LineDistAndPoint(x1: number, y1: number, x2: number, y2: number, x: number, y: number): [number, number[]] {
    const A = x - x1;
    const B = y - y1;
    const C = x2 - x1;
    const D = y2 - y1;

    const dot = A * C + B * D;
    const len_sq = C * C + D * D;
    const param = dot / len_sq;

    let xx, yy;

    if (param < 0) {
      xx = x1;
      yy = y1;
    }
    else if (param > 1) {
      xx = x2;
      yy = y2;
    }
    else {
      xx = x1 + param * C;
      yy = y1 + param * D;
    }

    return [this.dist(x, y, xx, yy), [xx, yy]];
  }

  private distance(lat_1: number, lon_1: number, lat_2: number, lon_2: number, alt1: number, alt2: number): number {
    let distance = 0;
    let d_lat = 0;
    let d_lon = 0;
    let a = 0;
    let c = 0;
    d_lat = lat_2 - lat_1;
    d_lon = lon_2 - lon_1;
    a = Math.sin(this.utilsService.toRad(d_lat / 2)) * Math.sin(this.utilsService.toRad(d_lat / 2)) + Math.cos(this.utilsService.toRad(lat_1)) * Math.cos(this.utilsService.toRad(lat_2)) * Math.sin(this.utilsService.toRad(d_lon / 2)) * Math.sin(this.utilsService.toRad(d_lon / 2));
    c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    distance = c * 6366707;
    return distance;
  }

  private dist(x1, y1, x2, y2) {
    return Math.sqrt(((x1 - x2) * (x1 - x2)) + ((y1 - y2) * (y1 - y2)));
  }

  /*private GetCommand(rp) {
    if (rp.Id == "ENTER_ROUNDABOUT" ||
      rp.Id == "EXIT_ROUNDABOUT") {
      if (rp.ManeuverData) {
        if (rp.Id == "EXIT_ROUNDABOUT") {
          files.Add(string.Format(Utils.GetVoiceFile("Commands/Take1Exit.wav"), rp.ManeuverData));
          this.AddToAudioPlayList(this.voiceFolder + "Commands/Take1Exit.mp3");
        }
        else {   // EnterRoundabout
          if (Settings.Instance.VoiceDirectory.StartsWith("es-ES")) {
            files.Add(string.Format(Utils.GetVoiceFile("Commands/AtRoundaboutTake{0}Exit.wav"), rp.ManeuverData));
          }
          else {
            files.Add(Utils.GetVoiceFile("Commands/AtRoundabout.wav"));
            files.Add(string.Format(Utils.GetVoiceFile("Commands/Take{0}Exit.wav"), rp.ManeuverData));
          }
        }
      }
      else {
        files.Add(Utils.GetVoiceFile(CommandFiles[(int)rp.Maneuver]));
      }
    }
    else {
      files.Add(Utils.GetVoiceFile(CommandFiles[(int)rp.Maneuver]));
    }
    return files;
  }*/

  private loadGpxAsRoute(xmlRoot: Document): { mPosition: number, mDateTime: number, mHeight: number, mBearing: number, mSpeed: number }[] {
    let lastBearing = 0;
    const pointarray = [];
    let lastPoint = null;
    const tracks = xmlRoot.documentElement.getElementsByTagName("trk");
    for (let i = 0; i < tracks.length; i++) {
      const segments = tracks[i].getElementsByTagName("trkseg");
      for (let i = 0; i < segments.length; i++) {
        const trackpoints = segments[i].getElementsByTagName("trkpt");
        for (let i = 0; i < trackpoints.length; i++) {
          const point = { mPosition: null, mDateTime: null, mHeight: null, mBearing: null, mSpeed: null };
          point.mPosition = [
            parseFloat(trackpoints[i].getAttribute("lat")),
            parseFloat(trackpoints[i].getAttribute("lon"))
          ];
          const times = trackpoints[i].getElementsByTagName("time");
          if (times.length > 0) {
            point.mDateTime = new Date(times[0].innerHTML);
          }
          const elevations = trackpoints[i].getElementsByTagName("ele");
          if (elevations.length > 0) {
            point.mHeight = parseFloat(elevations[0].innerHTML);
          }
          const extensions = trackpoints[i].getElementsByTagName("extensions");
          if (extensions.length > 0) {
            const gpss = extensions[0].getElementsByTagName("gte:gps");
            if (gpss.length > 0) {
              point.mSpeed = parseFloat(gpss[0].getAttribute("speed"));
            }
          }
          if (lastPoint != null && ((lastPoint[0] != point.mPosition[0]) && (lastPoint[1] != point.mPosition[1]))) {
            point.mBearing = this.utilsService.angleFromCoordinate(lastPoint[0], lastPoint[1], point.mPosition[0], point.mPosition[1]);
            lastBearing = point.mBearing;
          }
          else {
            point.mBearing = lastBearing;
          }
          lastPoint = point.mPosition;
          pointarray.push(point);
        }
      }
    }
    return pointarray;
  }

  // adding random points between two planpoints for simulating a real life gps situation
  public generateFakeGpsData(routePoints: RoutePoint[]): { mPosition: number, mDateTime: number, mHeight: number, mBearing: number, mSpeed: number }[] {
    // if a gpx is selected, replay that, instead of the planned route
    if (this.selectedReplayTrack != null) {
      return this.loadGpxAsRoute(this.selectedReplayTrack);
    }

    // consts
    const DGTEARTHRADIUS_M: number = 6366707;
    const DGTEARTHCIRCUMFERENCE_M: number = 2 * DGTEARTHRADIUS_M * Math.PI;
    const pointsWithTimeArray = [];
    const DateTime = new Date();
    let lastBearing = this.utilsService.angleFromCoordinate(routePoints[0].Location.lat, routePoints[0].Location.lng,
      routePoints[1].Location.lat, routePoints[1].Location.lng);

    for (let i = 1; i < routePoints.length; i++) {
      const VehicleSpeedInMeterPerSecond = this.utilsService.KmphToMps(routePoints[i].RouteSpeedLimit);
      const DistanceInMeter = this.utilsService.distanceBetweenCoordinates([routePoints[i - 1].Location.lat, routePoints[i - 1].Location.lng],
        [routePoints[i].Location.lat, routePoints[i].Location.lng]);
      let DistanceInSecond = 0.0;
      const randomNoise = ((Math.PI * 2) / DGTEARTHCIRCUMFERENCE_M) * 3;

      if (VehicleSpeedInMeterPerSecond > 0.0) {
        DistanceInSecond = DistanceInMeter / VehicleSpeedInMeterPerSecond;
      }

      let Grade = 0.0;
      if (DistanceInMeter != 0) {
        Grade = 1 / DistanceInMeter;
      }

      let PointWithTime = { mPosition: null, mDateTime: null, mHeight: null, mBearing: null, mSpeed: null };

      if (DistanceInSecond < 1.0) {

        PointWithTime.mPosition = [this.utilsService.toRad(routePoints[i - 1].Location.lat), this.utilsService.toRad(routePoints[i - 1].Location.lng)];

        const lonRand = randomNoise * Math.cos(PointWithTime.mPosition[0]);

        PointWithTime.mPosition[1] += this.utilsService.getRandomBetweenCoordinates(-lonRand, lonRand);
        PointWithTime.mPosition[0] += this.utilsService.getRandomBetweenCoordinates(-randomNoise, randomNoise);
        DateTime.setSeconds(DateTime.getSeconds() + DistanceInSecond);
        PointWithTime.mDateTime = DateTime;
        PointWithTime.mHeight = 100;
        PointWithTime.mSpeed = this.avg5Speed;
        if ((routePoints[i - 1].Location.lat != routePoints[i].Location.lat) && (routePoints[i - 1].Location.lng != routePoints[i].Location.lng)) {
          PointWithTime.mBearing = this.utilsService.angleFromCoordinate(routePoints[i - 1].Location.lat, routePoints[i - 1].Location.lng,
            routePoints[i].Location.lat, routePoints[i].Location.lng);
          lastBearing = PointWithTime.mBearing;
        }
        else {
          PointWithTime.mBearing = lastBearing;
        }
        PointWithTime.mPosition[0] = this.utilsService.toDeg(PointWithTime.mPosition[0]);
        PointWithTime.mPosition[1] = this.utilsService.toDeg(PointWithTime.mPosition[1]);
        pointsWithTimeArray.push(PointWithTime);
      }
      else {
        const PosFrom = [this.utilsService.toRad(routePoints[i - 1].Location.lat), this.utilsService.toRad(routePoints[i - 1].Location.lng)];

        const brng = this.utilsService.toRad(this.utilsService.angleFromCoordinate(routePoints[i - 1].Location.lat, routePoints[i - 1].Location.lng,
          routePoints[i].Location.lat, routePoints[i].Location.lng));
        for (let t = 0; t < DistanceInSecond; t += 1) {
          let PointWithTime = { mPosition: null, mDateTime: null, mHeight: null, mBearing: null, mSpeed: null };
          const d = VehicleSpeedInMeterPerSecond * t;
          const dR = d / DGTEARTHRADIUS_M;
          const lon1 = PosFrom[1];
          const lat1 = PosFrom[0];

          const cosLat1 = Math.cos(lat1);
          const sinLat1 = Math.sin(lat1);
          const cosDR = Math.cos(dR);
          const sinDR = Math.sin(dR);

          const lonRand = randomNoise * cosLat1;

          let lat2 = Math.asin(sinLat1 * cosDR + cosLat1 * sinDR * Math.cos(brng)) + this.utilsService.getRandomBetweenCoordinates(-randomNoise, randomNoise);
          let lon2 = lon1 + Math.atan2(Math.sin(brng) * sinDR * cosLat1, cosDR - sinLat1 * Math.sin(lat2)) + this.utilsService.getRandomBetweenCoordinates(-lonRand, lonRand);
          const Height = 100 + Grade * d;
          PointWithTime.mPosition = [routePoints[i].Location.lat, routePoints[i].Location.lng];
          PointWithTime.mPosition[0] = lat2;
          PointWithTime.mPosition[1] = lon2;
          PointWithTime.mDateTime = DateTime;
          PointWithTime.mHeight = Height;
          DateTime.setSeconds(DateTime.getSeconds() + 1);
          PointWithTime.mSpeed = routePoints[i].RouteSpeedLimit;
          if ((routePoints[i - 1].Location.lat != routePoints[i].Location.lat) && (routePoints[i - 1].Location.lng != routePoints[i].Location.lng)) {
            PointWithTime.mBearing = this.utilsService.angleFromCoordinate(routePoints[i - 1].Location.lat, routePoints[i - 1].Location.lng,
              routePoints[i].Location.lat, routePoints[i].Location.lng);
            lastBearing = PointWithTime.mBearing;
          }
          else {
            PointWithTime.mBearing = lastBearing;
          }
          PointWithTime.mPosition[0] = this.utilsService.toDeg(PointWithTime.mPosition[0]);
          PointWithTime.mPosition[1] = this.utilsService.toDeg(PointWithTime.mPosition[1]);
          pointsWithTimeArray.push(PointWithTime);
        }
      }
    }

    pointsWithTimeArray.push({
      mPosition: [routePoints[routePoints.length - 1].Location.lat, routePoints[routePoints.length - 1].Location.lng],
      mDateTime: new Date(), mHeight: 0, mBearing: 0, mSpeed: this.avg5Speed
    })

    return pointsWithTimeArray;
  }
}
