<div class="subscriptions-promo-dialog">
    <h1 mat-dialog-title>Beta testers wanted!</h1>
    <div mat-dialog-content>
        <ng-scrollbar [autoHeightDisabled]="false" [autoWidthDisabled]="true" #subscriptionsScrollbar
            class="scrollable-body">
            <div class="header-image"></div>
            <p class="desc1">
                We are thrilled to announce you that the Android Auto and CarPlay support is now available in beta
                version!
            </p>
            <p>
                We would be happy to see you on board as a Beta Tester and ask for your feedback.
            </p>
            <p>
                To update to the beta version competely FREE please follow the links below:
            </p>
            <div class="store-buttons">
                <a href="https://testflight.apple.com/join/8aDwjblU" target="_blank"><img
                        src="assets/icons/default/ui/apple_carplay_logo.png" /></a>
                <a href="https://play.google.com/apps/testing/com.gpstuner.evnavigation" target="_blank"><img
                        src="assets/icons/default/ui/android_auto_logo.png" /></a>
            </div>
        </ng-scrollbar>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="cancel()">{{ languageService.languageJSON.Global_Cancel }}</button>
    </div>
</div>