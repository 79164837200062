import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';
import { HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { SettingsService } from 'src/app/services/settings.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-rha-slider',
  templateUrl: './rha-slider.component.html',
  styleUrls: ['./rha-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RhaSliderComponent implements OnInit {

  // input output data
  @Input() altString: string;
  @Input() digit: number;
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;
  @Input() imageSource: string;
  @Input() value: number;
  @Output() valueChange = new EventEmitter();
  @Output() valueChangeEvent = new EventEmitter();

  @ViewChild('sliderOuter', { static: true }) slider: ElementRef;

  // recalculating sliders when size changed
  @HostListener('window:resize') onResize() {
    this.setSliderToCurrValue();
  }

  private sliderFill: HTMLElement;
  private sliderImg: HTMLElement;
  private subscriptions: Subscription[] = [];
  public valueInPercent: number;

  constructor(public matDialog: MatDialog,
    public mobileResolutionService: MobileResolutionService, private consoleLoggerService: ConsoleLoggerService,
    public settingsService: SettingsService, private cdr: ChangeDetectorRef, private utilsService: UtilsService) {
  }

  ngOnChanges(): void {
    this.setSliderToCurrValue();
  }


  ngOnInit(): void {

  }

  // subscribe slider move/touch events, setting slider to the default values
  ngAfterViewInit(): void {
    const sDown = fromEvent(this.slider.nativeElement, 'mousedown');
    const sMove = fromEvent(document, 'mousemove');
    const sUp = fromEvent(document, 'mouseup');
    const sDrag = sDown.pipe(concatMap(DragEvent => sMove.pipe(takeUntil(sUp))));

    let dragged = false;
    let mddownelement = null;
    this.subscriptions.push(sDown.subscribe((md: MouseEvent) => { mddownelement = this; }));

    this.subscriptions.push(sUp.subscribe((md: MouseEvent) => { if (dragged == false && mddownelement == this) { this.moveSlider(md.pageX) } dragged = false; mddownelement = null; }));

    this.subscriptions.push(sDrag.subscribe((md: MouseEvent) => {
      //md.preventDefault();
      dragged = true;
      if (!isNaN(md.pageX)) {
        this.moveSlider(md.pageX);
      }
    }));

    const sTouchStart = fromEvent(this.slider.nativeElement, 'touchstart');
    const sTouchMove = fromEvent(this.slider.nativeElement, 'touchmove');
    const sTouchEnd = fromEvent(this.slider.nativeElement, 'touchend');
    const sTouchDrag = sTouchStart.pipe(concatMap(DragEvent => sTouchMove.pipe(takeUntil(sTouchEnd))));

    this.subscriptions.push(sTouchStart.subscribe((td: TouchEvent) => { mddownelement = this; }));
    this.subscriptions.push(sTouchEnd.subscribe((td: TouchEvent) => { if (dragged == false && mddownelement == this) { this.moveSlider(td.changedTouches[0].pageX) } dragged = false; mddownelement = null; }));

    this.subscriptions.push(sTouchDrag.subscribe((td: TouchEvent) => {
      //td.preventDefault();
      dragged = true;
      if (!isNaN(td.changedTouches[0].pageX)) {
        this.moveSlider(td.changedTouches[0].pageX);
      }
    }));

    this.sliderFill = this.slider.nativeElement.querySelector('.slider-fill');
    this.sliderImg = this.slider.nativeElement.querySelector('.slider-image');
    this.setSliderToCurrValue();

    this.subscriptions.push(this.mobileResolutionService.ObservableMobileVisiblePanel.subscribe((resp) => {
      if (resp == "car") {
        this.setSliderToCurrValue();
      }
    }));
  }

  // setting slider to the current value
  public setSliderToCurrValue(): void {
    this.valueInPercent = Math.round((this.value - this.min) / (this.max - this.min) * 100);
  }

  private moveSlider(pageX: number): void {
    const buttonWidth = 16;
    // coords
    let newPos: number = pageX - this.slider.nativeElement.getBoundingClientRect().left;
    let sliderMinPos: number = buttonWidth;
    let sliderMaxPos: number = this.slider.nativeElement.clientWidth - buttonWidth;

    // overpulled
    if (newPos < sliderMinPos) {
      newPos = sliderMinPos;
    }
    if (newPos > sliderMaxPos) {
      newPos = sliderMaxPos;
    }

    // convert position coord into step groups
    if (this.step && this.step > 0) {
      let sliderStepWidth: number = (sliderMaxPos - sliderMinPos) / (this.step);
      let currStep: number = ((newPos - sliderMinPos) / sliderStepWidth);
      newPos = sliderMinPos + currStep * sliderStepWidth;
    }

    const currValue: number = (newPos - sliderMinPos) / (sliderMaxPos - sliderMinPos);
    this.changeValue(+this.toFixed(((currValue * (this.max - this.min)) + this.min), this.digit));
  }

  // create number with fixed decimals
  private toFixed(num, precision): string {
    return this.utilsService.toFixed(num, precision);
  }

  // passing slider new value to parent
  private changeValue(value: number): void {
    this.value = value;
    this.valueChange.emit(parseFloat(value.toFixed(this.digit)));
    this.valueChangeEvent.emit();
  }
}