export const enum SubscriptionType {
    Monthly = "Monthly",
    Yearly = "Yearly",
}

export const enum SubscriptionPayment {
    Paid = "Paid",
    Voucher = "Voucher"
}

export class Subscription {
    Active: boolean;
    Expiration: string;
    Type: SubscriptionType;
    Payment: SubscriptionPayment
    UserVehicleId: number;
    CarName: string;
    CarIcon: string;
    opened?: boolean;

    constructor(active: boolean, expiration: string, type: SubscriptionType, payment: SubscriptionPayment,
        userVehcileId: number) {
        this.Active = active;
        this.Expiration = expiration;
        this.Type = type;
        this.Payment = payment;
        this.UserVehicleId = userVehcileId;
    }
}