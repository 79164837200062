<h1 mat-dialog-title>Voice Command</h1>
<div mat-dialog-content id="confirmation-dialog">
    <div *ngIf="data.commandType==2">
        <div class="flex-row">
            <img class="battery-icon"
                [src]="'assets/icons/' + settingsService.getMap().Skin + '/voicecommand/voice_accept_battery.svg'">
            <h3 class="confirmation-value"> {{ BatteryValue }} </h3>
            <small id="percent-text">%</small>
        </div>
        <div class="flex-row">
            <h4>Battery</h4>
        </div>
    </div>

    <div *ngIf="data.commandType==3">
        <div class="flex-row">
            <img class="skipwp-icon"
                [src]="'assets/icons/' + settingsService.getMap().Skin + '/voicecommand/voice_accept_skipwaypoint.svg'">
        </div>
        <div class="flex-row">
            <h4>Skip</h4>
        </div>
    </div>

    <div *ngIf="data.commandType==4">
        <div class="flex-row">
            <img class="skipcs-icon"
                [src]="'assets/icons/' + settingsService.getMap().Skin + '/voicecommand/voice_accept_skipchargingstation.svg'">
        </div>
        <div class="flex-row">
            <h4>Skip</h4>
        </div>
    </div>

    <div *ngIf="data.commandType==5">
        <div class="flex-row">
            <img class="stopnavi-icon"
                [src]="'assets/icons/' + settingsService.getMap().Skin + '/voicecommand/voice_accept_stopnavigation.svg'">
        </div>
        <div class="flex-row">
            <h4>Stop</h4>
        </div>
    </div>

    <div *ngIf="data.commandType==6">
        <div class="flex-row">
            <img [src]="'assets/icons/' + settingsService.getMap().Skin + '/voicecommand/voice_accept_persons.svg'">
            <h3 class="confirmation-value">{{ WeightValue }}</h3>
        </div>
        <div class="flex-row">
            <h4>Weight</h4>
        </div>
    </div>

    <div *ngIf="data.commandType==7">
        <div class="flex-row">
            <img
                [src]="'assets/icons/' + settingsService.getMap().Skin + '/voicecommand/voice_accept_navigatetohome.svg'">
        </div>
        <div class="flex-row">
            <h4>Navigate</h4>
        </div>
    </div>

    <div *ngIf="data.commandType==8">
        <div class="flex-row">
            <img
                [src]="'assets/icons/' + settingsService.getMap().Skin + '/voicecommand/voice_accept_navigatetooffice.svg'">
        </div>
        <div class="flex-row">
            <h4>Navigate</h4>
        </div>
    </div>

    <div *ngIf="data.commandType==9 || data.commandType==10">
        <div class="flex-row">
            <img [src]="'assets/icons/' + settingsService.getMap().Skin + '/voicecommand/voice_accept_distance.svg'">
            <h3 class="confirmation-value">{{ EstimatedTime }}</h3>
        </div>
        <div class="flex-row">
            <h4>Arrival</h4>
        </div>
    </div>

    <div *ngIf="data.commandType==11">
        <div class="flex-row">
            <img [src]="'assets/icons/' + settingsService.getMap().Skin + '/voicecommand/voice_accept_distance.svg'">

            <div class="flex-computer" [hidden]="!showMetricKMeter(RemainingDist)">
                <h3 class="confirmation-value">{{ RemainingDist/1000 | turnValueIntegerThousands }}
                </h3>
                <small>km</small>
            </div>
            <div class="flex-computer" [hidden]="!showMetricMeter(RemainingDist)">
                <h3 class="confirmation-value">{{ RemainingDist | turnValueInteger }}</h3>
                <small>m</small>
            </div>
            <div class="flex-computer" [hidden]="!showImperialMile(RemainingDist)">
                <h3 class="confirmation-value">{{ RemainingDist*0.000621371192 | turnValueIntegerThousands }}
                </h3>
                <small>mi</small>
            </div>
            <div class="flex-computer" [hidden]="!showImperialYard(RemainingDist)">
                <h3 class="confirmation-value">{{ RemainingDist*1.0936133 | turnValueInteger }}</h3>
                <small>yd</small>
            </div>

        </div>
        <div class="flex-row">
            <h5>Remaining distance</h5>
        </div>
    </div>

    <button class="cancel" (click)="Cancel()">{{ languageService.languageJSON.Global_Cancel }}</button>
</div>