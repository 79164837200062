import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable, Observer, forkJoin } from 'rxjs';
import { UtilsService } from '../services/utils.service';
import { HttpClient } from '@angular/common/http';
import { MapService } from '../services/map.service';
import { DataParserService } from '../services/data-parser.service';
import { AccountService } from '../services/account.service';

export const initResolver: ResolveFn<Observable<boolean>> = (route, state) => {

  const utilsService = inject(UtilsService);
  const http = inject(HttpClient);
  const mapService = inject(MapService);
  const dataParserService = inject(DataParserService);
  const accountService = inject(AccountService);

  const versionUrl = utilsService.isProductionUrl() ?
    "https://evnavigation.com/chargingstations/getversion.php" : "https://staging.evnavigation.com/chargingstations/getversion.php";

  const observable: Observable<boolean> = new Observable((observer: Observer<boolean>) => {
    http.get(versionUrl, { responseType: 'text' }).subscribe((version: any) => {
      mapService.setChargingStationVersion(version);

      const allVehiclesUrl = utilsService.isProductionUrl() ?
        `https://evnavigation.com/chargingstations/allvehicles.json?ver=${version}`:
        `https://staging.evnavigation.com/chargingstations/allvehicles.json?ver=${version}`;

      const chargingOperatorsUrl = utilsService.isProductionUrl() ?
        `https://evnavigation.com/chargingstations/operators.json?ver=${version}`:
        `https://staging.evnavigation.com/chargingstations/operators.json?ver=${version}`;
      
      forkJoin([http.get(allVehiclesUrl), http.get(chargingOperatorsUrl)]).subscribe((resp: any) => {
        if (resp[1]?.operators?.length > 0) {
          const parsedOperators = dataParserService.parseChargingOperatorsArray(resp[1].operators);
          mapService.setOperatorsArray(parsedOperators);
        }

        if (resp[0]?.length>0) {
          const parsedVehices = dataParserService.parseVehiclesArray(resp[0]);
          accountService.initAllVehicles(parsedVehices);
          mapService.initAllVehicles(parsedVehices);

          // user vehicles must be loaded after all vehicles initialized
          if (accountService.getIsAuthorized()) {
            accountService.getUserVehicles().subscribe(() => {
              observer.next(true);
              observer.complete();
            }, (error) => {
              observer.next(true);
              observer.complete();
            });
          }
          else {
            observer.next(true);
            observer.complete();
          }
        }
      });
    });
  });

  return observable;
};
