<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
    <img *ngIf="icon" alt="Confirmation icon" [src]="icon" class="icon" />
    <p *ngIf="message" [innerHtml]="message"></p>
    <p *ngIf="warning" class="warning" [innerHtml]="warning"></p>
    <p *ngIf="warning2" class="warning" [innerHtml]="warning2"></p>
</div>
<div mat-dialog-actions>
    <button *ngIf="buttons[0]" mat-button (click)="accept()">{{ buttons[0] }}</button>
    <button *ngIf="buttons[1]" mat-button (click)="cancel()">{{ buttons[1] }}</button>
</div>