export class UserProfile {
    Id?: number;
    Email: string;
    FirstName: string;
    LastName: string;
    Country: string;
    Avatar: string;
    Phone: number;
    Newsletter: boolean;

    constructor() {

    }
}