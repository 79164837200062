import { VoiceCommandValueType } from "../services/speech.service";

export class VoiceCommandDesc {
  ValueType: VoiceCommandValueType;
  KeyWords: string[];
  LowerInterval: number;
  UpperInterval: number;
  ConfirmedMessageFunct: (param: any) => string[];

  constructor(keyWords: string[], valueType: VoiceCommandValueType, lowerInterval: number, upperInterval: number,
    confirmedMessageFunction: (param: any) => string[]) {
    this.KeyWords = keyWords;
    this.ValueType = valueType;
    this.LowerInterval = lowerInterval;
    this.UpperInterval = upperInterval;
    this.ConfirmedMessageFunct = confirmedMessageFunction;
  }
}