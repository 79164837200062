import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { LanguageService } from 'src/app/services/language.service';
import { MapService, ShowOnMapEnum } from 'src/app/services/map.service';
import { SettingsService } from 'src/app/services/settings.service';
import { HistoryElement } from '../../../models/history';

@Component({
  selector: 'app-history-panel',
  templateUrl: './history-panel.component.html',
  styleUrls: ['./history-panel.component.scss']
})
export class HistoryPanelComponent implements OnInit {

  @Input() history: HistoryElement;
  @Output() loadingHistoryChanged = new EventEmitter();

  constructor(private consoleLoggerService: ConsoleLoggerService, public settingsService: SettingsService,
    private mapService: MapService, public accountService: AccountService,
    public languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.loadingHistoryChanged.emit(true);
    this.accountService.getTrack(this.history.id).subscribe((resp)=>{
      this.mapService.ObservableDrawTripToMap.next(resp);
      this.loadingHistoryChanged.emit(false);
    });
  }

  public goBack(): void {
    this.mapService.ObservableShowOnMap.next({ type: ShowOnMapEnum.BackHistory, data: history });
  }
}