import { Component, OnInit, ChangeDetectorRef, Renderer2, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { fromEvent } from 'rxjs';
import { first, skip } from 'rxjs/operators';
import device from 'current-device';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { MapService } from 'src/app/services/map.service';
import { LanguageService } from 'src/app/services/language.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { SearchedRouteElement } from 'src/app/models/searched-route-element';
import { SettingsService } from 'src/app/services/settings.service';
declare let google;
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() routeIcon: string;
  @Input() inputValue: string;
  @Input() multiwayScrollFromTop: number;
  @Input() waypointIdx: number;
  @Input() failedPlan: boolean;
  @Input() addressSearch: boolean = false;
  @Input() distance: number;
  @Input() batteryReservedWarning: boolean = false;
  @ViewChild('searchInput') searchInput: any;
  @ViewChild('leafletSearch') leafletSearch: any;
  @Output() searchChanged: EventEmitter<any> = new EventEmitter();
  @Output() keyPressed: EventEmitter<any> = new EventEmitter();
  @Output() goBackEvent: EventEmitter<any> = new EventEmitter();
  @Output() searchActiveChanged: EventEmitter<boolean> = new EventEmitter();
  private placesService: any;
  private autocompleteService: any;
  private geocoder: any;
  public searchResults: any;
  public searchActive: boolean;
  private searchDropdownListener: any;
  private firstClick = fromEvent(document, 'click').pipe(first());
  private documentClick = fromEvent(document, 'click').pipe(skip(1));
  private searchInterval: ReturnType<typeof setTimeout>;
  private readyToFocusText: boolean = true;
  private sessionToken: any;

  constructor(private http: HttpClient, private cdr: ChangeDetectorRef, private renderer2: Renderer2, public mobileResolutionService: MobileResolutionService,
    private mapService: MapService, public languageService: LanguageService, private consoleLoggerService: ConsoleLoggerService,
    public settingsService: SettingsService) {
  }

  ngOnInit(): void {
    this.placesService = new google.maps.places.PlacesService(document.getElementById('attributions'));
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    this.geocoder = new google.maps.Geocoder();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.searchDropdownListener) {
      this.searchDropdownListener.unsubscribe();
    }
  }

  // wait 1s after last keypress
  public onSearchSubmit(value: string): void {
    clearTimeout(this.searchInterval);
    this.searchInterval = setTimeout(() => {
      this.getSearchedList(value);
    }, 1000);
  }

  // get map search results
  private getSearchedList(value: string): void {
    const bounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(this.mapService.getMapBounds().getSouthWest().lat, this.mapService.getMapBounds().getSouthWest().lng),
      new google.maps.LatLng(this.mapService.getMapBounds().getNorthEast().lat, this.mapService.getMapBounds().getNorthEast().lng));

    // google autocomplete service for search
    this.autocompleteService.getPlacePredictions({ input: value, bounds: bounds, sessionToken: this.sessionToken }, (resp) => {
      this.searchResults = resp;
      this.searchActive = true;
      this.searchActiveChanged.emit(true);
      this.cdr.detectChanges();

      //create observable that emits click events
      if (this.searchDropdownListener) {
        this.searchDropdownListener.unsubscribe();
      }

      this.keyPressed.emit();

      this.searchDropdownListener = this.firstClick.subscribe(val => {
        this.searchActive = false;
        this.searchActiveChanged.emit(false);
        this.cdr.detectChanges();
      });
    });
  }

  public searchActivated(): void {
    if (device.mobile() && !this.addressSearch) {
      this.mobileResolutionService.setMobileVisiblePanel("search");
    }
    if (this.readyToFocusText) {
      this.readyToFocusText = false;
      this.searchInput.nativeElement.focus();
      this.searchInput.nativeElement.setSelectionRange(0, this.searchInput.nativeElement.value.length);

      this.searchDropdownListener = this.documentClick.subscribe((val: any) => {
        if (val.target != this.searchInput.nativeElement) {
          this.readyToFocusText = true;
          this.searchDropdownListener.unsubscribe();
        }
      });
    }
  }

  public searchInputChanged(value: string): void {
    if (value != null && value != "") {
      this.onSearchSubmit(value);
    }
    else {
      this.searchActive = false;
      this.searchActiveChanged.emit(false);
      this.cdr.detectChanges();
    }
  }

  public outputValue(searchEl: any): void {
    this.geocoder.geocode({ 'placeId': searchEl.place_id }, (results) => {
      if (results[0]){
        const boundingbox = [results[0].geometry.viewport.getNorthEast().lat(), results[0].geometry.viewport.getNorthEast().lng(),
          results[0].geometry.viewport.getSouthWest().lat(), results[0].geometry.viewport.getSouthWest().lng()];
          this.inputValue = searchEl.description;
          const selected: SearchedRouteElement = {
            display_name: this.inputValue,
            lat: results[0].geometry.location.lat(),
            lon: results[0].geometry.location.lng(),
            boundingbox: boundingbox
          }
          this.searchChanged.emit(selected);
      }
    });

    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
  }

  public goBack(): void {
    this.goBackEvent.emit();
  }
}
