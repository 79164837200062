<div class="tabbed-info-dialog">
  <h1 mat-dialog-title>
    <span class="title">
      <span *ngIf="menuSelected == 'welcome'">{{ languageService.languageJSON.Global_Info }} - {{ languageService.languageJSON.TabbedInfoDialog_Welcome }}</span>
      <span *ngIf="menuSelected == 'feedback'">{{ languageService.languageJSON.Global_Info }} - {{ languageService.languageJSON.TabbedInfoDialog_Feedback }}</span>
      <span *ngIf="menuSelected == 'tutorial'">{{ languageService.languageJSON.Global_Info }} - {{ languageService.languageJSON.TabbedInfoDialog_Tutorial }}</span>
      <span *ngIf="menuSelected == 'privacy'">{{ languageService.languageJSON.Global_Info }} - {{ languageService.languageJSON.TabbedInfoDialog_PrivacyPolicy }}</span>
      <span *ngIf="menuSelected == 'legal'">{{ languageService.languageJSON.Global_Info }} - {{ languageService.languageJSON.TabbedInfoDialog_Legal }}</span>
    </span>
    <span class="closebutton" (click)="clickClose()"><img
        [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/deletetrack_icon.svg'" width="25px"></span>
  </h1>
  <div mat-dialog-content>
    <div class="menuside">
      <div class="menu">
        <div class="menu-element" [ngClass]="{ 'active': menuSelected == 'welcome' }"
          (click)="changeSelectedMenu('welcome')">
          <img alt="Welcome"
            [src]="menuSelected == 'welcome' ? 'assets/icons/dark/ui/welcome_icon.svg' : '/assets/icons/' + settingsService.getMap().Skin + '/ui/welcome_icon.svg'"
            [matTooltip]="languageService.languageJSON.TabbedInfoDialog_Welcome" matTooltipShowDelay="600" matTooltipClass="google-tooltip" />
        </div>
        <div class="menu-element" [ngClass]="{ 'active': menuSelected == 'feedback' }"
          (click)="changeSelectedMenu('feedback')">
          <img alt="Feedback"
            [src]="menuSelected == 'feedback' ? 'assets/icons/dark/ui/feedback_icon.svg' : '/assets/icons/' + settingsService.getMap().Skin + '/ui/feedback_icon.svg'"
            [matTooltip]="languageService.languageJSON.TabbedInfoDialog_Feedback" matTooltipShowDelay="600" matTooltipClass="google-tooltip" />
        </div>
        <div class="menu-element" [ngClass]="{ 'active': menuSelected == 'tutorial' }"
          (click)="changeSelectedMenu('tutorial')">
          <img alt="Tutorial"
            [src]="menuSelected == 'tutorial' ? 'assets/icons/dark/ui/tutorial_icon.svg' : '/assets/icons/' + settingsService.getMap().Skin + '/ui/tutorial_icon.svg'"
            [matTooltip]="languageService.languageJSON.TabbedInfoDialog_Tutorial" matTooltipShowDelay="600" matTooltipClass="google-tooltip" />
        </div>
        <div class="menu-element" [ngClass]="{ 'active': menuSelected == 'privacy' }"
          (click)="changeSelectedMenu('privacy')">
          <img alt="Privacy Policy"
            [src]="menuSelected == 'privacy' ? 'assets/icons/dark/ui/privacy_policy_icon.svg' : '/assets/icons/' + settingsService.getMap().Skin + '/ui/privacy_policy_icon.svg'"
            [matTooltip]="languageService.languageJSON.TabbedInfoDialog_PrivacyPolicy" matTooltipShowDelay="600" matTooltipClass="google-tooltip" />
        </div>
        <div class="menu-element" [ngClass]="{ 'active': menuSelected == 'legal' }"
          (click)="changeSelectedMenu('legal')">
          <img alt="Legal"
            [src]="menuSelected == 'legal' ? 'assets/icons/dark/ui/legal_info_icon.svg' : '/assets/icons/' + settingsService.getMap().Skin + '/ui/legal_info_icon.svg'"
            [matTooltip]="languageService.languageJSON.TabbedInfoDialog_Legal" matTooltipShowDelay="600" matTooltipClass="google-tooltip" />
        </div>
      </div>
    </div>
    <ng-scrollbar [autoHeightDisabled]="false" [autoWidthDisabled]="true" #panelScrollBar class="scrollable-body">
      <div id="dialog-body">
        <app-welcome *ngIf="menuSelected == 'welcome'"></app-welcome>
        <app-feedback #feedbacktab *ngIf="menuSelected == 'feedback'"></app-feedback>
        <app-tutorial *ngIf="menuSelected == 'tutorial'"></app-tutorial>
        <app-privacy-policy *ngIf="menuSelected == 'privacy'"></app-privacy-policy>
        <app-eula *ngIf="menuSelected == 'legal'"></app-eula>
      </div>
    </ng-scrollbar>
  </div>
  <div class="tabbed-info-dialog-footer">
    <button id="footerCloseButton" name="ok_button" mat-button (click)="clickClose()">{{ languageService.languageJSON.Global_Close }}</button>
  </div>
</div>