import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountService } from 'src/app/services/account.service';
import { LanguageService } from 'src/app/services/language.service';
import { SettingsService } from 'src/app/services/settings.service';
import { AccountDialogComponent } from '../account-dialog/account-dialog.component';
import { SubscriptionsDialogComponent } from '../subscriptions-dialog/subscriptions-dialog.component';

@Component({
  selector: 'app-subscriptions-promo-dialog',
  templateUrl: './subscriptions-promo-dialog.component.html',
  styleUrls: ['./subscriptions-promo-dialog.component.scss']
})
export class SubscriptionsPromoDialogComponent implements OnInit {

  public voucherPromoCode: string = "TRYCONNECT2023";

  constructor(public dialogRef: MatDialogRef<SubscriptionsPromoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public languageService: LanguageService, public accountService: AccountService, private matDialog: MatDialog,
    private _ngZone: NgZone, public settingsService: SettingsService) {

  }

  ngOnInit(): void {
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public action(): void {
    if (this.accountService.getIsAuthorized()) {
      this._ngZone.run(() => {
        this.matDialog.open(SubscriptionsDialogComponent, {
          panelClass: 'dialog-snap-to-fullscreen', autoFocus: false, disableClose: true,
          data: { process: "newSubscriptionPromoCode"}
        });
      });

    }
    else {
      this._ngZone.run(() => {
        this.matDialog.open(AccountDialogComponent, {
          data: { process: "signIn" },
          panelClass: 'dialog-snap-to-fullscreen',
          autoFocus: false
        });
      });
    }
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    localStorage.setItem("subscriptionsPromo", "true");
  }
}
