import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LatLng } from 'leaflet';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { InputParamsService } from 'src/app/services/input-params.service';
import { LanguageService } from 'src/app/services/language.service';
import { LoggerService } from 'src/app/services/logger.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SettingsService, Unit } from 'src/app/services/settings.service';
import { SpeechService, VoiceCommand } from 'src/app/services/speech.service';
import { UtilsService } from 'src/app/services/utils.service';

const CONFIRMATION_STOP_RECOGNITION_TIMEOUT_MS: number = 5000;

@Component({
  selector: 'app-voice-command-confirmation-dialog',
  templateUrl: './voice-command-confirmation-dialog.component.html',
  styleUrls: ['./voice-command-confirmation-dialog.component.scss']
})

export class VoiceCommandConfirmationDialogComponent implements OnInit {

  public BatteryValue: number = 0;
  public WeightValue: number = 0;
  public EstimatedTime: string = "0";
  public RemainingDist: number = 0;
  private SpeechRecognitionIsActive: boolean;
  private Subscriptions = [];
  private ConfirmationStopRecognitionMs: number = CONFIRMATION_STOP_RECOGNITION_TIMEOUT_MS;
  private CloseTimer: ReturnType<typeof setTimeout>;

  constructor(
    private SpeechRecognitionService: SpeechService,
    private InputParamsService: InputParamsService,
    public dialogRef: MatDialogRef<VoiceCommandConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    public languageService: LanguageService,
    public settingsService: SettingsService,
    private consoleLoggerService: ConsoleLoggerService,
    private navigationService: NavigationService,
    private inputParamsService: InputParamsService,
    private utilsService: UtilsService,
    private loggerService: LoggerService
  ) {
  }

  ngOnInit(): void {
    this.SpeechRecognitionIsActive = false;
    this.cdr.detectChanges();

    this.Subscriptions.push(this.SpeechRecognitionService.ObservableActiveVoiceCommands.subscribe((voiceCommands: VoiceCommand[]) => {
      if (voiceCommands != null) {
        if (!this.SpeechRecognitionIsActive) {
          this.SpeechRecognitionIsActive = voiceCommands.indexOf(VoiceCommand.Confirmation_Yes) != -1;
        }
        else if (voiceCommands.length == 0) {
          this.CloseDialog();
        }
      }
    }));

    switch (this.data.commandType) {
      case VoiceCommand.SetBattery:
        this.BatteryValue = this.data.batteryValue;
        this.SpeechRecognitionService.StartVoiceConfirmation(VoiceCommand.SetBattery, this.BatteryValue);
        break;
      case VoiceCommand.SkipWP:
        this.SpeechRecognitionService.StartVoiceConfirmation(VoiceCommand.SkipWP, null);
        break;
      case VoiceCommand.SkipCS:
        this.SpeechRecognitionService.StartVoiceConfirmation(VoiceCommand.SkipCS, null);
        break;
      case VoiceCommand.StopNavigation:
        this.SpeechRecognitionService.StartVoiceConfirmation(VoiceCommand.StopNavigation, null);
        break;
      case VoiceCommand.SetWeight:
        this.WeightValue = this.data.weightValue;
        this.SpeechRecognitionService.StartVoiceConfirmation(VoiceCommand.SetWeight, this.WeightValue);
        break;
      case VoiceCommand.NavigateToHome:
        this.SpeechRecognitionService.StartVoiceConfirmation(VoiceCommand.NavigateToHome, null);
        break;
      case VoiceCommand.NavigateToWork:
        this.SpeechRecognitionService.StartVoiceConfirmation(VoiceCommand.NavigateToWork, null);
        break;
      case VoiceCommand.ReadEte:
        this.EstimatedTime = this.utilsService.timeConverter(this.data.Ete);
        this.SpeechRecognitionService.StartVoiceConfirmation(VoiceCommand.ReadEte, this.data.Ete);
        break;
      case VoiceCommand.ReadEta:
        this.EstimatedTime = this.utilsService.timeConverter(this.data.Eta);
        this.SpeechRecognitionService.StartVoiceConfirmation(VoiceCommand.ReadEta, this.data.Eta);
        break;
      case VoiceCommand.ReadRemainingDist:
        this.RemainingDist = this.data.RemainingDist;
        this.SpeechRecognitionService.StartVoiceConfirmation(VoiceCommand.ReadRemainingDist, this.data.RemainingDist);
        break;
    }

    this.CloseTimer = setTimeout(() => {
      this.CloseDialog();
    }, this.ConfirmationStopRecognitionMs);
  }

  public Cancel(): void {
    this.SpeechRecognitionService.StopRecognition();
    clearTimeout(this.CloseTimer);
    this.dialogRef.close();
  }

  private CloseDialog(): void {
    switch (this.data.commandType) {
      case VoiceCommand.SetBattery:
        this.SetBatteryLevel(this.BatteryValue);
        break;
      case VoiceCommand.SkipWP:
        if (this.navigationService.navigation) {
          this.navigationService.SkipNextWaypoint();
        }
        else {
          let waypoints = [...this.inputParamsService.getWaypointsParams()];
          waypoints.splice(1, 1);
          this.inputParamsService.setWaypointParams(waypoints);
        }
        break;
      case VoiceCommand.SkipCS:
        this.navigationService.SkipNextChargingStation();
        break;
      case VoiceCommand.StopNavigation:
        this.navigationService.ObservableChangeNavigation.next("exit");
        break;
      case VoiceCommand.SetWeight:
        this.SetWeight(this.WeightValue);
        break;
      case VoiceCommand.NavigateToHome:
        const waypointsHome = [this.inputParamsService.getWaypointsParams()[0]];
        waypointsHome.push(new LatLng(this.data.homeAddress.Location.lat, this.data.homeAddress.Location.lng));
        this.inputParamsService.setWaypointParams(waypointsHome);
        break;
      case VoiceCommand.NavigateToWork:
        const waypointsWork = [this.inputParamsService.getWaypointsParams()[0]];
        waypointsWork.push(new LatLng(this.data.workAddress.Location.lat, this.data.workAddress.Location.lng));
        this.inputParamsService.setWaypointParams(waypointsWork);
        break;
      case VoiceCommand.ReadEta:
        break;
      case VoiceCommand.ReadRemainingDist:
        break;
    }
    this.dialogRef.close();
  }

  private SetBatteryLevel(newBatteryLevel: number): void {
    this.InputParamsService.setBattery(newBatteryLevel);
    this.loggerService.onBatteryChangeEvent(newBatteryLevel);
    this.inputParamsService.ObservableBatteryChanged.next(newBatteryLevel);
    this.InputParamsService.paramsUpdate();
  }

  private SetWeight(weightValue: number): void {
    this.InputParamsService.setWeight(weightValue);
    this.loggerService.onWeightChangeEvent();
    this.InputParamsService.paramsUpdate();
  }

  public showMetricMeter(m: number): boolean {
    if (m < 1000 && this.settingsService.getUnit().Distance == Unit.Metric) {
      return true;
    }
    else {
      return false;
    }
  }

  public showMetricKMeter(m: number): boolean {
    if (m >= 1000 && this.settingsService.getUnit().Distance == Unit.Metric) {
      return true;
    }
    else {
      return false;
    }
  }

  public showImperialYard(m: number): boolean {
    if (this.utilsService.mToYard(m) < 1760 && this.settingsService.getUnit().Distance == Unit.Imperial) {
      return true;
    }
    else {
      return false;
    }
  }

  public showImperialMile(m: number): boolean {
    if (this.utilsService.mToYard(m) >= 1760 && this.settingsService.getUnit().Distance == Unit.Imperial) {
      return true;
    }
    else {
      return false;
    }
  }

  ngOnDestroy(): void {
    for (let subscription of this.Subscriptions) {
      subscription.unsubscribe();
    }
  }
}
