import { LatLng } from "leaflet";
import { AddressType } from "../services/settings.service";

export class Address {
    AddressType: AddressType;
    Description: string;
    Location: LatLng;
    BoundingBox: number[];

    constructor(addressType: AddressType, description: string, location: LatLng, boundingBox: number[]) {
        this.AddressType = addressType;
        this.Description = description;
        this.Location = location;
        this.BoundingBox = boundingBox;
    }
}