<div class="search">
  <div class="operator-search">
    <div class="search-image"></div>
    <input autocomplete="off" type="text" [placeholder]="languageService.languageJSON.SettingsDialog_TypeOperatorName" [(ngModel)]="inputValue"
      (click)="searchActivated()" #searchInput>
  </div>
  <div class="search-dropdown" *ngIf="dropdownVisibility">
    <ng-scrollbar [autoHeightDisabled]="false" [autoWidthDisabled]="true" class="scrollable-body">
      <div class="search-dropdown-item" *ngFor="let operatorEl of OperatorList | searchProperty : 'name' : inputValue" (click)="addOperator(operatorEl)">
        {{ operatorEl.name }}</div>
    </ng-scrollbar>
    
  </div>
</div>