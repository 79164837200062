import { Injectable, TemplateRef, NgZone } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { AccountService } from './account.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StackedModalsService {

  private modalStack : { componentOrTemplateRef: ComponentType<any> | TemplateRef<any>, config: MatDialogConfig<any> }[] = [];
  private openedModal: boolean = false;
  public ObservableModalStackLenght: BehaviorSubject<number>;

  constructor(public matDialog: MatDialog, private _ngZone: NgZone,
    public accountService: AccountService) {
    this.ObservableModalStackLenght = new BehaviorSubject<number>(null);
  }

  public openModal<T, D = any, R = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig<D>): void {
    if (this.openedModal === false) {
      this.openModalWindow(componentOrTemplateRef, config);
      this.openedModal = true;
    }
    else {
      let modal = { componentOrTemplateRef: componentOrTemplateRef, config: config };
      this.modalStack.push(modal);
    }
  }

  public openModalNext<T, D = any, R = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig<D>): void {
    if (this.openedModal === false) {
      this.openModalWindow(componentOrTemplateRef, config);
      this.openedModal = true;
    }
    else {
      let modal = { componentOrTemplateRef: componentOrTemplateRef, config: config };
      this.modalStack.unshift(modal);
    }
  }

  public clearStack(): void {
    this.modalStack = [];
  }

  private openModalWindow<T, D = any, R = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig<D>) {
    this._ngZone.run(() => {
      let dialogRef = this.matDialog.open(componentOrTemplateRef, config);

      if (config && config.data && (config.data as any).firstUserCar && (config.data as any).firstUserCar === true) {
        dialogRef.backdropClick().subscribe(() => {
          this.accountService.addDefaultVehicleToUser();
        });
      }

      dialogRef.afterClosed().subscribe(() => {
        this.checkStack();
      });
    });
  }

  private checkStack(): void {
    this.ObservableModalStackLenght.next(this.modalStack.length);
    if (this.modalStack.length === 0) {
      this.openedModal = false;
    }
    else {
      this.openModalWindow(this.modalStack[0].componentOrTemplateRef, this.modalStack[0].config);
      this.modalStack.shift();
    }
  }
}
