export class ECarType {
  Id: number;
  CarWeight:number;
  ChargerTypes: number[];
  DesignCapacity: number;
  DragCoefficient: number;
  DragCross: number;
  Name: string;
  Type: string;
  Subtype: string;
  Range: number;
  FactoryRange: number;
  FactoryRangeSource: string;
  TopSpeed: number;
  TotalPower: number;
  Icon: string;
  ChargePower: number;
  FastChargePower: number;
  GreenCarsLink: string;

  constructor(id: number, carWeight: number, chargerTypes: number[], designCapacity: number, dragCoefficient: number, dragCross: number,
    name: string, range: number, factoryRange: number, factoryRangeSource: string, topSpeed: number, totalPower: number, icon: string,
    chargePower: number, fastChargePower, type: string, subtype: string, greenCarsLink: string) {
    this.Id = id;
    this.CarWeight = carWeight;
    this.ChargerTypes = chargerTypes;
    this.DesignCapacity = designCapacity;
    this.DragCoefficient = dragCoefficient;
    this.DragCross = dragCross;
    this.Name = name;
    this.Range = range;
    this.FactoryRange = factoryRange;
    this.FactoryRangeSource = factoryRangeSource;
    this.TopSpeed = topSpeed;
    this.TotalPower = totalPower;
    this.Icon = icon;
    this.ChargePower = chargePower;
    this.FastChargePower = fastChargePower;
    this.Type = type;
    this.Subtype = subtype;
    this.GreenCarsLink = greenCarsLink;
  }
}