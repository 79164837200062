import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { SettingsService, Unit } from 'src/app/services/settings.service';
import { skip } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UnitSettings } from 'src/app/models/unit-settings';

@Component({
  selector: 'app-wind-tab',
  templateUrl: './wind-tab.component.html',
  styleUrls: ['./wind-tab.component.scss']
})
export class WindTabComponent implements OnInit {

  public temperatureImg: string;
  public temperatureValue: number;
  public temperatureValueText: number;
  public temperatureUnit: string = "°C";
  public windDeg: number;
  public windForce: number;
  public windForceText: number;
  public windUnit: string = "km/h";
  private subscriptions: Subscription[] = [];
  private unit: UnitSettings = { Distance: Unit.Metric, Temperature: Unit.Metric, Speed: Unit.Metric, Pressure: Unit.Metric };

  

  constructor(private cdr: ChangeDetectorRef, private utilsService: UtilsService, public settingsService: SettingsService
  ) {
  }

  public setTemperature(temperature: number, weathercode: number, windDeg: number, windForce: number): void {
    this.subscriptions.push(
      this.settingsService.ObservableUnit.subscribe((resp) => {
        if (resp) {
          this.unit = resp;
          this.setValues();
          this.cdr.detectChanges();
        }
      }));
    this.temperatureValue = temperature;
    this.temperatureImg = this.utilsService.getWeatherImgById(weathercode);
    this.windDeg = windDeg;
    this.windForce = windForce;
    this.setValues();
  }

  ngOnInit(): void {
    this.settingsService.ObservableMap.pipe(skip(1)).subscribe((mapsettings) => {
      this.cdr.detectChanges();
    });
  }

  private setValues(): void {
    if (this.unit.Temperature == Unit.Imperial) {
      this.temperatureUnit = "°F";
      this.temperatureValueText = Math.round(this.utilsService.ConvertCelsiusToFahrenheit(this.temperatureValue));
    }
    else {
      this.temperatureUnit = "°C";
      this.temperatureValueText = Math.round(this.temperatureValue);
    }

    if (this.unit.Speed == Unit.Imperial) {
      this.windUnit = "mph";
      this.windForceText = Math.round(this.utilsService.kmphToMph(this.windForce));
    }
    else {
      this.windUnit = "km/h";
      this.windForceText = Math.round(this.windForce);
    }

    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];

  }
}
