import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import device from 'current-device';
import { WebviewService } from './webview.service';
import { SettingsService, Unit } from './settings.service';
import { ConsoleLoggerService } from './console-logger.service';
import concatAudioBuffers from 'concat-audio-buffers';
import { Turn } from '../models/route';
import { UnitSettings } from '../models/unit-settings';

@Injectable({
  providedIn: 'root'
})

/**
 * playing voice instruction audio files
*/

export class AudioService {
  private audio: HTMLAudioElement;
  private audioContext: AudioContext;
  private audioSourceUrl: string;
  private gainNode: GainNode;
  private bufferNode: AudioBufferSourceNode;
  private playPromise: Promise<void>;
  private speechActive: boolean;

  constructor(private utilsService: UtilsService, private http: HttpClient,
    public webviewService: WebviewService, private settingsService: SettingsService,
    private consoleLoggerService: ConsoleLoggerService) {
    this.initAudioContext();
  }

  private initAudioContext(): void {
    this.audio = new Audio();
    let AudioContext = window.AudioContext || (window as any).webkitAudioContext;
    this.audioContext = new AudioContext();
    this.gainNode = this.audioContext.createGain();
  }

  private isMuted(): boolean {
    return this.settingsService.getVoiceGuidence().Mute || this.speechActive;
  }

  public mute(mute: boolean): void {
    this.audio.muted = mute;
    this.gainNode.gain.value = mute ? 0 : 1;
  }

  public StartNavigation(): void {
    if (this.isMuted()) {
      return;
    }

    if (!this.webviewService.IsGPSTWebview() && !window.AudioContext) {
      this.Beep();
    }
  }

  public Beep(): void {
    this.mergeAndPlayAudio(["assets/audio/beep.mp3"]);
  }

  public playVoiceCommandApprovedSignal(): void {
    this.mergeAndPlayAudio(["assets/audio/voice_recognition/voice_command_approved.mp3"]);
  }

  public playVoiceCommandDiscardedSignal(): void {
    this.mergeAndPlayAudio(["assets/audio/voice_recognition/voice_command_discarded.mp3"]);
  }

  public readCommand(dist: number, NextTurn: Turn, unit: UnitSettings): void {
    let audioPlayList: string[] = [];

    dist = this.RoundDistance(dist, unit);
    audioPlayList.push("assets/audio/beep.mp3");

    if (dist != 0) {
      // in x distance
      audioPlayList.push(this.getAudioSource() + "Commands/In.mp3");

      audioPlayList = audioPlayList.concat(this.getSoundFiles(dist, unit, "meter"));
      if (dist < 1000 && unit.Distance == Unit.Metric) {
        audioPlayList.push(this.getAudioSource() + "meters.mp3");
      }

      if (dist < 1760 && unit.Distance == Unit.Imperial) {
        audioPlayList.push(this.getAudioSource() + "yards.mp3");
      }
    }
    if (dist >= 1000 && dist < 2000 && unit.Distance == Unit.Metric) {
      audioPlayList.push(this.getAudioSource() + "kilometer.mp3");
    }
    if (dist >= 2000 && unit.Distance == Unit.Metric) {
      audioPlayList.push(this.getAudioSource() + "kilometers.mp3");
    }
    if (dist >= 1760 && unit.Distance == Unit.Imperial) {
      audioPlayList.push(this.getAudioSource() + "miles.mp3");
    }

    // turn instruction
    if (NextTurn.Id == "ENTER_ROUNDABOUT" || NextTurn.Id == "EXIT_ROUNDABOUT") {
      audioPlayList.push(this.getAudioSource() + "Commands/AtRoundabout.mp3");
      audioPlayList.push(this.getAudioSource() + "Commands/Take" + NextTurn.RoundaboutExitNumber + "Exit.mp3");
    }
    else {
      audioPlayList.push(this.getAudioSource() + "Commands/" + this.utilsService.turnList[NextTurn.TypeIndex].sound);
    }
    this.mergeAndPlayAudio(audioPlayList);
  }

  public readFollowTheRoute(dist: number, unit: UnitSettings): void {
    let audioPlayList: string[] = [];

    dist = this.RoundDistance(dist, unit);
    audioPlayList.push("assets/audio/beep.mp3");
    audioPlayList.push(this.getAudioSource() + "Commands/FollowRoute.mp3");
    audioPlayList = audioPlayList.concat(this.getSoundFiles(dist, unit, "meter"));
    if (dist < 1000 && unit.Distance == Unit.Metric) {
      audioPlayList.push(this.getAudioSource() + "meters.mp3");
    }

    if (dist >= 1000 && dist < 2000 && unit.Distance == Unit.Metric) {
      audioPlayList.push(this.getAudioSource() + "kilometer.mp3");
    }
    if (dist >= 2000 && unit.Distance == Unit.Metric) {
      audioPlayList.push(this.getAudioSource() + "kilometers.mp3");
    }
    if (dist < 1760 && unit.Distance == Unit.Imperial) {
      audioPlayList.push(this.getAudioSource() + "yards.mp3");
    }
    if (dist >= 1760 && unit.Distance == Unit.Imperial) {
      audioPlayList.push(this.getAudioSource() + "miles.mp3");
    }

    this.mergeAndPlayAudio(audioPlayList);
  }

  public getSoundFiles(number: number, unit: UnitSettings, unitType): string[] {
    let audioPlayList: string[] = [];

    /** ENGLISH, SPANISH */
    if (this.settingsService.getLanguage() == "en" || this.settingsService.getLanguage() == "es") {
      // read dot
      if (number.toString().includes(".")) {
        audioPlayList.push(this.getAudioSource() + "Numbers/" + Math.trunc(number) + ".mp3");
        audioPlayList.push(this.getAudioSource() + "point.mp3");
        audioPlayList = audioPlayList.concat(this.getSoundFiles(parseInt(number.toString().split(".")[1]), unit, "km"));
      }
      // 1-19
      else if (number > 0 && number <= 19) {
        if (unit.Distance == Unit.Imperial && (number == 1 || number == 2)) {
          audioPlayList.push(this.getAudioSource() + "Numbers/" + number + ".mp3");
        }
        else {
          audioPlayList.push(this.getAudioSource() + "Numbers/" + number + ".mp3");
        }
      }
      // 100 200 ...
      else if (number % 100 == 0 && Math.trunc(number / 1000) == 0) {
        audioPlayList.push(this.getAudioSource() + "Numbers/" + number + ".mp3");
      }
      // 10 20 ...
      else if (number % 10 == 0 && Math.trunc(number / 100) == 0) {
        audioPlayList.push(this.getAudioSource() + "Numbers/" + number + ".mp3");
      }
      // Change to km
      else if (number >= 1000 && unit.Distance == Unit.Metric && unitType == "meter") {
        if (number >= 10000) {
          audioPlayList = audioPlayList.concat(this.getSoundFiles(Math.round(number / 1000), unit, "km"));
        }
        else {
          audioPlayList = audioPlayList.concat(this.getSoundFiles(Math.round(number / 100) / 10, unit, "km"));
        }
      }
      else if (number >= 1760 && unit.Distance == Unit.Imperial && unitType == "meter") {
        if (number >= 17600) {
          audioPlayList = audioPlayList.concat(this.getSoundFiles(Math.round(number / 1760), unit, "km"));
        }
        else {
          audioPlayList = audioPlayList.concat(this.getSoundFiles(Math.round(number / 176) / 10, unit, "km"));
        }
      }
      // 1000X
      else if (number >= 1000) {
        if (number % 100 == 1) {
          audioPlayList.push(this.getAudioSource() + "Numbers/" + Math.trunc(number / 1000) * 1000 + "X.mp3");
        }
        else {
          audioPlayList.push(this.getAudioSource() + "Numbers/" + Math.trunc(number / 1000) * 1000 + "X.mp3");
          number %= 1000;
          audioPlayList = audioPlayList.concat(this.getSoundFiles(number, unit, unitType));
        }
      }
      // 100X
      else if (number >= 100) {
        if (number % 100 == 1) {
          audioPlayList.push(this.getAudioSource() + "Numbers/" + Math.trunc(number / 100) * 100 + "X.mp3");
        }
        else {
          audioPlayList.push(this.getAudioSource() + "Numbers/" + Math.trunc(number / 100) * 100 + "X.mp3");
          number %= 100;
          audioPlayList = audioPlayList.concat(this.getSoundFiles(number, unit, unitType));
        }
      }
      // 10X
      else if (number >= 20) {
        if (number % 10 == 1) {
          audioPlayList.push(this.getAudioSource() + "Numbers/" + Math.trunc(number / 10) * 10 + "X.mp3");
        }
        else {
          audioPlayList.push(this.getAudioSource() + "Numbers/" + Math.trunc(number / 10) * 10 + "X.mp3");
          number %= 10;
          audioPlayList = audioPlayList.concat(this.getSoundFiles(number, unit, unitType));
        }
      }
    }
    /** ITALIAN */
    else if (this.settingsService.getLanguage() == "it") {
      if (number >= 0 && number <= 19) {
        audioPlayList.push(this.getAudioSource() + "Numbers/" + number + ".mp3");
      }
      else if (number % 1000 == 0 && Math.trunc(number / 10000) == 0) {
        audioPlayList.push(this.getAudioSource() + "Numbers/" + number + ".mp3");
      }
      else if (number % 100 == 0 && Math.trunc(number / 1000) == 0) {
        audioPlayList.push(this.getAudioSource() + "Numbers/" + number + ".mp3");
      }
      else if (number % 10 == 0 && Math.trunc(number / 100) == 0) {
        audioPlayList.push(this.getAudioSource() + "Numbers/" + number + ".mp3");
      }
      else if (number > 5999) {
        return null;
      }
      else if (number >= 1000) {
        audioPlayList.push(this.getAudioSource() + "Numbers/" + Math.trunc(number / 1000) * 1000 + "X.mp3");
        number %= 1000;
        audioPlayList = audioPlayList.concat(this.getSoundFiles(number, unit, unitType));
      }
      else if (number >= 100) {
        audioPlayList.push(this.getAudioSource() + "Numbers/" + Math.trunc(number / 100) * 100 + "X.mp3");
        number %= 100;
        audioPlayList = audioPlayList.concat(this.getSoundFiles(number, unit, unitType));
      }
      else if (number >= 20) {
        if (number % 10 == 1 || number % 10 == 8) {
          audioPlayList.push(this.getAudioSource() + "Numbers/" + number + ".mp3");
        }
        else {
          audioPlayList.push(this.getAudioSource() + "Numbers/" + Math.trunc(number / 10) * 10 + "X.mp3");
          number %= 10;
          audioPlayList = audioPlayList.concat(this.getSoundFiles(number, unit, unitType));
        }
      }
    }
    /** GERMAN */
    else if (this.settingsService.getLanguage() == "de") {
      if (number == 1 && !number.toString().includes(".")) {
        if (unit.Distance == Unit.Imperial && unitType == "km") {
          audioPlayList.push(this.getAudioSource() + "Numbers/Numbers/einer.mp3");
        }
        else {
          audioPlayList.push(this.getAudioSource() + "Numbers/Numbers/einem.mp3");
        }
      }
      else if (number >= 0 && number <= 19) {
        audioPlayList.push(this.getAudioSource() + "Numbers/" + number + ".mp3");
      }
      else if (number % 1000 == 0 && Math.trunc(number / 10000) == 0) {
        audioPlayList.push(this.getAudioSource() + "Numbers/" + number + ".mp3");
      }
      else if (number % 100 == 0 && Math.trunc(number / 1000) == 0) {
        audioPlayList.push(this.getAudioSource() + "Numbers/" + number + ".mp3");
      }
      else if (number % 10 == 0 && Math.trunc(number / 100) == 0) {
        audioPlayList.push(this.getAudioSource() + "Numbers/" + number + ".mp3");
      }
      else if (number > 5999) {
        return null;
      }
      else if (number >= 1000) {
        audioPlayList.push(this.getAudioSource() + "Numbers/" + Math.trunc(number / 1000) * 1000 + "X.mp3");
        number %= 1000;
        audioPlayList = audioPlayList.concat(this.getSoundFiles(number, unit, unitType));
      }
      else if (number >= 100) {
        audioPlayList.push(this.getAudioSource() + "Numbers/" + Math.trunc(number / 100) * 100 + "X.mp3");
        number %= 100;
        audioPlayList = audioPlayList.concat(this.getSoundFiles(number, unit, unitType));
      }
      else if (number >= 20) {
        if (number % 10) {
          audioPlayList.push(this.getAudioSource() + "Numbers/X" + Math.trunc(number % 10) + ".mp3");
        }

        audioPlayList.push(this.getAudioSource() + "Numbers/" + Math.trunc(number / 10) * 10 + ".mp3");
        number %= 10;
      }
    }
    /** FRENCH */
    else if (this.settingsService.getLanguage() == "fr") {
      if (number == 71) {
        audioPlayList.push(this.getAudioSource() + "Numbers/71.mp3");
      }
      else if (number == 81) {
        audioPlayList.push(this.getAudioSource() + "Numbers/81.mp3");
      }
      else if (number >= 0 && number <= 19) {
        audioPlayList.push(this.getAudioSource() + "Numbers/" + number + ".mp3");
      }
      else if (number % 1000 == 0 && Math.trunc(number / 10000) == 0) {
        audioPlayList.push(this.getAudioSource() + "Numbers/" + number + ".mp3");
      }
      else if (number % 100 == 0 && Math.trunc(number / 1000) == 0) {
        audioPlayList.push(this.getAudioSource() + "Numbers/" + number + ".mp3");
      }
      else if (number % 10 == 0 && Math.trunc(number / 100) == 0 && number != 70 && number != 90) {
        audioPlayList.push(this.getAudioSource() + "Numbers/" + number + ".mp3");
      }
      else if (number > 5999) {
        return null;
      }
      else if (number >= 1000) {
        audioPlayList.push(this.getAudioSource() + "Numbers/" + Math.trunc(number / 1000) * 1000 + "X.mp3");
        number %= 1000;
        audioPlayList = audioPlayList.concat(this.getSoundFiles(number, unit, unitType));
      }
      else if (number >= 100) {
        audioPlayList.push(this.getAudioSource() + "Numbers/" + Math.trunc(number / 100) * 100 + "X.mp3");
        number %= 100;
        audioPlayList = audioPlayList.concat(this.getSoundFiles(number, unit, unitType));
      }
      else if (number >= 20) {
        if (number < 70 && number % 10 == 1) {
          audioPlayList.push(this.getAudioSource() + "Numbers/" + Math.trunc(number / 10) * 10 + "X.mp3");
          audioPlayList.push(this.getAudioSource() + "Numbers/X1.mp3");
        }
        else if (number < 70) {
          audioPlayList.push(this.getAudioSource() + "Numbers/" + Math.trunc(number / 10) * 10 + "X.mp3");
          number %= 10;
          audioPlayList = audioPlayList.concat(this.getSoundFiles(number, unit, unitType));
        }
        else if (number < 80) {
          audioPlayList.push(this.getAudioSource() + "Numbers/60.mp3");
          audioPlayList = audioPlayList.concat(this.getSoundFiles(number, unit, unitType));
        }
        else if (number < 100) {
          audioPlayList.push(this.getAudioSource() + "Numbers/80.mp3");
          audioPlayList = audioPlayList.concat(this.getSoundFiles(number, unit, unitType));
        }
      }
    }

    return audioPlayList;
  }

  // merging mp3 files
  public mergeAndPlayAudio(audioPlayList: string[], voiceCommand?: boolean): void {
    if (this.isMuted()) {
      return;
    }

    let playlistHttpCalls = audioPlayList.map((el) => { return this.http.get(el, { responseType: 'arraybuffer' }) });
    forkJoin(playlistHttpCalls).subscribe((resp: ArrayBuffer[]) => {
      if (this.webviewService.IsGPSTWebview() && device.ios()) {
        this.webviewService.PlayAudio(resp);
      }
      else if (this.webviewService.IsGPSTWebview() && device.android()) {
        const mergedAudio = this.MergeArrayBuffersFilesToUint8Arr(resp);
        this.webviewService.PlayAudioAndroid(mergedAudio);
      }
      else if (window.AudioContext) {
        this.mergeAndPlayBuffers(resp);
      }
      else {
        this.playMergedAsAudio(resp);
      }
    });
  }

  // convert arrayBuffers to audioBuffers and merge them
  private mergeAndPlayBuffers(arrayBuffers: ArrayBuffer[]): void {
    let decodePromises = [];

    for (let i = 0; i < arrayBuffers.length; i++) {
      decodePromises.push(this.audioContext.decodeAudioData(arrayBuffers[i],));
    }

    Promise.all(decodePromises).then((audioBuffers) => {

      concatAudioBuffers(audioBuffers, 1, (error, combinedBuffer) => {
        if (!error) {
          this.playAudioContext(combinedBuffer);
        }
      });
    })
  }

  // play merged audiobuffers
  private playAudioContext(mergedAudio: AudioBuffer): void {
    if (this.bufferNode) {
      this.bufferNode.disconnect();
    }
    this.bufferNode = this.audioContext.createBufferSource();
    this.bufferNode.buffer = mergedAudio;
    this.bufferNode.connect(this.audioContext.destination);
    this.bufferNode.start();
  }

  // play single audio file
  private playMergedAsAudio(arraybuffer: ArrayBuffer[]): void {
    if (this.playPromise == undefined) {
      this.audioSourceUrl = window.URL.createObjectURL(new Blob(arraybuffer, { type: "audio/mp3" }));

      this.audio.src = this.audioSourceUrl;
      this.audio.load();
      this.playPromise = this.audio.play();
      this.playPromise.then(() => {
        window.URL.revokeObjectURL(this.audioSourceUrl);
      });
    }
    else {
      this.playPromise.then(() => {
        const url = window.URL.createObjectURL(new Blob(arraybuffer, { type: "audio/mp3" }));
        this.audio.src = url;
        this.audio.load();
        this.playPromise = this.audio.play();
        this.playPromise.then(() => {
          window.URL.revokeObjectURL(this.audioSourceUrl);
        });
      })
    }
  }

  public playKeepAlive(): void {
    this.audio.src = "assets/audio/keep-alive.mp3";
    this.audio.load();
    this.audio.play();
  }

  /** return source of the audio with the selected language folder*/
  public getAudioSource(): string {

    let voiceLib = "en-US_Kendra";

    switch (this.settingsService.getLanguage()) {
      case "de":
        voiceLib = "de-DE_Marlene";
        break;
      case "en":
        voiceLib = "en-US_Kendra";
        break;
      case "es":
        voiceLib = "es-ES_Conchita";
        break;
      case "fr":
        voiceLib = "fr-FR_Celine";
        break;
      case "it":
        voiceLib = "it-IT_Carla";
        break;
      default:
        voiceLib = "en-US_Kendra";
        break;
    }

    return `assets/audio/turn/${voiceLib}/`;
  }

  public setSpeechActive(speechActive: boolean): void {
    this.speechActive = speechActive;

    /*if (speechActive) {
      this.mute(true);
    }
    if(!speechActive && !this.settingsService.getVoiceGuidence().Mute) {
      this.mute(false);
    }*/

    this.consoleLoggerService.log("speech active", this.speechActive);
  }

  // merge mp3 files into Uint8Array
  private MergeArrayBuffersFilesToUint8Arr(buffers: ArrayBuffer[]): Uint8Array {
    let byteArrays = [];
    let length = 0;

    buffers.forEach(buffer => {
      let byteArray = new Uint8Array(buffer);
      let arr = new Uint8Array(byteArray.length);
      for (let i = 0; i < byteArray.length; i++) {
        arr[i] = byteArray[i];
      }

      byteArrays.push(arr);
      length += arr.length;
    });

    let mergedArray = new Uint8Array(length);
    let offset = 0;
    byteArrays.forEach(item => {
      mergedArray.set(item, offset);
      offset += item.length;
    });

    return mergedArray;
  }

  // convert mp3 file into Uint8Array
  private ArrayBufferToUint8Arr(buffer): Uint8Array {
    let byteArray = new Uint8Array(buffer);
    let arr = new Uint8Array(byteArray.length);
    for (let i = 0; i < byteArray.length; i++) {
      arr[i] = byteArray[i];
    }

    return arr;
  }

  public RoundDistance(dist: number, unit: UnitSettings): number {
    if (unit.Distance == Unit.Metric) {
      if (dist >= 0 && dist <= 20) {
        return Math.round(dist);
      }
      else if (dist > 20 && dist <= 100) {
        return Math.round(dist / 10) * 10;
      }
      else if (dist > 100 && dist <= 250) {
        return Math.round(dist / 10) * 10;
      }
      else if (dist > 250 && dist <= 1000) {
        return Math.round(dist / 50) * 50;
      }
      else if (dist > 1000 && dist <= 100000) {
        return Math.round(dist / 100) * 100;
      }
      else {
        return Math.round(dist / 1000) * 1000;
      }
    }
    else {
      const yards = this.utilsService.mToYard(dist);
      if (yards >= 0 && yards <= 20) {
        return Math.round(this.utilsService.mToYard(dist));
      }
      else if (yards > 20 && yards <= 100) {
        return Math.round(this.utilsService.mToYard(Math.round(dist / 10) * 10));
      }
      else if (yards > 100 && yards <= 250) {
        return Math.round(this.utilsService.mToYard(Math.round(dist / 10) * 10));
      }
      else if (yards > 250 && yards <= 850) {
        return Math.round(this.utilsService.mToYard(Math.round(dist / 50) * 50));
      }
      else {
        const miles = Math.round(this.utilsService.mToMiles(dist));
        if (miles < 100) {
          return Math.round(this.utilsService.mToYard(Math.round(dist * 100) / 100));
        }
        else {
          return Math.round(this.utilsService.mToYard(Math.round(dist * 1000) / 1000));
        }
      }
    }
  }
}
