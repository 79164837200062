<mat-form-field *ngIf="selectedECar">
    <mat-select disableRipple [(value)]="selectedECar" class="brand-mat-select"
    (selectionChange)="selectionChanged()">
        <mat-select-trigger>
            <img class="vehicle-img" [src]="'/assets/icons/dark/ecars/' + selectedECar.ECarType.Icon + '.svg'" />
            <span class="vehicle-name" *ngIf="selectedECar.name"> {{ selectedECar.name }} </span>
            <span class="vehicle-name" *ngIf="!selectedECar.name"> {{ selectedECar.ECarType.Name }} </span>
        </mat-select-trigger>
        <mat-option class="user-cars-option" *ngFor="let userECar of userECars" [value]="userECar">
            <img class="vehicle-img" [src]="'/assets/icons/dark/ecars/' + userECar.ECarType.Icon + '.svg'" />
            <span class="vehicle-name" *ngIf="userECar.name"> {{ userECar.name }} </span>
            <span class="vehicle-name" *ngIf="!userECar.name"> {{ userECar.ECarType.Name }} </span>
        </mat-option>
    </mat-select>
</mat-form-field>