import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { LanguageService } from 'src/app/services/language.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-navigation-restart-dialog',
  templateUrl: './navigation-restart-dialog.component.html',
  styleUrl: './navigation-restart-dialog.component.scss'
})
export class NavigationRestartDialogComponent {

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public languageService: LanguageService, private navigationService:NavigationService) {
  }

  stopNavigation() {
    this.navigationService.ObservableChangeNavigation.next("exit");
    this.dialogRef.close();
  }

  continue() {
    this.dialogRef.close();
  }

}
