import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-voice-command-error-dialog',
  templateUrl: './voice-command-error-dialog.component.html',
  styleUrls: ['./voice-command-error-dialog.component.scss']
})
export class VoiceCommandErrorDialogComponent implements OnInit {

  public errorMsg: string;
  public notSupported: boolean = false;

  constructor(public dialogRef: MatDialogRef<VoiceCommandErrorDialogComponent>,
    public settingsService: SettingsService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.errorMsg = data.errorMsg;
    if (this.data.notSupported) {
      this.notSupported = true;
    }
  }

  ngOnInit(): void {
    if (!this.notSupported) {
      setTimeout(() => {
        this.dialogRef.close();
      }, 5000);
    }
  }

  public close(): void {
    this.dialogRef.close();
  }
}
