import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'carNameFilter'
})
export class CarNamePipe implements PipeTransform {

  transform(carName: string, carType: string, teslaCarType): string {
    const splitted = carName.split(" ");
    let carSplitName = "";
    let startIdx = 1;
    if (carType == "tesla") {
      if (teslaCarType == "Cybertruck") {
        startIdx = 2;
      }
      else if (teslaCarType == "Roadster") { }
      else {
        startIdx = 3;
      }
    }
    for (let i = startIdx; i < splitted.length; i++) {
      carSplitName += splitted[i] + " ";
    }
    return carSplitName;
  }
}
