import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'carFilter'
})
export class CarFilterPipe implements PipeTransform {

  transform(items: any[], carType: string, carSubType): any[] {
      if (!items) return [];
      if (!carType) return items;

      return items.filter(it => {
          if (it.Subtype) {
              return (it.Type == carType && it.Subtype == carSubType);
          }
          else {
              return it.Type == carType;
          }
      });
  }
}