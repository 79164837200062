<div class="account-dialog" [ngClass]="{'dialog-loader': subscriptionsLoader}">

    <h1 mat-dialog-title>
        <span *ngIf="!transferSubscriptionVehicleId && !assignSubscriptionVehicleId">{{
            SubscriptionsDialogTitles[activeStepIndex] }}</span>
        <span *ngIf="transferSubscriptionVehicleId">{{
            languageService.languageJSON.SubscriptionsDialog_Transfer_Subscription }}</span>
        <span *ngIf="assignSubscriptionVehicleId">{{
            languageService.languageJSON.SubscriptionsDialog_Assign_Subscription }}</span>
        <span class="closebutton" (click)="cancel()"><img
                [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/deletetrack_icon.svg'"
                width="25px"></span>
    </h1>
    <div mat-dialog-content>
        <!-- LOADER -->
        <div class="dialog-loader-outer" *ngIf="subscriptionsLoader">
            <mat-spinner class="dialog-loading-spinner"></mat-spinner>
        </div>

        <!-- MY SUBSCRIPTIONS -->
        <div class="subscriptions-page" *ngIf="activeStepIndex==0">
            <ng-scrollbar [autoHeightDisabled]="false" [autoWidthDisabled]="true" class="inner-scrollbar scrollable-body">
                <div class="subscriptions-outer mat-dialog-row" *ngIf="activeSubscriptions.length>0">
                    <h2>{{ languageService.languageJSON.SubscriptionsDialog_ActiveSubscriptions }}</h2>
                    <div class="subscription" *ngFor="let activeSubscription of activeSubscriptions">
                        <div class="subscription-details">
                            <div class="subscription-details-row">
                                <div class="subscription-details-title">
                                    {{ languageService.languageJSON.Global_Car }}
                                </div>
                                <div class="subscription-details-description" *ngIf="activeSubscription.CarName">
                                    {{ activeSubscription.CarName }}
                                </div>
                                <div class="subscription-details-description" *ngIf="!activeSubscription.CarName">
                                    {{ languageService.languageJSON.SubscriptionsDialog_AssignToCar }}
                                </div>
                                <div *ngIf="!activeSubscription.CarName" class="assign-warning">!</div>
                            </div>
                            <div class="subscription-details-row">
                                <div class="subscription-details-title">
                                    {{ languageService.languageJSON.CarSelectorDialog_Type }}
                                </div>
                                <div class="subscription-details-description">
                                    {{ getSubscriptionType(0) }}
                                </div>
                            </div>
                            <div class="subscription-details-row">
                                <div class="subscription-details-title">
                                    {{ languageService.languageJSON.SubscriptionsDialog_Expire }}
                                </div>
                                <div class="subscription-details-description">
                                    {{ activeSubscription.Expiration | date:'shortDate' }}
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'opened': activeSubscription.opened}" class="subscription-functions">
                            <button class="evnavi-button grayscale">{{
                                languageService.languageJSON.SubscriptionsDialog_UpgradeToYearly }}</button>
                            <button class="evnavi-button grayscale">{{
                                languageService.languageJSON.SubscriptionsDialog_DisableAutoRenew
                                }}</button>
                            <button class="evnavi-button" *ngIf="activeSubscription.CarName"
                                (click)="startTransferSubscription(activeSubscription)">{{
                                languageService.languageJSON.SubscriptionsDialog_Transfer }}</button>
                            <button class="evnavi-button" *ngIf="!activeSubscription.CarName"
                                (click)="startAssignSubscription(activeSubscription)">{{
                                languageService.languageJSON.SubscriptionsDialog_Assign }}</button>
                        </div>
                        <div class="subscription-functions-opener" [ngClass]="{'opened': activeSubscription.opened}"
                            (click)="activeSubscription.opened = ! activeSubscription.opened"><img
                                src="/assets/icons/dark/ui/open_arrow.svg" /></div>
                    </div>
                </div>
                <div class="subscriptions-outer mat-dialog-row" *ngIf="inactiveSubscriptions.length>0">
                    <h2>{{ languageService.languageJSON.SubscriptionsDialog_InactiveSubscriptions }}</h2>
                    <div class="subscription" *ngFor="let inactiveSubscription of inactiveSubscriptions">
                        <div class="subscription-details">
                            <div class="subscription-details-row">
                                <div class="subscription-details-title">
                                    {{ languageService.languageJSON.Global_Car }}
                                </div>
                                <div class="subscription-details-description" *ngIf="inactiveSubscription.CarName">
                                    {{ inactiveSubscription.CarName }}
                                </div>
                                <div class="subscription-details-description" *ngIf="!inactiveSubscription.CarName">
                                    {{ languageService.languageJSON.SubscriptionsDialog_AssignToCar }}
                                </div>
                            </div>
                            <div class="subscription-details-row">
                                <div class="subscription-details-title">
                                    {{ languageService.languageJSON.CarSelectorDialog_Type }}
                                </div>
                                <div class="subscription-details-description">
                                    {{ getSubscriptionType(0) }}
                                </div>
                            </div>
                            <div class="subscription-details-row">
                                <div class="subscription-details-title">
                                    {{ languageService.languageJSON.SubscriptionsDialog_Expired }}
                                </div>
                                <div class="subscription-details-description">
                                    {{ inactiveSubscription.Expiration | date:'shortDate' }}
                                </div>
                            </div>
                        </div>
                        <div class="subscription-functions-opener grayscale"><img src="/assets/icons/dark/ui/open_arrow.svg" />
                        </div>
                    </div>
                </div>
                <div *ngIf="activeSubscriptions.length==0 && inactiveSubscriptions.length==0">
                    <img class="subscription-image"
                        [src]="'/assets/icons/' + settingsService.getMap().Skin +'/ui/subscription_image.svg'" />
                    <p class="subscription-description">{{
                        languageService.languageJSON.CarSelectorDialog_SmartConnection_Desc }} </p>
                </div>
            </ng-scrollbar>
            <div class="bottom-panel">
                <button class="evnavi-button" (click)="nextStep()">
                    <div class="plus-icon">+</div>{{ languageService.languageJSON.SubscriptionsDialog_AddNew }}
                </button>
            </div>
        </div>

        <ng-scrollbar [autoHeightDisabled]="false" [autoWidthDisabled]="true" #subscriptionsScrollbar class="scrollable-body" *ngIf="activeStepIndex!=0">
            <div class="scroll-active-width">

                <img class="subscription-image" *ngIf="activeStepIndex>0 && activeStepIndex<7 && activeStepIndex!=4"
                    [src]="'/assets/icons/' + settingsService.getMap().Skin +'/ui/subscription_image.svg'" />

                <!-- SELECT CAR -->
                <div class="subscriptions-page select-car" *ngIf="activeStepIndex==1">
                    <div class="mat-dialog-row remove-top-padding">
                        <div class="input-el flex-column">
                            <p *ngIf="!addNewSubscriptionError">{{
                                languageService.languageJSON.SubscriptionsDialog_AddNew_Desc }}</p>
                            <p *ngIf="addNewSubscriptionError" class="add-new-error">{{
                                languageService.languageJSON.SubscriptionsDialog_AddNew_Error }}</p>
                            <app-user-cars-selector [newSubscription]="true"
                                (valueChange)="setSelectedUserVehicle($event)">
                            </app-user-cars-selector>
                        </div>
                    </div>
                </div>

                <!-- COMPATIBILITY -->
                <div class="subscriptions-page compatibility" [formGroup]="secondStepFormGroup"
                    *ngIf="activeStepIndex==2">
                    <div class="mat-dialog-row remove-top-padding">
                        <div class="input-el flex-column">
                            <p class="compatibility-desc">{{languageService.languageJSON.SubscriptionsDialog_VIN_Desc}}</p>
                            <p class="compatibility-support">{{languageService.languageJSON.SubscriptionsDialog_SupportedInfo }}
                                <a target="_blank" href="https://smartcar.com/product/compatible-vehicles/">SmartCar website</a>.</p>
                            <input placeholder="VIN number" name="vin" formControlName="vinValue" type="text">
                            <h6 class="invalid" *ngIf="compatibilityError">
                                {{ compatibilityError }}
                            </h6>
                            <h6 class="invalid" *ngIf="transferSubscriptionError">
                                {{ languageService.languageJSON[transferSubscriptionError] }}
                            </h6>
                        </div>
                    </div>
                </div>

                <!-- COMPATIBILITY RESULT -->
                <div class="subscriptions-page compatibility-result" *ngIf="activeStepIndex==3">
                    <div class="mat-dialog-row remove-top-padding">
                        <div class="input-el flex-column">
                            <h3 class="success"><img class="success-icon"
                                    [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/registration_icon_success.svg'" />
                                {{languageService.languageJSON.SubscriptionsDialog_VIN_Succ_Title}}</h3>
                            <p>{{languageService.languageJSON.SubscriptionsDialog_VIN_Succ_Desc }}</p>
                        </div>
                    </div>
                </div>

                <!-- SELECT PLAN -->
                <div class="subscriptions-page" *ngIf="activeStepIndex==4">
                    <div class="mat-dialog-row inactive remove-top-padding">
                        <div class="subscription-plans-outer">
                            <div class="subscription-plan"
                                *ngFor="let subscriptionPlan of subscriptionPlans; index as i"
                                [ngClass]="{'active': i==selectedSubscriptionplan}"
                                (click)="selectedSubscriptionplan=i">
                                <div class="radio-button flex-row">
                                    <img class="checkmark-success-icon" *ngIf="i==selectedSubscriptionplan"
                                        [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/success_icon.svg'" />

                                </div>
                                <h3 class="flex-row">{{ subscriptionPlan.type }}</h3>
                                <div class="flex-row">
                                    <h1>{{ subscriptionPlan.monthlyPrice }}</h1>
                                    <h4>€</h4>
                                </div>
                                <h3 class="flex-row lightWeight">
                                    /{{ languageService.languageJSON.Global_Month }}
                                </h3>
                                <h3 class="flex-row lightWeight lightColor">
                                    {{ subscriptionPlan.yearlyPrice }} €/{{ languageService.languageJSON.Global_Year }}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div class="mat-dialog-row">
                        <div class="subscription-plan-horizontial" [ngClass]="{'active': selectedSubscriptionplan==2}"
                            (click)="selectedSubscriptionplan=2">
                            <div class="radio-button">
                                <img class="checkmark-success-icon" *ngIf="selectedSubscriptionplan==2"
                                    [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/success_icon.svg'" />

                            </div>
                            <h2>{{ languageService.languageJSON.SubscriptionsDialog_RedeemVoucher }}</h2>
                        </div>
                    </div>
                </div>

                <!-- PAYMENT -->
                <div class="subscriptions-page" *ngIf="activeStepIndex==5">
                </div>

                <!-- VOUCHER -->
                <div class="subscriptions-page" *ngIf="activeStepIndex==6">
                    <div class="mat-dialog-row remove-top-padding" [formGroup]="voucherIdForm">
                        <div class="input-el flex-column">
                            <p>{{ languageService.languageJSON.SubscriptionsDialog_Voucher_Desc }}</p>
                            <input [placeholder]="languageService.languageJSON.SubscriptionsDialog_VoucherId"
                                name="voucher" formControlName="voucherValue" type="text">
                            <h6 class="invalid" *ngIf="voucherError">
                                {{ languageService.languageJSON[voucherError] }}
                            </h6>
                        </div>
                    </div>
                </div>

                <!-- SUCCESSFUL SUBSCRIPTION -->
                <div class="mat-dialog-row" *ngIf="activeStepIndex==7">
                    <div class="input-el flex-column">
                        <div class="selected-car-icon">
                            <img
                                [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ecars/' + newSubscription.CarIcon + '.svg'" />
                            <div class="car-badge">
                                <img
                                    [src]="'./assets/icons/' + settingsService.getMap().Skin + '/ui/smartcar_icon_blue.svg'" />
                            </div>
                        </div>
                        <h2 class="success">
                            <img class="success-icon"
                                [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/registration_icon_success.svg'" />
                            {{languageService.languageJSON.SubscriptionsDialog_Success_Title}}
                        </h2>
                        <h3 class="success description">
                            {{languageService.languageJSON.SubscriptionsDialog_Success_Desc}}</h3>
                        <div class="subscription">
                            <div class="subscription-details subscription-summary">
                                <div class="subscription-details-row">
                                    <div class="subscription-details-title">
                                        {{ languageService.languageJSON.Global_Car }}
                                    </div>
                                    <div class="subscription-details-description">
                                        {{ newSubscription.CarName }}
                                    </div>
                                </div>
                                <div class="subscription-details-row">
                                    <div class="subscription-details-title">
                                        {{ languageService.languageJSON.CarSelectorDialog_Type }}
                                    </div>
                                    <div class="subscription-details-description">
                                        {{ languageService.languageJSON.SubscriptionsDialog_Voucher }}
                                    </div>
                                </div>
                                <div class="subscription-details-row">
                                    <div class="subscription-details-title">
                                        {{ languageService.languageJSON.SubscriptionsDialog_Expire }}
                                    </div>
                                    <div class="subscription-details-description">
                                        {{ newSubscription.Expiration | date:'shortDate' }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- SUCCESSFUL CONNECTION -->
                <div class="mat-dialog-row" *ngIf="activeStepIndex==8">
                    <div class="input-el flex-column">
                        <div class="succ-icon">
                            <img
                                [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/registration_icon_success.svg'" />
                        </div>
                        <h3 class="success">{{ languageService.languageJSON.AccountDialog_Success }}</h3>
                        <h5 class="h5-succ">{{
                            languageService.languageJSON.SubscriptionsDialog_ConnectionSuccess_Description }}</h5>
                    </div>
                </div>
            </div>
        </ng-scrollbar>
    </div>
    <div mat-dialog-actions>
        <button *ngIf="activeStepIndex==0" mat-button (click)="cancel()">{{ languageService.languageJSON.Global_Cancel
            }}</button>
        <button *ngIf="activeStepIndex!=0 && activeStepIndex<7" mat-button (click)="goBack()">{{
            languageService.languageJSON.Global_Back }}</button>
        <button
            *ngIf="activeStepIndex!=0 && activeStepIndex<7 && (activeStepIndex!=1 || activeStepIndex==1 && !addNewSubscriptionError)"
            mat-button (click)="nextStep()">{{
            languageService.languageJSON.Global_Next }}</button>
        <button *ngIf="activeStepIndex==7" mat-button (click)="finish()">{{
            languageService.languageJSON.SubscriptionsDialog_SignInYourCarAccount
            }}</button>
        <button *ngIf="activeStepIndex==8" mat-button (click)="cancel()">{{
            languageService.languageJSON.Global_Ok
            }}</button>
    </div>

</div>