import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'turnValueIntegerThousands'
})
export class TurnValueIntegerThousandsPipe implements PipeTransform {

  transform(val: number): string {
      if (val !== undefined && val !== null) {
          if (val < 10 && val.toFixed(1)[val.toFixed(1).length - 1] != "0") {
              return val.toFixed(1);
          }
          else {
              return val.toFixed(0);
          }
      } else {
          return "";
      }
  }
}