<div class="welcome-dialog">
    <h1 mat-dialog-title>
        <span>{{ dialogTitle }}</span>
    </h1>

    <div mat-dialog-content>
        <ng-scrollbar class="scrollable-body" [autoHeightDisabled]="false" [autoWidthDisabled]="true">
            <div id="dialog-body">
                <app-welcome *ngIf="activeComponent == 'welcome'" [hideChangelog]="true"></app-welcome>
                <app-language-selector *ngIf="activeComponent == 'language'"></app-language-selector>
                <app-tutorial [welcomeDialog]="true" #tutorialElement *ngIf="activeComponent == 'tutorial'"></app-tutorial>
            </div>
        </ng-scrollbar>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="onCloseClick()" *ngIf="activeComponent == 'welcome' || activeComponent == 'language'">{{ button }}</button>
        <button mat-button (click)="onOpenTutorialClick()" *ngIf="activeComponent == 'welcome'">{{ languageService.languageJSON.TabbedInfoDialog_Tutorial }}</button>
        <button mat-button (click)="onCloseClick()" *ngIf="activeComponent == 'tutorial' && step < 3">{{ languageService.languageJSON.Global_Skip }}</button>
        <button mat-button (click)="onNextClick()" *ngIf="activeComponent == 'tutorial'">{{ languageService.languageJSON.Global_Next  }}</button>
    </div>
</div>