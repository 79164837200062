import { Component, OnInit, ChangeDetectorRef, Input, ChangeDetectionStrategy, NgZone } from '@angular/core';
import { SpeechService, VoiceCommand } from 'src/app/services/speech.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccountService } from 'src/app/services/account.service';
import { SettingsService } from 'src/app/services/settings.service';
import { skip } from 'rxjs/operators';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { VoiceCommandErrorDialogComponent } from '../modals/voice-command-error-dialog/voice-command-error-dialog.component';
import device from 'current-device';
import { WebviewService } from 'src/app/services/webview.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-voice-input',
  templateUrl: './voice-input.component.html',
  styleUrls: ['./voice-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class VoiceInputComponent implements OnInit {
  @Input() Desktop: boolean;
  public IsActive: boolean = false;

  private subscriptions: Subscription[] = [];
  private errorDialogRef: MatDialogRef<VoiceCommandErrorDialogComponent> = null;

  constructor(
    public SpeechRecognitionService: SpeechService,
    private cdr: ChangeDetectorRef,
    private matDialog: MatDialog,
    public accountService: AccountService,
    public settingsService: SettingsService,
    private consoleLoggerService: ConsoleLoggerService,
    public webviewService: WebviewService,
    private _ngZone: NgZone) { }

  ngOnInit(): void {
    this.subscriptions.push(this.SpeechRecognitionService.ObservableActiveVoiceCommands.subscribe((voiceCommands: VoiceCommand[]) => {
      if (voiceCommands != null) {
        this.IsActive = voiceCommands.indexOf(VoiceCommand.SetBattery) != -1;
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.settingsService.ObservableMap.pipe(skip(1)).subscribe((resp) => {
      if (resp) {
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.SpeechRecognitionService.ObservableVoiceInputError.subscribe((resp: any) => {
      if ((resp?.error && resp?.error === "service-not-allowed"
      || resp?.error && resp?.error === "not-allowed")  && document.getElementsByClassName("mat-dialog-title").length == 0) {
        this.openVoiceInputErrorDialog();
      }
    }));
  }

  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public ToggleSpeechRecognition(): void {
    const desktopEdge = device.desktop() && window.navigator.userAgent.indexOf("Edg") > -1;
    if (('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) && !desktopEdge) {
      if (this.IsActive) {
        this.SpeechRecognitionService.StopRecognition();
      } else {
        this.StartRecognition();
      }
    }
    else {
      this.openVoiceInputErrorDialog();
    }
  }

  private openVoiceInputErrorDialog(): void {
    this._ngZone.run(() => {
      this.errorDialogRef = this.matDialog.open(VoiceCommandErrorDialogComponent,
        {
          data: {
            errorMsg: "This feature is not supported on this device.",
            notSupported: true
          }
        });

      this.errorDialogRef.afterClosed().subscribe(() => {
        this.errorDialogRef = null;
      });
    });
  }

  private StartRecognition(): void {
    this.SpeechRecognitionService.StartRecognition(
      [VoiceCommand.SetBattery, VoiceCommand.SkipWP, VoiceCommand.SkipCS,
      VoiceCommand.StopNavigation, VoiceCommand.SetWeight, VoiceCommand.NavigateToHome,
      VoiceCommand.NavigateToWork, VoiceCommand.ReadEta, VoiceCommand.ReadEte, VoiceCommand.ReadRemainingDist]
    );
  }
}
