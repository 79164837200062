import { BehaviorSubject } from 'rxjs';
import { Injectable, HostListener } from '@angular/core';
import device, { CurrentDeviceInterface } from 'current-device';

@Injectable({
  providedIn: 'root'
})

export class MobileResolutionService {
  @HostListener('window:resize') onResize() {

  }
  private mobileResolution: boolean;
  private mobileVisiblePanel: string;
  private mobilevisibleRoutePanel: string;
  public ObservableMobileResolution: BehaviorSubject<boolean>;
  public ObservableMobileVisiblePanel: BehaviorSubject<string>;
  public ObservableMobileVisibleRoutePanel: BehaviorSubject<string>;

  constructor() {
    this.ObservableMobileResolution = new BehaviorSubject<boolean>(this.mobileResolution);
    this.ObservableMobileVisiblePanel = new BehaviorSubject<string>(this.mobileVisiblePanel);
    this.ObservableMobileVisibleRoutePanel = new BehaviorSubject<string>(this.mobilevisibleRoutePanel);
  }

  public setMobileResolution(res: boolean): void {
    this.mobileResolution = res;
    this.ObservableMobileResolution.next(res);
  }

  public getMobileResolution(): boolean {
    return this.mobileResolution;
  }

  public setMobileVisiblePanel(name: string): void {
    this.mobileVisiblePanel = name;
    this.ObservableMobileVisiblePanel.next(name);
  }

  public getMobileVisiblePanel(): string {
    return this.mobileVisiblePanel;
  }

  public setMobileVisibleRoutePanel(name: string): void {
    this.mobilevisibleRoutePanel = name;
    this.ObservableMobileVisibleRoutePanel.next(name);
  }

  public getMobileVisibleRoutePanel(): string {
    return this.mobilevisibleRoutePanel;
  }

  public isPortrait(): boolean {
    if (window.innerHeight > window.innerWidth) {
      return true;
    }
    else {
      return false;
    }
  }

  public getDevice(): CurrentDeviceInterface {
    return device;
  }
}
