import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, HostListener, Input, NgZone } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { InputParamsService } from 'src/app/services/input-params.service';
import { MapService } from 'src/app/services/map.service';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { UtilsService } from 'src/app/services/utils.service';
import { CarSelectorDialogComponent } from '../../modals/car-selector-dialog/car-selector-dialog.component';
import { AccountService } from 'src/app/services/account.service';
import { ManageCarsComponent } from './manage-cars/manage-cars.component';
import { StackedModalsService } from 'src/app/services/stacked-modals.service';
import { SettingsService, Unit } from 'src/app/services/settings.service';
import { LanguageService } from 'src/app/services/language.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { RhaSliderComponent } from './rha-slider/rha-slider.component';
import { LoggerService } from 'src/app/services/logger.service';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ECarType } from 'src/app/models/ecar-type';
import { RangeParams } from 'src/app/models/range-params';
import { ECar, ECarSettings } from 'src/app/models/ecar';

@Component({
  selector: 'app-slider-sidebar',
  templateUrl: './slider-sidebar.component.html',
  styleUrls: ['./slider-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderSidebarComponent implements OnInit {
  @ViewChild('sliderScrollbar', { static: true }) sliderScrollbar: NgScrollbar;
  @ViewChild('manageCarsComponent') manageCarsComponent: ManageCarsComponent;
  @ViewChild('batterySlider') batterySliderComponent: RhaSliderComponent;
  @Input() navigation: boolean;
  public mobileResolution: boolean = false;
  public mobileVisiblePanel: string = null;
  public selectedMode: string = "range";
  public batteryLevel: number = 73;
  public batteryLevelMin: number = 1;
  public weight: number = 2;
  public tirePressure: number = 2.3;
  public tirePressureString: string = "2.3 Bar";
  public userBehavior: number = 50;
  public userBehaviorString: string = "normal";
  public batterySafetyRhaString: string;
  public speedLimiter: number = 100;
  public speedLimiterString: string = "100 km/h";
  public minBatteryAtDestination: number = 10;
  public batterySafetyLimitRha: number = 50;
  public batterySafetyLimitRoute: number = 15;
  public weightMin: number = 1;
  public weightMax: number = 5;
  public speedLimiterMin: number = 80;
  public speedLimiterMax: number = 130;

  public flatRangeImg: string = "./assets/icons/default/slider/slider_small_button_battery.svg";
  public weightImg: string = "./assets/icons/default/slider/slider_button_weight.svg";
  public tirePressureImg: string = "./assets/icons/default/slider/slider_small_button_tirepressure.svg";
  public userBehaviorImg: string = "./assets/icons/default/slider/slider_small_button_userbehavior.svg";
  public speedLimiterImg: string = "./assets/icons/default/slider/slider_small_button_speedlimiter.svg";
  public batterySafetyLimitImg: string = "./assets/icons/default/slider/slider_button_safetylimit.svg";
  public minBatteryAtDestinationImg: string = "./assets/icons/default/slider/slider_small_button_batteryatdestination.svg";

  public selectedCar: ECarType = {
    Id: -1,
    CarWeight: null,
    ChargerTypes: null,
    DesignCapacity: null,
    DragCoefficient: null,
    DragCross: null,
    Name: "",
    Type: null,
    Subtype: null,
    Range: null,
    FactoryRange: null,
    FactoryRangeSource: "",
    TopSpeed: null,
    TotalPower: null,
    Icon: null,
    FastChargePower: null,
    ChargePower: null,
    GreenCarsLink: null
  };
  public selectedCarNameString: string = "";
  public selectedCarMultilineString: string = "";

  private subscriptions: Subscription[] = [];
  public manageCars: boolean = true;
  public manageCarsExpanded: boolean = false;
  public slidersValueChangedIn15seconds = false;

  updateCarSettignsInterval: ReturnType<typeof setTimeout>;
  slidersValueChangedInterval: ReturnType<typeof setTimeout>;
  changeUserSettingsInterval: ReturnType<typeof setTimeout>;

  @HostListener('window:resize') onResize() {
    setTimeout(() => {
      this.sliderScrollbar.update();
    }, 500);
  }

  // display selected car, car properties sliders
  constructor(private inputParamsService: InputParamsService, public mapService: MapService,
    public mobileResolutionService: MobileResolutionService, private cdr: ChangeDetectorRef,
    private utilsService: UtilsService, public accountService: AccountService,
    private stackedModalsService: StackedModalsService, public settingsService: SettingsService,
    public languageService: LanguageService, private navigationService: NavigationService,
    private consoleLoggerService: ConsoleLoggerService, private loggerService: LoggerService,
    private _ngZone: NgZone, private matDialog: MatDialog) {
    this.selectedCar.Name = "";
    this.selectedCarNameString = "";
    this.selectedCarMultilineString = "";
  }

  ngOnInit() {
    // observe input params
    this.subscriptions.push(this.inputParamsService.ObservableRangeInputParams.subscribe((resp: RangeParams) => {
      if (resp && resp.RangeInputParams) {
        this.batteryLevel = Math.round(resp.RangeInputParams.batteryLevel);
        this.weight = resp.RangeInputParams.weight;
        this.tirePressure = resp.RangeInputParams.tirePressure;
        this.getTirePressure();
        this.userBehavior = resp.RangeInputParams.userBehavior;
        this.getUserBehavior();
        this.minBatteryAtDestination = resp.RangeInputParams.minBatteryAtDestination;
        this.batterySafetyLimitRha = resp.RangeInputParams.batterySafetyLimitRha;
        this.batterySafetyLimitRoute = resp.RangeInputParams.batterySafetyLimitRoute;
        this.getBatterySafetyLimit();
        this.speedLimiter = resp.RangeInputParams.speedLimiter;
        this.getSpeedLimiter();
        this.cdr.detectChanges();
      }
    }));

    this.getBatterySafetyLimit();
    this.getTirePressure();
    // observe selected car
    this.subscriptions.push(this.mapService.ObservableSelectedCar.subscribe((resp) => {
      if (resp) {

        this.selectedCar = resp.ECarType;
        this.selectedCarMultilineString = "";
        if (resp.name.length > 0) {
          this.selectedCarNameString = resp.name;
        }
        else {
          this.selectedCarNameString = this.selectedCar.Name;
          if (this.selectedCarNameString && this.selectedCarNameString.toLocaleLowerCase().includes("tesla model")) {
            this.selectedCarMultilineString = this.selectedCarNameString.substr(14);
            this.selectedCarNameString = this.selectedCarNameString.substr(0, 13);
          }
        }
        this.cdr.detectChanges();
      }
    }));

    //observe selected mode
    this.subscriptions.push(this.inputParamsService.ObservableSelectedMode.subscribe((resp) => {
      this.selectedMode = resp;
      this.inputParamsService.setSliderRangeInputParams(this.getRangeSliderParams());
      this.cdr.detectChanges();
    }));
    this.sliderScrollbar.update();

    // observe unit
    this.subscriptions.push(this.settingsService.ObservableUnit.subscribe((resp) => {
      if (resp) {
        this.getSpeedLimiter();
        this.getTirePressure();
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.languageService.ObservableLanguageLoaded.subscribe((resp) => {
      if (resp) {
        this.getBatterySafetyLimit();
        // cdr detection already included in the get battery safety limit function
      }
    }));

    this.loadSettings();

    if (this.speedLimiter <= 45) {
      this.speedLimiter = 100;
      this.getSpeedLimiter();
    }

    this.subscriptions.push(this.accountService.ObservableIsAuthorized.subscribe((resp) => {
      if (resp != undefined) {
        this.manageCars = resp;
        if (resp == false) {
          this.manageCarsExpanded = false;
          this.loadSettings();
        }
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.mapService.ObservableNavigation.subscribe((resp) => {
      if (resp == true) {
        this.manageCarsExpanded = false;
        this.cdr.detectChanges();
      }
    }));

    // observe mobile resolution
    this.subscriptions.push(this.mobileResolutionService.ObservableMobileResolution.subscribe((resp) => {
      if (resp != null) {
        this.mobileResolution = resp;
      }
    }));

    this.subscriptions.push(this.mobileResolutionService.ObservableMobileVisiblePanel.subscribe((resp) => {
      if (resp == "car") {
        setTimeout(() => {
          this.sliderScrollbar.update();
          this.sliderScrollbar.scrollTo({ top: 0, duration: 0 });
        }, 300);
      }
      if (resp) {
        this.mobileVisiblePanel = resp;
        if ((resp == "" || resp == "car") && this.mobileResolutionService.getMobileResolution()) {
          this.manageCarsExpanded = false;
        }
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.navigationService.ObservableNextTurn.subscribe((resp) => {
      if (resp && this.navigation && !this.slidersValueChangedIn15seconds) {
        this.batteryLevel = resp.EstimatedBatteryLevel;
        this.cdr.detectChanges();
      }
    }));
  }

  private loadSettings(): void {
    if (this.inputParamsService.AcceptCookies && !this.accountService.getIsAuthorized()) {
      const batteryLevel = localStorage.getItem('batteryLevel');
      if (batteryLevel) {
        this.batteryLevel = parseFloat(batteryLevel);
        this.cdr.detectChanges();
      }

      const batterySafetyLimitRha = localStorage.getItem('batterySafetyLimitRha');
      if (batterySafetyLimitRha) {
        this.batterySafetyLimitRha = parseFloat(batterySafetyLimitRha);
        this.getBatterySafetyLimit();
      }

      const batterySafetyLimitRoute = localStorage.getItem('batterySafetyLimitRoute');
      if (batterySafetyLimitRoute) {
        this.batterySafetyLimitRoute = parseFloat(batterySafetyLimitRoute);
      }

      const minBatteryAtDestination = localStorage.getItem('minBatteryAtDestination');
      if (minBatteryAtDestination) {
        this.minBatteryAtDestination = parseFloat(minBatteryAtDestination);
      }
      const speedLimiter = localStorage.getItem('speedLimiter');
      if (speedLimiter) {
        this.speedLimiter = parseFloat(speedLimiter);
        this.getSpeedLimiter();
      }
      const tirePressure = localStorage.getItem('tirePressure');
      if (tirePressure) {
        this.tirePressure = parseFloat(tirePressure);
        this.getTirePressure();
      }
      const userBehavior = localStorage.getItem('userBehavior');
      if (userBehavior) {
        this.userBehavior = parseFloat(userBehavior);
        this.getUserBehavior();
      }
      const weight = localStorage.getItem('weight');
      if (weight) {
        this.weight = parseFloat(weight);
      }
      this.sliderParamsChanged();
      this.inputParamsService.loadCarSettingsCookies();
      this.cdr.detectChanges;
    }
    if (!this.inputParamsService.AcceptCookies) {
      this.setDefaultSettings();
    }
  }

  private setDefaultSettings(): void {
    this.batteryLevel = 73;
    this.weight = 2;
    this.tirePressure = 2.3;
    this.getTirePressure();
    this.userBehavior = 50;
    this.getUserBehavior();
    this.speedLimiter = 100;
    this.getSpeedLimiter();
    this.batterySafetyLimitRha = 50;
    this.batterySafetyLimitRoute = 15;
    this.getBatterySafetyLimit();
    this.sliderParamsChanged();
    this.cdr.detectChanges;
  }

  // range slider change
  public sliderParamsChanged(): void {
    if (this.navigation) {
      this.slidersValueChangedIn15seconds = true;
      this.navigationService.setSlidersValueChangedIn15seconds(true);
      clearTimeout(this.slidersValueChangedInterval);
      this.slidersValueChangedInterval = setTimeout(() => {
        this.slidersValueChangedIn15seconds = false;
        this.navigationService.setSlidersValueChangedIn15seconds(false);
      }, 15000);
    }

    this.inputParamsService.setSliderRangeInputParams(this.getRangeSliderParams());
    if (this.inputParamsService.AcceptCookies && !this.accountService.getIsAuthorized()) {
      localStorage.setItem('batteryLevel', this.batteryLevel.toString());
      localStorage.setItem('batterySafetyLimitRha', this.batterySafetyLimitRha.toString());
      localStorage.setItem('batterySafetyLimitRoute', this.batterySafetyLimitRoute.toString());
      localStorage.setItem('minBatteryAtDestination', this.minBatteryAtDestination.toString());
      localStorage.setItem('speedLimiter', this.speedLimiter.toString());
      localStorage.setItem('tirePressure', this.tirePressure.toString());
      localStorage.setItem('userBehavior', this.userBehavior.toString());
      localStorage.setItem('weight', this.weight.toString());
    }

    if (this.accountService.getIsAuthorized()) {
      clearTimeout(this.updateCarSettignsInterval);
      const selectedCarArray = this.accountService.user.Ecars.filter((ecar: ECar) => ecar.selected === true);

      this.updateCarSettignsInterval = setTimeout(() => {
        if (selectedCarArray.length > 0) {
          const settings = this.getRangeSliderParams();
          const rangeInputParams = this.inputParamsService.getECarSettings();
          if (rangeInputParams && rangeInputParams.batteryStateOfHealth) {
            settings.batteryStateOfHealth = rangeInputParams.batteryStateOfHealth;
          }
          if (rangeInputParams && rangeInputParams.fastChargeLimit) {
            settings.fastChargeLimit = rangeInputParams.fastChargeLimit;
          }
          const userCarSettings: any = this.accountService.user.getSelectedUserCar().settings;
          settings.tripRecording = userCarSettings.tripRecording != undefined ? userCarSettings.tripRecording : 1;
          this.accountService.editVehicle(selectedCarArray[0].userVehicleID, null, null, null, JSON.stringify(settings), false).subscribe();
        }
      }, 1000);
    }
  }

  public batterySliderChanged(): void {
    if (this.navigation) {
      this.loggerService.onBatteryChangeEvent(this.batteryLevel);
    }
  }

  public weightSliderChanged(): void {
    if (this.navigation) {
      this.loggerService.onWeightChangeEvent();
    }
  }

  // getting object that contains sliders current values
  public getRangeSliderParams(): ECarSettings {
    return {
      batteryLevel: this.batteryLevel, weight: this.weight, tirePressure: this.tirePressure, userBehavior: this.userBehavior, speedLimiter: this.speedLimiter,
      batterySafetyLimitRha: this.batterySafetyLimitRha, batterySafetyLimitRoute: this.batterySafetyLimitRoute, minBatteryAtDestination: this.minBatteryAtDestination
    };
  }

  public getSpeedLimiter(): void {
    if (this.settingsService.getUnit().Speed == Unit.Metric) {
      this.speedLimiterString = this.speedLimiter + " km/h";
    }
    else if (this.settingsService.getUnit().Speed == Unit.Imperial) {
      this.speedLimiterString = Math.round(this.utilsService.kmphToMph(this.speedLimiter)) + " mph";
    }
    this.cdr.detectChanges();
  }

  // user behavior type conversion from the value
  public getUserBehavior(): void {
    if (this.userBehavior < 33) {
      this.userBehaviorString = "eco";
    }
    else if (this.userBehavior < 66) {
      this.userBehaviorString = "normal";
    }
    else {
      this.userBehaviorString = "sport";
    }
    this.cdr.detectChanges();
  }

  // change battery reserved value to return limit at max value 
  public getBatterySafetyLimit(): void {
    if (this.batterySafetyLimitRha != 50) {
      this.batterySafetyRhaString = this.batterySafetyLimitRha.toString() + " %";
    }
    else {
      this.batterySafetyRhaString = this.languageService.languageJSON.SliderSidebar_BatteryReserved_ReturnLimit;
    }
    this.cdr.detectChanges();
  }

  public getTirePressure(): void {
    const pre = (this.tirePressure - 2.3 >= 0) ? "+" : "-";
    if (this.settingsService.getUnit().Pressure == Unit.Metric) {
      if (this.tirePressure - 2.3 == 0) {
        this.tirePressureString = "Std + 0.0 bar";
      }
      else {
        this.tirePressureString = "Std " + pre + " " + Math.abs(Math.round((this.tirePressure - 2.3) * 10) / 10) + " bar";
      }
    }
    else if (this.settingsService.getUnit().Pressure == Unit.Imperial) {
      this.tirePressureString = "Std " + pre + " " + Math.abs(Math.round(this.utilsService.barToPsi(this.tirePressure) - this.utilsService.barToPsi(2.3)) * 10) / 10 + " psi";
    }
    this.cdr.detectChanges();
  }

  // open error dialog modal
  public openCarSelectorDialog(): void {
    if (!this.navigation) {
      if (!this.accountService.getIsAuthorized()) {
        this.stackedModalsService.openModal(CarSelectorDialogComponent,
          { data: {}, autoFocus: false, panelClass: 'dialog-snap-to-fullscreen' });
      }
      else {
        this.manageCarsExpanded = !this.manageCarsExpanded;
        if (this.mobileResolutionService.getMobileResolution()) {
          if (this.manageCarsExpanded) {
            this.mobileResolutionService.setMobileVisiblePanel("car-open");
          }
          else {
            this.mobileResolutionService.setMobileVisiblePanel("car");
          }
        }
        this.cdr.detectChanges();
      }
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];
  }
}
