import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { tap, concatMap, takeUntil } from 'rxjs/operators';
import { HostListener } from '@angular/core';
import { InputParamsService } from 'src/app/services/input-params.service';
import { MatDialog } from '@angular/material/dialog';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitchComponent implements OnInit {

  // input output data
  @Input() onImage: string;
  @Input() offImage: string;
  @Input() value: number;
  @Output() valueChange = new EventEmitter();
  @Output() valueChangeEvent = new EventEmitter();

  @ViewChild('switchOuter', { static: true }) switchControl: ElementRef;

  // recalculating sliders when size changed
  @HostListener('window:resize') onResize() {
    this.setSliderToCurrValue();
  }

  private sliderFill: HTMLElement;
  private sliderImg: HTMLElement;
  public grabImage: string = null;
  private dragged: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(private inputParamsService: InputParamsService, public matDialog: MatDialog, public mobileResolutionService: MobileResolutionService,
    private utilsService: UtilsService) {
  }

  ngOnChanges(): void {
    this.setSliderToCurrValue();
  }

  // subscribe slider move/touch events, setting slider to the default values
  ngOnInit(): void {
    const sDown = fromEvent(this.switchControl.nativeElement, 'mousedown');
    const sMove = fromEvent(document, 'mousemove');
    const sUp = fromEvent(document, 'mouseup');
    const sDrag = sDown.pipe(concatMap(DragEvent => sMove.pipe(takeUntil(sUp))));

    let dragged = false;
    let mddownelement = null;
    this.subscriptions.push(sDown.subscribe((md: MouseEvent) => { mddownelement = this; }));

    this.subscriptions.push(sUp.subscribe((md: MouseEvent) => { if (dragged == false && mddownelement == this) { this.toggle(); } dragged = false; mddownelement = null; }));

    this.subscriptions.push(sDrag.subscribe((md: MouseEvent) => {
      //md.preventDefault();
      dragged = true;
      if (!isNaN(md.pageX)) {
        this.moveSliderCondition(md.pageX);
      }
    }));

    const sTouchStart = fromEvent(this.switchControl.nativeElement, 'touchstart');
    const sTouchMove = fromEvent(this.switchControl.nativeElement, 'touchmove');
    const sTouchEnd = fromEvent(this.switchControl.nativeElement, 'touchend')
    const sTouchDrag = sTouchStart.pipe(concatMap(DragEvent => sTouchMove.pipe(takeUntil(sTouchEnd))));

    this.subscriptions.push(sTouchStart.subscribe((td: TouchEvent) => { mddownelement = this; }));
    this.subscriptions.push(sTouchEnd.subscribe((td: TouchEvent) => { if (dragged == false && mddownelement == this) { this.toggle(); } dragged = false; mddownelement = null; }));

    this.subscriptions.push(sTouchDrag.subscribe((td: TouchEvent) => {
      //td.preventDefault();
      dragged = true;
      if (!isNaN(td.changedTouches[0].pageX)) {
        this.moveSliderCondition(td.changedTouches[0].pageX);
      }
    }));

    this.sliderFill = this.switchControl.nativeElement.querySelector('.switch-fill');
    this.sliderImg = this.switchControl.nativeElement.querySelector('.switch-image');
    this.setSliderToCurrValue();

    this.subscriptions.push(this.mobileResolutionService.ObservableMobileVisiblePanel.subscribe((resp) => {
      if (resp == "slider") {
        if (navigator.userAgent.includes("FBIOS")) {
          this.setSliderToCurrValue();
        }
      }
    }));
  }

  // setting slider to the current value
  private setSliderToCurrValue(): void {
    let sliderWidth = 62;
    let sliderMinPos: number = 16;
    let sliderMaxPos: number = sliderWidth - sliderMinPos;
    let valueInPercent = this.value;
    let newPos: number = (valueInPercent * (sliderMaxPos - sliderMinPos)) + sliderMinPos;
    if (this.sliderFill) {
      this.sliderFill.style.width = (newPos + 16) + "px";
    }
    if (this.sliderImg) {
      this.sliderImg.style.left = (newPos - 16) + "px";
    }
    this.grabImage = this.value == 0 ? this.offImage : this.onImage;
  }

  public toggle(): void {
    this.changeValue((this.value == 0 ? 1 : 0));
  }

  // moving slider to the new value from mouse/touch event
  private moveSliderCondition(pageX: number): void {
    this.moveSlider(pageX);
  }

  private moveSlider(pageX: number): void {
    const buttonWidth = 16;
    // coords
    let newPos: number = pageX - this.switchControl.nativeElement.getBoundingClientRect().left;
    let sliderMinPos: number = buttonWidth;
    let sliderMaxPos: number = this.switchControl.nativeElement.clientWidth - buttonWidth;

    // overpulled
    if (newPos < sliderMinPos) {
      newPos = sliderMinPos;
    }
    if (newPos > sliderMaxPos) {
      newPos = sliderMaxPos;
    }

    const sliderStepWidth: number = sliderMaxPos - sliderMinPos;
    const currStep: number = ((newPos - sliderMinPos) / sliderStepWidth);
    newPos = sliderMinPos + currStep * sliderStepWidth;

    const currValue: number = (newPos - sliderMinPos) / (sliderMaxPos - sliderMinPos);
    this.changeValue(+this.toFixed(currValue, 0));
  }

  // create number with fixed decimals
  private toFixed(num: number, precision: number): string {
    return this.utilsService.toFixed(num, precision);
  }

  // passing slider new value to parent
  private changeValue(value: number): void {
    if (this.value != value) {
      this.value = value;
      this.valueChange.emit(parseFloat(this.toFixed(value, 0)));
      this.valueChangeEvent.emit();
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];
  }
}