import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AudioService } from 'src/app/services/audio.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-navigation-disclaimer',
  templateUrl: './navigation-disclaimer.component.html',
  styleUrls: ['./navigation-disclaimer.component.scss']
})
export class NavigationDisclaimerComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<NavigationDisclaimerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private audioService: AudioService,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
  }

  public accept(): void{
    this.audioService.StartNavigation();
    this.dialogRef.close("start");
  }

  public cancel(): void{
    this.dialogRef.close(null);
  }
}
