import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface ILoggerService {
  info(value: any, ...rest: any[]): void;
  log(value: any, ...rest: any[]): void;
  warn(value: any, ...rest: any[]): void;
  error(value: any, ...rest: any[]): void;
}

@Injectable({
  providedIn: 'root'
})
export class ConsoleLoggerService {

  public info(value: any, ...rest: any[]): void {
    if (environment.consolelog)
      console.info(value, rest);
  }

  public log(value: any, ...rest: any[]): void {
    if (environment.consolelog)
      console.log(value, rest);
  }

  public warn(value: any, ...rest: any[]): void {
    if (environment.consolelog)
      console.warn(value, rest);
  }

  public error(value: any, ...rest: any[]): void {
    if (environment.consolelog)
      console.error(value, rest);
  }
}
