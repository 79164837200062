export const environment = {
  production: true,
  APPLE_CLIENT_ID: 'com.gpstuner.evnaviapp.client',
  APPLE_REDIRECT_URI: 'https://staging.evnavigation.com/oauth/token.php',
  TRACK_CLOSE_TIME_SECS: 10*60,
  TRACK_CLOSE_DISTANCE_METERS: 2000,
  API_URL: 'https://staging-api.evnavigation.com/',
  BACKEND_URL : 'https://staging.evnavigation.com/',
  consolelog: true
};
