import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, Input, NgZone, EventEmitter, Output } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { UtilsService } from 'src/app/services/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { Subscription } from 'rxjs';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { MapSkin, SettingsService, Unit } from 'src/app/services/settings.service';
import { LanguageService } from 'src/app/services/language.service';
import { WebviewService } from 'src/app/services/webview.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { MapService } from 'src/app/services/map.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Computer } from '../../../models/computer';
import { RoutePlanElement, Turn } from 'src/app/models/route';
import { ChargeState } from 'src/app/models/charge-state';
import { StackedModalsService } from 'src/app/services/stacked-modals.service';
import { NavigationSetBatteryDialogComponent } from '../../modals/navigation-set-battery-dialog/navigation-set-battery-dialog.component';
import { InputParamsService } from 'src/app/services/input-params.service';

@Component({
  selector: 'app-navigation-hud',
  templateUrl: './navigation-hud.component.html',
  styleUrls: ['./navigation-hud.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationHudComponent implements OnInit {
  @Input() Desktop: boolean;
  @Output() hudOpenedChangedEvent = new EventEmitter();
  @Output() computersChangedEvent = new EventEmitter();
  public hudBottomOpened: boolean = false;
  public turn: Turn;
  public turnDistance: number;
  private navigation: boolean = true;
  private subscriptions: Subscription[] = [];
  public speedLimit: number = 0;
  public computersArray: Computer[] = [];
  public roadLanesVisible: boolean = false;
  public mapDragged: boolean = false;
  public estimatedBatteryLevel: number = 100;
  public NavigationHudDialogRef;
  public NextWaypoint: boolean = false;
  public NextWaypointDisplayName = "";
  public CannotSkipMoreChargingStation: boolean = false;
  public mobileVisiblePanel: string = "";
  public floatingInfoVisible: boolean = true;
  public batteryOptimizationSettingsRequired: boolean = false;
  public arrivedChargerDetails: RoutePlanElement = null;
  public chargeState: ChargeState = ChargeState.None;
  public ChargeState = ChargeState;
  public chargeTime = "";
  public chargerIconUrl = "assets/icons/dark/turn/charge.svg";
  public arrivedAtMessage = "";
  public arrivedChargerIsEndpoint = false;

  constructor(public navigationService: NavigationService, private cdr: ChangeDetectorRef, private utilsService: UtilsService,
    private MatDialog: MatDialog, public mobileResolutionService: MobileResolutionService, public settingsService: SettingsService,
    private _ngZone: NgZone, public languageService: LanguageService, public webviewService: WebviewService,
    private consoleLoggerService: ConsoleLoggerService, public mapService: MapService, private snackBar: MatSnackBar,
    private stackedModalsService: StackedModalsService, private inputParamsService: InputParamsService) { }

  ngOnInit() {
    this.subscriptions.push(this.navigationService.ObservableShowRoadLane.subscribe((resp) => {
      if (resp != null) {
        this.roadLanesVisible = resp;
      }
    }
    ));

    this.subscriptions.push(this.navigationService.ObservableMapDragIn5Seconds.subscribe((resp) => {
      if (resp != null) {
        this.mapDragged = resp;
        this.cdr.detectChanges();
      }
    }
    ));

    this.subscriptions.push(this.navigationService.ObservableNextTurn.subscribe((resp) => {
      if (resp && this.navigation) {
        this.turn = resp.NextTurnInstruction;
        this.turnDistance = Math.round(resp.NextTurnDistance);
        this.estimatedBatteryLevel = resp.EstimatedBatteryLevel;

        let nextWpChComputer: Computer = null;

        if (resp.DistToNextChargingStation != null && resp.DistToNextWaypoint != null) {
          if (resp.DistToNextWaypoint < resp.DistToNextChargingStation) {
            nextWpChComputer = new Computer("wp", "/assets/icons/" + this.settingsService.getMap().Skin + "/ui/computer_icon_waypoint.svg", resp.DistToNextWaypoint, this.utilsService.timeConverter(resp.TimeToNextWaypoint), resp.NextWaypointBatteryReservedWarning);
            this.NextWaypointDisplayName = resp.NextWaypointDisplayName;
          }
          else {
            nextWpChComputer = new Computer("cs", "/assets/icons/" + this.settingsService.getMap().Skin + "/ui/chargingplan_icon.svg", resp.DistToNextChargingStation, this.utilsService.timeConverter(resp.TimeToNextChargingStation), resp.NextChargingStationBatteryReservedWarning);
          }
        }
        else if (resp.DistToNextWaypoint != null) {
          nextWpChComputer = new Computer("wp", "/assets/icons/" + this.settingsService.getMap().Skin + "/ui/computer_icon_waypoint.svg", resp.DistToNextWaypoint, this.utilsService.timeConverter(resp.TimeToNextWaypoint), resp.NextWaypointBatteryReservedWarning);
          this.NextWaypointDisplayName = resp.NextWaypointDisplayName;
        }
        else if (resp.DistToNextChargingStation != null) {
          nextWpChComputer = new Computer("cs", "/assets/icons/" + this.settingsService.getMap().Skin + "/ui/chargingplan_icon.svg", resp.DistToNextChargingStation, this.utilsService.timeConverter(resp.TimeToNextChargingStation), resp.NextChargingStationBatteryReservedWarning);
        }

        if (nextWpChComputer != null) {
          if (this.computersArray.length < 2) {
            this.computersArray.unshift(nextWpChComputer);
          } else {
            this.computersArray[0].Set(nextWpChComputer);
          }
        } else if (this.computersArray.length == 2) {
          this.computersArray.pop();
        }
        let destinationComputers: Computer = new Computer("t", '/assets/icons/' + this.settingsService.getMap().Skin + '/ui/target_icon.svg', resp.DistToDest, this.utilsService.timeConverter(resp.TimeToDest), resp.DestBatteryReservedWarning);

        if (this.computersArray.length == 0) {
          this.computersArray.push(destinationComputers);
        } else {
          this.computersArray[this.computersArray.length - 1].Set(destinationComputers);
        }

        this.computersChangedEvent.next(this.computersArray.length);

        this.speedLimit = resp.SpeedLimit;
      }
      if (resp && resp.DistToNextWaypoint != null) {
        this.NextWaypointDisplayName = resp.NextWaypointDisplayName;
      }
      this.cdr.detectChanges();
    }));

    this.subscriptions.push(this.navigationService.ObservableCannotSkipMoreChargingStation.subscribe((resp) => {
      if (resp) {
        this.CannotSkipMoreChargingStation = resp;
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.navigationService.ObservableCannotSkipMoreChargingStation.subscribe((resp) => {
      if (resp) {
        this.CannotSkipMoreChargingStation = resp;
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.mobileResolutionService.ObservableMobileVisiblePanel.subscribe((panelName) => {
      if (panelName != undefined) {
        this.mobileVisiblePanel = panelName;
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.navigationService.ObservableFloatingInfoVisible.subscribe((resp) => {
      if (resp != null) {
        this.floatingInfoVisible = resp;
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.navigationService.ObservableArrivedToCharger.subscribe((resp) => {
      if (resp) {
        const waypoints = this.inputParamsService.getWaypointsParams();
        if (waypoints[waypoints.length - 1].csid && resp.ChargingStationId == waypoints[waypoints.length - 1].csid) {
          this.arrivedChargerIsEndpoint = true;
        }
        else {
          this.arrivedChargerIsEndpoint = false;
        }

        this.arrivedChargerDetails = resp;
        this.chargeTime = this.utilsService.getChargeTime(this.arrivedChargerDetails.ChargeTimeHour,
          this.arrivedChargerDetails.ChargeTimeMin);

        this.arrivedAtMessage = this.languageService.languageJSON.NavigationHud_ArrivedAtCharger.replace("$chargername", "<b>" + this.arrivedChargerDetails?.Name + "</b>");
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.navigationService.ObservableChargeStateChanged.subscribe((resp) => {
      if (resp != undefined && resp != null) {
        this.chargeState = resp;
        console.log("charge state", this.chargeState);
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.settingsService.ObservableMap.subscribe((resp) => {
      if (resp) {
        if (resp.Skin == MapSkin.Dark) {
          this.chargerIconUrl = "assets/icons/dark/ui/charge_accent_outline.svg";
        }
        if (resp.Skin == MapSkin.Light) {
          this.chargerIconUrl = "assets/icons/dark/turn/charge.svg";
        }
      }
    }));

    this.batteryOptimizationSettingsRequired = this.webviewService.getBatteryOptimizationSettingsRequired();
  }

  public toggleBottomPanel(): void {
    // when hider is visible
    if (!(document.documentElement.classList.contains("landscape") && this.computersArray.length < 2)) {
      this.hudBottomOpened = !this.hudBottomOpened;
      this.hudOpenedChangedEvent.emit(this.hudBottomOpened);
    }
  }

  public skipWaypoint(): void {
    let skipmessage = "";
    if (this.NextWaypointDisplayName != null && this.NextWaypointDisplayName != undefined) {
      skipmessage = this.languageService.languageJSON.NavigationHud_SkipWaypointWithName_Message.replace(`$waypoint`, `<b>${this.NextWaypointDisplayName}</b>`);
    }
    else {
      skipmessage = this.languageService.languageJSON.NavigationHud_SkipWaypoint_Message;
    }

    this._ngZone.run(() => {
      this.NavigationHudDialogRef = this.MatDialog.open(ConfirmationDialogComponent,
        {
          data: {
            title: this.languageService.languageJSON.NavigationHud_SkipWaypoint, message: skipmessage,
            buttons: [this.languageService.languageJSON.Global_Skip, this.languageService.languageJSON.Global_Cancel]
          }, autoFocus: false
        });

      this.NavigationHudDialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.navigationService.SkipNextWaypoint();
        }
        this.NavigationHudDialogRef = null;
      });
    });
  }

  public skipChargingStation(): void {
    let skipmessage = this.languageService.languageJSON.NavigationHud_SkipChargingStation_Message;

    this._ngZone.run(() => {
      this.NavigationHudDialogRef = this.MatDialog.open(ConfirmationDialogComponent,
        {
          data: {
            title: this.languageService.languageJSON.NavigationHud_SkipChargingStation, message: skipmessage,
            buttons: [this.languageService.languageJSON.Global_Skip, this.languageService.languageJSON.Global_Cancel]
          }, autoFocus: false
        });

      this.NavigationHudDialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.navigationService.SkipNextChargingStation();
        }
        this.NavigationHudDialogRef = null;
      });
    });
  }

  public replanNavigation(): void {
    this.navigationService.SkipNextWaypoint();
  }

  public startCharge(): void {
    this.navigationService.setChargeState(ChargeState.Charging);
  }

  public finishCharge(): void {
    this.navigationService.setChargeState(ChargeState.ChargeFinish);
    this.NavigationHudDialogRef = this.MatDialog.open(NavigationSetBatteryDialogComponent,
      {
        data: {
          chargedBatteryLevel: this.arrivedChargerDetails.BatteryMaxPercent,
          chargingStationId: this.arrivedChargerDetails.ChargingStationId,
          arrivedChargerIsEndpoint: this.arrivedChargerIsEndpoint
        }, autoFocus: false
      });

    this.NavigationHudDialogRef.afterClosed().subscribe((result) => {
      if (this.arrivedChargerIsEndpoint) {
        this.navigationService.ObservableChangeNavigation.next("arrive");
        this.navigationService.setChargeState(ChargeState.None);
      }
      this.NavigationHudDialogRef = null;
    });
  }

  public stopNavigation(): void {
    this._ngZone.run(() => {
      this.NavigationHudDialogRef = this.MatDialog.open(ConfirmationDialogComponent,
        {
          data: {
            title: this.languageService.languageJSON.NavigationHud_StopNavigation, message: this.languageService.languageJSON.NavigationHud_StopNavigation_Message,
            buttons: [this.languageService.languageJSON.Global_Stop, this.languageService.languageJSON.Global_Cancel]
          }, autoFocus: false
        });

      this.NavigationHudDialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.navigationService.ObservableChangeNavigation.next("exit");
        }
        this.NavigationHudDialogRef = null;
      });
    });
  }

  public showMetricMeter(m: number): boolean {
    if (m < 1000 && this.settingsService.getUnit().Distance == Unit.Metric) {
      return true;
    }
    else {
      return false;
    }
  }

  public showMetricKMeter(m: number): boolean {
    if (m >= 1000 && this.settingsService.getUnit().Distance == Unit.Metric) {
      return true;
    }
    else {
      return false;
    }
  }

  public showImperialYard(m: number): boolean {
    if (this.utilsService.mToYard(m) < 1760 && this.settingsService.getUnit().Distance == Unit.Imperial) {
      return true;
    }
    else {
      return false;
    }
  }

  public showImperialMile(m: number): boolean {
    if (this.utilsService.mToYard(m) >= 1760 && this.settingsService.getUnit().Distance == Unit.Imperial) {
      return true;
    }
    else {
      return false;
    }
  }

  //Mobile resolution
  public setMobileVisiblePanel(panelName: string): void {
    this.mobileVisiblePanel = panelName;
    this.mobileResolutionService.setMobileVisiblePanel(panelName);
    this.cdr.detectChanges();
  }

  public floatingInfoOnClick(): void {
    this.webviewService.OpenBatteryOptimizationWindow();
  }

  public openBatteryReservedSnackbar() {
    this.snackBar.open(this.languageService.languageJSON.WarningSoc_popup, "", { duration: 5000, panelClass: 'snackbar-warning' });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.NavigationHudDialogRef) {
      this.NavigationHudDialogRef.close();
    }
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];
  }
}
