import { Component, ViewChild, HostListener, NgZone, ChangeDetectorRef, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { InputParamsService } from 'src/app/services/input-params.service';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { Router, ActivatedRoute, NavigationEnd, ParamMap } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WelcomeDialogComponent } from 'src/app/components/modals/welcome-dialog/welcome-dialog.component';
import { LatLng } from 'leaflet';
import screenfull from 'screenfull';
import { environment } from 'src/environments/environment';
import device from 'current-device';
import { Subscription, timer } from 'rxjs';
import { MapComponent, MapElementType } from 'src/app/components/map/map.component';
import { UtilsService } from 'src/app/services/utils.service';
import { first, map, skip } from 'rxjs/operators';
import { NavigationComponent } from 'src/app/components/navigation/navigation.component';
import { NavigationService } from 'src/app/services/navigation.service';
import { GpsErrorDialogComponent } from 'src/app/components/modals/gps-error-dialog/gps-error-dialog.component';
import { CarSelectorDialogComponent } from 'src/app/components/modals/car-selector-dialog/car-selector-dialog.component';
import { WindTabComponent } from 'src/app/components/wind-tab/wind-tab.component';
import { TabbedInfoDialogComponent } from 'src/app/components/modals/tabbed-info-dialog/tabbed-info-dialog.component';
import { SettingsDialogComponent } from 'src/app/components/modals/settings-dialog/settings-dialog.component';
import { AccountDialogComponent } from 'src/app/components/modals/account-dialog/account-dialog.component';
import { AccountService } from 'src/app/services/account.service';
import { SpeechService, VoiceCommand } from 'src/app/services/speech.service';
import { VoiceCommandConfirmationDialogComponent } from 'src/app/components/modals/voice-command-confirmation-dialog/voice-command-confirmation-dialog.component';
import { WebviewService } from 'src/app/services/webview.service';
import { ConfirmationDialogComponent } from 'src/app/components/modals/confirmation-dialog/confirmation-dialog.component';
import { SettingsService } from 'src/app/services/settings.service';
import { StackedModalsService } from 'src/app/services/stacked-modals.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { LanguageService } from 'src/app/services/language.service';
import { VoiceCommandListenDialogComponent } from 'src/app/components/modals/voice-command-listen-dialog/voice-command-listen-dialog.component';
import { VoiceCommandErrorDialogComponent } from 'src/app/components/modals/voice-command-error-dialog/voice-command-error-dialog.component';
import NoSleep from 'nosleep.js';
import { MapService, ShowOnMapEnum } from 'src/app/services/map.service';
import { HistoryDialogComponent } from '../modals/history-dialog/history-dialog.component';
import { SubscriptionsPromoDialogComponent } from '../modals/subscriptions-promo-dialog/subscriptions-promo-dialog.component';
import { LocationService } from 'src/app/services/location.service';
import { Route, RoutePlanType, WaypointAddress } from '../../models/route';
import { RangeParams, Waypoint } from '../../models/range-params';
import { RecognizedVoiceCommand } from 'src/app/models/recognized-voice-command';
import { Rha } from 'src/app/models/rha';
import { WindElement } from 'src/app/models/wind';
import { DataParserService } from 'src/app/services/data-parser.service';
import { ECarSettings } from 'src/app/models/ecar';
import { ChargerUpdateInfoDialogComponent } from '../modals/charger-update-info-dialog/charger-update-info-dialog.component';

declare function addGtag(): any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent implements OnInit {

  @ViewChild('windTabComponent') windTabComponent: WindTabComponent;
  // disable firefox backspace
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(evt: KeyboardEvent) {
    if (
      evt.keyCode === 8 || evt.which === 8
    ) {
      let doPrevent = true;
      const types = ['text', 'password', 'file', 'search', 'email', 'number', 'date', 'color', 'datetime', 'datetime-local', 'month', 'range', 'search', 'tel', 'time', 'url', 'week'];
      const target = (<HTMLInputElement>evt.target);

      const disabled = target.disabled || (<HTMLInputElement>event.target).readOnly;
      if (!disabled) {
        if (target.isContentEditable) {
          doPrevent = false;
        } else if (target.nodeName === 'INPUT') {
          let type = target.type;
          if (type) {
            type = type.toLowerCase();
          }
          if (types.indexOf(type) > -1) {
            doPrevent = false;
          }
        } else if (target.nodeName === 'TEXTAREA') {
          doPrevent = false;
        }
      }
      if (doPrevent) {
        evt.preventDefault();
        return false;
      }
    }

    return null;
  }
  @HostListener('window:resize') onResize() {
    // guard against resize before view is rendered
    this.mobileResolution = (window.innerWidth <= 1050 || window.innerHeight <= 700) ? true : false;
    if (!this.mobileResolution) {
      this.setMobileVisiblePanel('');
    }
    this.mobileResolutionService.setMobileResolution(this.mobileResolution);
    if (this.mobileResolution && (device.mobile() || device.tablet())) {
      document.body.classList.add("no-animation");

      setTimeout(() => {
        document.body.classList.remove("no-animation");
      }, 2050);
    }

    setTimeout(() => {
      if (this.mobileResolutionService.isPortrait()) {
        document.documentElement.classList.remove("landscape");
        document.documentElement.classList.add("portrait");
      }
      else {
        document.documentElement.classList.remove("portrait");
        document.documentElement.classList.add("landscape");
      }

      if (this.mobileResolution && device.mobile() && device.ios()) {
        window.scrollTo(0, 0);
      }

      //this.map.positionMapToActiveRoute();
    }, 1000);
  }

  private noSleep = new NoSleep();

  public selectedMode: string = "rha";
  public cookiePolicyAgreed: boolean = null;
  public mobileResolution: boolean;
  public mobileVisiblePanel: string = "";
  public mobileVisibleRoutePanel: string = "routepoints";
  public showOnMap: { type: MapElementType | ShowOnMapEnum, data?: any } = null;

  // map
  @ViewChild('map') mapComponent: MapComponent;
  @ViewChild('navigation') navigationComponent: NavigationComponent;

  // service subscriptions
  private subscriptions: Subscription[] = [];
  private httpSub: Subscription = null;

  // map elements
  public loadingMap: string = null;

  // input elements
  public manactText: string = "Act";
  public manactTextX: number = 24;

  private paramsInterval: ReturnType<typeof setTimeout>;
  public compare: boolean = false;
  public iframeRedirect: boolean = false;
  public demo: boolean = false;

  public isFullscreen: boolean = false;
  public navigation: boolean = false;
  public navigationRecalculateRoute: boolean = false;
  private lastWaypoints: Waypoint[] = null;
  public failedPlan: boolean = false;
  public noRoute: boolean = false;
  public windHider: boolean = false;
  public windHiderAnim: boolean = false;
  public feedbackCounter: number = -1;
  public hudOpened: boolean = false;
  public hudComputersLength: number = 1;

  public isAuthorized: boolean = false;

  //public carNewCalc: boolean = true;

  // subscribe to the coordinate change
  constructor(public mobileResolutionService: MobileResolutionService,
    public mapService: MapService, private _ngZone: NgZone, private router: Router, public matDialog: MatDialog,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    public errorDialog: MatDialog,
    public infoDialog: MatDialog,
    public inputParamsService: InputParamsService,
    private SpeechRecognitionService: SpeechService,
    private cdr: ChangeDetectorRef,
    public utilsService: UtilsService,
    private navigationService: NavigationService,
    public webviewService: WebviewService,
    public accountService: AccountService,
    public settingsService: SettingsService,
    private stackedModalsService: StackedModalsService,
    private consoleLoggerService: ConsoleLoggerService,
    public languageService: LanguageService,
    private locationService: LocationService,
    private dataParserService: DataParserService) {

    this.subscriptions.push(this.router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        if (this.activatedRoute?.snapshot?.routeConfig?.path=="compare") {
          this.compare = true;
        }
        if (this.activatedRoute?.snapshot?.routeConfig?.path=="demo") {
          this.demo = true;
          document.body.classList.add("demo");
        }
        if (value.url.length > 1 && value.url.includes("privacy-policy")) {
          this.openPrivacyPolicyDialog();
        }
        if (value.url.length > 1 && value.url.includes("apple_access_token")) {
          const urlParam = this.activatedRoute.snapshot.queryParamMap.get("apple_access_token");
          const provider = "apple";
          this.accountService.initSocialProfile({ success: { token: urlParam } }, provider).subscribe();
          this.accountService.accountInit = true;
          window.history.pushState("", "", '/');
        }
        if (value.url.length > 1 && value.url.includes("carid")) {
          this.iframeRedirect = true;
          let carId = parseInt(this.activatedRoute.snapshot.queryParamMap.get("carid"));
          let lat = parseFloat(this.activatedRoute.snapshot.queryParamMap.get("lat"));
          let lon = parseFloat(this.activatedRoute.snapshot.queryParamMap.get("lon"));

          if (!this.isAuthorized) {
            this.mapService.setSelectedCar(this.mapService.getCarByID(carId), true);
            let carSettings: ECarSettings = {
              "batteryLevel": 100,
              "weight": 2,
              "tirePressure": 2.2,
              "userBehavior": 50,
              "speedLimiter": 100,
              "batterySafetyLimitRha": 50,
              "batterySafetyLimitRoute": 30,
              "minBatteryAtDestination": 10,
              "batteryStateOfHealth": 100,
              "fastChargeLimit": 90,
              "batterySafetyLimit": 50
            };
            this.inputParamsService.setECarSettings(carSettings);
          }

          this.inputParamsService.setSelectedMode("rha");
          const waypoints = [new LatLng(lat, lon)];
          this.inputParamsService.setWaypointParams(waypoints);
          
          this.mapService.ObservableMoveTo.next({ latlng: waypoints[0], zoom: 7 });
          window.history.pushState("", "", '/');
        }
        if (value.url.length > 1 && value.url.includes("apple_error")) {
          this._ngZone.run(() => {
            this.matDialog.open(ConfirmationDialogComponent,
              {
                data: {
                  title: this.languageService.languageJSON.App_LoginFailed,
                  message: this.languageService.languageJSON.App_LoginFailed_Description,
                  buttons: [this.languageService.languageJSON.Global_Ok, null]

                }, autoFocus: false
              });
          });
          window.history.pushState("", "", '/');
        }
        if (value.url.length > 1 && value.url.includes("verify-email")) {
          const urlParam = this.activatedRoute.snapshot.queryParamMap.get("queryURL");
          this.accountService.verifyEmail(urlParam).subscribe({
            next: (resp) => {
              if (resp.success) {
                this._ngZone.run(() => {
                  this.stackedModalsService.openModal(AccountDialogComponent, {
                    data: { process: "verifySuccess" },
                    autoFocus: false,
                    panelClass: 'dialog-snap-to-fullscreen'
                  });
                  this.accountService.getUserVehicles().subscribe((resp) => {
                    if (resp && resp.success && resp.success.length == 0) {
                      this.stackedModalsService.openModal(CarSelectorDialogComponent,
                        {
                          data: { firstUserCar: true },
                          autoFocus: false,
                          panelClass: 'dialog-snap-to-fullscreen'
                        });
                    }
                  });
                  this.accountService.initUserData(true, true, true, false).subscribe();
                });
              }
              else {
                this.stackedModalsService.openModal(AccountDialogComponent, {
                  data: { process: "verifyFailed" },
                  autoFocus: false,
                  panelClass: 'dialog-snap-to-fullscreen'
                });
              }
            },
            error: () => {
              this.stackedModalsService.openModal(AccountDialogComponent, {
                data: { process: "verifyFailed" },
                autoFocus: false,
                panelClass: 'dialog-snap-to-fullscreen'
              })
            }
          });
        }
      }
    }));

    if (localStorage.getItem("cookiesAccepted") == "true" || this.webviewService.IsGPSTWebview()) {
      this.cookiePolicyAgreed = true;
    }

    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    }
    addGtag();
    const manactText = localStorage.getItem("manactText");
    if (manactText) {
      this.manactText = manactText;
      if (this.manactText == "Act") {
        this.manactTextX = 27;
      }
    }
    const feedbackCounter = localStorage.getItem("feedbackCounter");
    if (feedbackCounter) {
      this.feedbackCounter = parseInt(feedbackCounter);
    }
    else {
      this.feedbackCounter = 0;
    }

    if (this.inputParamsService.AcceptCookies) {
      localStorage.setItem("skippedChargingStation", "null");
    }
    // subscribe variable changes

    this.mobileResolution = (window.innerWidth <= 1050 || window.innerHeight <= 700) ? true : false;
    this.mobileResolutionService.setMobileResolution(this.mobileResolution);
    this.subscriptions.push(this.mobileResolutionService.ObservableMobileVisiblePanel.subscribe((panelName) => {
      if (panelName != undefined) {
        this.mobileVisiblePanel = panelName;
      }
    }));
    this.subscriptions.push(this.inputParamsService.ObservableSelectedMode.subscribe((resp) => {
      this.selectedMode = resp;
    }));

    this.subscriptions.push(this.settingsService.ObservableMap.pipe(skip(1)).subscribe((resp) => {
      if (resp) {
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.mapService.ObservableOpenModal.subscribe((resp) => {
      if (resp == "first-open" && !this.iframeRedirect && !document.URL.includes("carid")) {
        localStorage.setItem("chargerUpdateInfo", "true");
        this.openWelcomeScreenDialog();
      }
      if (resp == "car-selector") {
        this.stackedModalsService.openModal(CarSelectorDialogComponent, {
          data: {},
          autoFocus: false,
          panelClass: ['car-selector-outer', 'dialog-snap-to-fullscreen']
        });
      }
    }));

    if (!localStorage.getItem("chargerUpdateInfo") && !this.iframeRedirect && !document.URL.includes("carid")) {
      this.openChargerUpdateInfoDialog();
    }
  }

  // initalizing, subscribe to click event on the map
  ngOnInit(): void {
    // pre load audio files
    if (this.webviewService.IsGPSTWebview()) {
      const voiceLibs: string[] = ["de-DE_Marlene", "en-US_Kendra", "es-ES_Conchita", "fr-FR_Celine", "it-IT_Carla"]

      voiceLibs.forEach((voiceLib) => {
        const soundFileLib = `assets/audio/turn/${voiceLib}/Commands/`;
        this.utilsService.turnList.forEach((turn) => {
          if (turn.sound) {
            this.http.get(soundFileLib + turn.sound, { responseType: "arraybuffer" }).subscribe();
          }
        });
      });
    }

    this.subscriptions.push(this.mobileResolutionService.ObservableMobileResolution.subscribe((res) => { this.mobileResolution = res }));
    this.subscriptions.push(this.mobileResolutionService.ObservableMobileVisiblePanel.subscribe((panelName) => {
      if (panelName != undefined) {
        this.mobileVisiblePanel = panelName;
        this.cdr.detectChanges();
      }
    }));

    document.getElementById("loading-screen").remove();
  }

  ngAfterViewInit(): void {
    this.mapComponent.setChargingStations();
    this.mapComponent.canvasLayer.needRedraw();

    if (this.webviewService.IsGPSTWebview()) {
      this.webviewService.InitGoToCurrentLocationFunction(() => { this.setStartCoordsToYourGeolocation() });
    } else {
      this.setStartCoordsToYourGeolocation();
    }

    this.subscriptions.push(this.inputParamsService.ObservableRangeInputParams.subscribe((RangeParams: RangeParams) => {
      if (RangeParams != undefined) {
        clearTimeout(this.paramsInterval);
        this.paramsInterval = setTimeout(() => {
          this.getData(this.inputParamsService.getRangeParams());
        }, 400);
      }
    }));

    this.subscriptions.push(this.mapService.ObservableChargingStations.subscribe((resp) => {
      if (resp != undefined) {
        clearTimeout(this.paramsInterval);
        this.paramsInterval = setTimeout(() => {
          this.getData(this.inputParamsService.getRangeParams());
        }, 400);
      }
    }));

    this.subscriptions.push(this.mapService.ObservableSelectedCar.subscribe((resp) => {
      if (resp != undefined) {
        clearTimeout(this.paramsInterval);
        this.paramsInterval = setTimeout(() => {
          this.getData(this.inputParamsService.getRangeParams());
        }, 400);
      }
    }));

    if (screenfull && screenfull.isEnabled) {
      screenfull.on('change', (evt) => {
        this.isFullscreen = !this.isFullscreen;
        this.cdr.detectChanges();
      });
    }
    if (device.mobile()) {
      if (navigator.userAgent.includes('FBIOS')) {
        setTimeout(() => {
          this.mobileResolution = (window.innerWidth <= 1050 || window.innerHeight <= 700) ? true : false;
          this.mobileResolutionService.setMobileResolution(this.mobileResolution);

          if (this.mobileResolutionService.isPortrait()) {
            document.documentElement.classList.remove("landscape");
            document.documentElement.classList.add("portrait");
          }
          else {
            document.documentElement.classList.remove("portrait");
            document.documentElement.classList.add("landscape");
          }
        }, 500);
      }
    }

    let floatingInfoTimerRef = null;

    this.subscriptions.push(this.navigationService.ObservableChangeNavigation.subscribe((resp) => {
      this.consoleLoggerService.log(resp);
      if (resp != undefined && resp == "start" && this.navigation == false) {
        if (this.lastWaypoints == null) {
          this.lastWaypoints = this.inputParamsService.getWaypointsParams();
        }
        this.navigationRecalculateRoute = true;
        this.navigation = true;
        this.mapService.setNavigation(true);
        this.mapService.ObservableActiveInfoSidebarRouteElement.next({ type: 'clear' });
        this.cdr.detectChanges();
        if (device.mobile() && !this.webviewService.IsGPSTWebview && !device.ios()) {
          this.noSleep.enable();
        }
        document.documentElement.classList.add("navigation");
      }
      if (resp != undefined && resp == "exit") {
        if (this.webviewService.IsGPSTWebview()) {
          this.webviewService.StopNavigation()
        }
        this.navigationService.ObservableCannotSkipMoreChargingStation.next(false);
        if (this.inputParamsService.AcceptCookies) {
          localStorage.setItem("skippedChargingStation", "null");
        }
        this.navigationRecalculateRoute = false;
        this.navigation = false;
        this.mapService.setNavigation(false);
        this.cdr.detectChanges();
        if (device.mobile() && !this.webviewService.IsGPSTWebview && !device.ios()) {
          this.noSleep.disable();
        }
        //this.inputParamsService.setWaypointParams(this.lastWaypoints);
        this.lastWaypoints = null;
        this.getData(this.inputParamsService.getRangeParams());
        document.documentElement.classList.remove("navigation");
        if (floatingInfoTimerRef) { floatingInfoTimerRef.unsubscribe(); }
      }
      if (resp != undefined && resp == "arrive") {
        if (this.webviewService.IsGPSTWebview()) {
          this.webviewService.StopNavigation()
        }
        this.navigationService.ObservableCannotSkipMoreChargingStation.next(false);
        if (this.inputParamsService.AcceptCookies) {
          localStorage.setItem("skippedChargingStation", "null");
        }
        this.inputParamsService.setSelectedMode("rha");
        this.navigation = false;
        this.mapService.setNavigation(false);
        this.inputParamsService.setWaypointParams([this.inputParamsService.getWaypointsParams()[this.inputParamsService.getWaypointsParams().length - 1]]);
        this.cdr.detectChanges();
        if (device.mobile() && !this.webviewService.IsGPSTWebview && !device.ios()) {
          this.noSleep.disable();
        }
        this.lastWaypoints = null;
        this.getData(this.inputParamsService.getRangeParams());
        document.documentElement.classList.remove("navigation");
        if (floatingInfoTimerRef) { floatingInfoTimerRef.unsubscribe(); }
      }
    }));

    this.subscriptions.push(this.SpeechRecognitionService.ObservableRecognizedVoiceCommand.subscribe((command: RecognizedVoiceCommand) => {
      if (command != null) {
        switch (command.CommadType) {
          case VoiceCommand.SetBattery:
            this._ngZone.run(() => {
              this.matDialog.open(VoiceCommandConfirmationDialogComponent,
                { data: { commandType: command.CommadType, batteryValue: command.Value }, autoFocus: false });

            });
            break;
          case VoiceCommand.SkipWP:
            if (this.selectedMode != "rha" && this.inputParamsService.getWaypointsParams().length > 2) {
              this._ngZone.run(() => {
                this.matDialog.open(VoiceCommandConfirmationDialogComponent,
                  { data: { commandType: command.CommadType }, autoFocus: false });
              });
            }
            else {
              this._ngZone.run(() => {
                this.matDialog.open(VoiceCommandErrorDialogComponent,
                  { data: { errorMsg: "Function is not available." } });
                this.SpeechRecognitionService.PlayErrorNotAvailable();
              });
            }
            break;
          case VoiceCommand.SkipCS:
            if (this.navigation) {
              this._ngZone.run(() => {
                this.matDialog.open(VoiceCommandConfirmationDialogComponent,
                  { data: { commandType: command.CommadType }, autoFocus: false });
              });
            }
            else {
              this._ngZone.run(() => {
                this.matDialog.open(VoiceCommandErrorDialogComponent,
                  { data: { errorMsg: "This function is only available during navigation." } });
                this.SpeechRecognitionService.PlayErrorAvailableDuringNavigation();
              });
            }
            break;
          case VoiceCommand.StopNavigation:
            if (this.navigation) {
              this._ngZone.run(() => {
                this.matDialog.open(VoiceCommandConfirmationDialogComponent,
                  { data: { commandType: command.CommadType }, autoFocus: false });
              });
            }
            else {
              this._ngZone.run(() => {
                this.matDialog.open(VoiceCommandErrorDialogComponent,
                  { data: { errorMsg: "This function is only available during navigation." } });
                this.SpeechRecognitionService.PlayErrorAvailableDuringNavigation();
              });
            }
            break;
          case VoiceCommand.SetWeight:
            this._ngZone.run(() => {
              this.matDialog.open(VoiceCommandConfirmationDialogComponent,
                { data: { commandType: command.CommadType, weightValue: command.Value }, autoFocus: false });
            });
            break;
          case VoiceCommand.NavigateToHome:
            let homeAddress = this.settingsService.getHomeAddress();
            if (this.isAuthorized && homeAddress?.Description)
              this._ngZone.run(() => {
                this.matDialog.open(VoiceCommandConfirmationDialogComponent,
                  { data: { commandType: command.CommadType, homeAddress: homeAddress }, autoFocus: false });
              });
            else {
              this._ngZone.run(() => {
                this.matDialog.open(VoiceCommandErrorDialogComponent,
                  { data: { errorMsg: "Function is not available." } });
                this.SpeechRecognitionService.PlayErrorNotAvailable();
              });
            }
            break;
          case VoiceCommand.NavigateToWork:
            let workAddress = this.settingsService.getWorkAddress();
            if (this.isAuthorized && workAddress?.Description) {
              this._ngZone.run(() => {
                this.matDialog.open(VoiceCommandConfirmationDialogComponent,
                  { data: { commandType: command.CommadType, workAddress: workAddress }, autoFocus: false });
              });
            }
            else {
              this._ngZone.run(() => {
                this.matDialog.open(VoiceCommandErrorDialogComponent,
                  { data: { errorMsg: "Function is not available." } });
                this.SpeechRecognitionService.PlayErrorNotAvailable();
              });
            }
            break;
          case VoiceCommand.ReadEta:
            if (this.navigation) {
              let eta;
              this.navigationService.ObservableNextTurn.pipe(first()).subscribe((resp) => {
                let date = new Date();
                let dateInMinute = date.getHours() * 60 + date.getMinutes();
                eta = dateInMinute + resp.TimeToDest;
                if (eta > 60 * 24) {
                  eta -= 60 * 24;
                }
              });
              this._ngZone.run(() => {
                this.matDialog.open(VoiceCommandConfirmationDialogComponent,
                  { data: { commandType: command.CommadType, Eta: eta }, autoFocus: false });
              });
            }
            else {
              this._ngZone.run(() => {
                this.matDialog.open(VoiceCommandErrorDialogComponent,
                  { data: { errorMsg: "This function is only available during navigation." } });
                this.SpeechRecognitionService.PlayErrorAvailableDuringNavigation();
              });
            }
            break;
          case VoiceCommand.ReadEte:
            if (this.navigation) {
              let ete;
              this.navigationService.ObservableNextTurn.pipe(first()).subscribe((resp) => {
                ete = resp.TimeToDest;
              });
              this._ngZone.run(() => {
                this.matDialog.open(VoiceCommandConfirmationDialogComponent,
                  { data: { commandType: command.CommadType, Ete: ete }, autoFocus: false });
              });
            }
            else {
              this._ngZone.run(() => {
                this.matDialog.open(VoiceCommandErrorDialogComponent,
                  { data: { errorMsg: "This function is only available during navigation." } });
                this.SpeechRecognitionService.PlayErrorAvailableDuringNavigation();
              });
            }
            break;
          case VoiceCommand.ReadRemainingDist:
            if (this.navigation) {
              let remainingDist;
              this.navigationService.ObservableNextTurn.pipe(first()).subscribe((resp) => {
                remainingDist = resp.DistToDest;
              });
              this._ngZone.run(() => {
                this.matDialog.open(VoiceCommandConfirmationDialogComponent,
                  { data: { commandType: command.CommadType, RemainingDist: remainingDist }, autoFocus: false });
              });
            }
            else {
              this._ngZone.run(() => {
                this.matDialog.open(VoiceCommandErrorDialogComponent,
                  { data: { errorMsg: "This function is only available during navigation." } });
                this.SpeechRecognitionService.PlayErrorAvailableDuringNavigation();
              });
            }
            break;
        }
      }
    }));

    this.subscriptions.push(this.SpeechRecognitionService.ObservableOpenVoiceCommandListen.subscribe((resp) => {
      if (resp) {
        this._ngZone.run(() => {
          this.matDialog.open(VoiceCommandListenDialogComponent, { disableClose: true })
        });
      }
    }));

    this.subscriptions.push(this.inputParamsService.ObservableSameCoordsError.subscribe((resp) => {
      if (resp) {
        this._ngZone.run(() => this.openErrorDialog(this.languageService.languageJSON.App_ErrorDialog_SameStartEndLocation, "/assets/icons/" + this.settingsService.getMap().Skin + "/error/popup_icon_routeplanningfailed.svg"));
      }
    }));

    this.subscriptions.push(this.inputParamsService.ObservableGpsErrorDialog.subscribe((resp) => {
      if (resp) {
        this._ngZone.run(() => this.openGpsErrorDialog());
      }
    }));

    this.subscriptions.push(this.inputParamsService.ObservableReverseGeocodingLoader.subscribe((resp) => {
      if (resp) {
        this.setLoadingMap("circle");
      }
    }));

    this.subscriptions.push(this.inputParamsService.ObservableNoRoute.subscribe((resp) => {
      if (resp != undefined && resp != null) {
        this.noRoute = resp;
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.accountService.ObservableIsAuthorized.subscribe((resp) => {
      if (resp != null && resp != undefined) {
        this.isAuthorized = resp;
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.accountService.ObservableOpenEditProfileDialog.subscribe((resp) => {
      if (resp != null && resp != undefined) {
        this.stackedModalsService.openModal(AccountDialogComponent, {
          data: { process: "profileEdit" },
          autoFocus: false,
          panelClass: 'dialog-snap-to-fullscreen'
        });
      }
    }));

    this.subscriptions.push(this.mapService.ObservableShowOnMap.subscribe((resp) => {
      if (resp != null) {
        if (resp.type && resp.type == ShowOnMapEnum.BackSettings) {
          this.showOnMap = null;
          this.cdr.detectChanges();
          if (resp.data) {
            this.openSettings(resp.data);
          }
          else {
            this.openSettings();
          }
        }
        if (resp.type && resp.type == ShowOnMapEnum.BackHistory) {
          this.showOnMap = null;
          this.cdr.detectChanges();
          this.openHistory(resp.data);
        }
        else if (resp.data && resp.type == MapElementType.Address ||
          resp.type && (resp.type == MapElementType.FavoriteChargingStation || resp.type == MapElementType.DisabledChargingStation) ||
          resp.data && resp.type == MapElementType.RouteHistory) {
          this.showOnMap = resp;
          this.cdr.detectChanges();
        }
      }
    }));

    if (!this.webviewService.IsGPSTWebview()) {
      this.accountService.initGoogleLogin();
    }
  }

  public get MapElementType(): typeof MapElementType {
    return MapElementType;
  }

  public getData(RangeParams): void {
    this.resolveOngoingHttpCall();
    if (RangeParams.Waypoints.length >= 2) {
      this.getRoute(RangeParams);
    }
    else {
      if (this.selectedMode == "rha") {
        this.getPolys(RangeParams);
      }
      else if (!this.navigation) {
        this.mapComponent.drawOnePointRouteToMap();
      }
    }
  }

  // place cursor http call for rha data
  private getPolys(rangeParams: RangeParams): void {
    let polyParams: any = {};
    polyParams.Car = { ...(this.mapService.getSelectedCar()) };
    if (polyParams.Car && polyParams.Car.ChargerTypes && polyParams.Car.ChargerTypes.length == 0) {
      this._ngZone.run(() => this.openErrorDialog(this.languageService.languageJSON.App_ErrorDialog_SelectChargingStation, "/assets/icons/" + this.settingsService.getMap().Skin + "/error/popup_icon_select_charging_station.svg"));
    }
    else if (polyParams.Car.Name != null) {
      polyParams.Params = { ...rangeParams };
      polyParams.Params.WindMan = 0;
      if (this.inputParamsService.getSelectedMode() == "route") {
        polyParams.Params.RangeInputParams.batterySafetyLimit = 50;
      }

      this.cdr.detectChanges();

      if (this.compare) {
        polyParams.RHAHeightCompare = 1;
      }

      this.setLoadingMap("circle");

      let rhaUrl = environment.API_URL + "api/rha";


      polyParams.Params.RangeInputParams.batterySafetyLimit = polyParams.Params.RangeInputParams.batterySafetyLimitRha;
      if (polyParams.Params.RangeInputParams.batteryStateOfHealth) {
        polyParams.Car.Range = polyParams.Car.Range * polyParams.Params.RangeInputParams.batteryStateOfHealth / 100;
      }

      /** remove car name from polyparams */
      delete polyParams.Car.Name;
      delete polyParams.Car.Subtype;
      delete polyParams.Car.Type;

      if (this.accountService.getIsAuthorized()) {
        polyParams.UserId = this.accountService.getUserProfile().Profile.Id;
      }

      const VqrzrxFGyHNUVIRU = this.utilsService.VqrzrxFGyHNUVIRU(polyParams);
      const headers = new HttpHeaders({
        'Vqrzrxfgyhnuviru': VqrzrxFGyHNUVIRU.toString()
      });

      this.httpSub = this.http.post(rhaUrl, window.btoa(JSON.stringify(polyParams)), { headers: headers })
        .subscribe({
          next: (resp: any) => {
            if (this.showOnMap) {
              return;
            }
            /** Country not supported error */
            if (resp?.error == "country_not_supported") {
              this.setLoadingMap(null);
              this._ngZone.run(() => this.openErrorDialog(this.languageService.languageJSON.App_ErrorDialog_CountryNotSupported, "/assets/icons/" + this.settingsService.getMap().Skin + "/error/popup_icon_nomap.svg"));
              if (this.webviewService.IsGPSTWebview()) {
                this.webviewService.RhaPolygonUpdate("");
              }
            }
            /** Closest route too far error */
            else if (resp?.error == "closest_route_too_far") {
              this.setLoadingMap(null);
              this._ngZone.run(() => this.openErrorDialog(this.languageService.languageJSON.App_ErrorDialog_ClosestRouteTooFar, "/assets/icons/" + this.settingsService.getMap().Skin + "/error/popup_icon_nomap.svg"));
              this.mapComponent.polyLayer.clearLayers();
              this.mapComponent.markerLayer.clearLayers();
              this.mapComponent.setMarker(new LatLng(rangeParams.Waypoints[0].lat, rangeParams.Waypoints[0].lng), 0);
              this.mapComponent.clearWind();
              if (this.webviewService.IsGPSTWebview()) {
                this.webviewService.RhaPolygonUpdate("");
              }
            }
            /** Invalid server response error */
            else if (resp == null || resp.length == 0 || resp.range1.length == 0 && resp.range2.length == 0) {
              this.setLoadingMap(null);
              this._ngZone.run(() => this.openErrorDialog(this.languageService.languageJSON.App_ErrorDialog_CannotCalculateRange, "/assets/icons/" + this.settingsService.getMap().Skin + "/error/popup_icon_nomap.svg"));
              this.mapComponent.polyLayer.clearLayers();
              this.mapComponent.markerLayer.clearLayers();
              this.mapComponent.setMarker(new LatLng(rangeParams.Waypoints[0].lat, rangeParams.Waypoints[0].lng), 0);
              this.mapComponent.clearWind();
              if (this.webviewService.IsGPSTWebview()) {
                this.webviewService.RhaPolygonUpdate("");
              }
            }
            /** Successful calculation */
            else {
              const rhaData: Rha = this.dataParserService.parseRhaData(resp);

              this.setLoadingMap(null);
              /** Show feedback form after 30 successful calcualtion */
              if (this.feedbackCounter != -1 && this.feedbackCounter < 30) {
                this.feedbackCounter++;
                localStorage.setItem("feedbackCounter", this.feedbackCounter.toString());
                if (this.feedbackCounter == 30) {
                  timer(10000).pipe(first()).subscribe(() => {
                    this._ngZone.run(() => { this.openFeedbackDialog(); });
                  });
                }
              }
              if (this.inputParamsService.AcceptCookies) {
                if (polyParams?.Params?.Waypoints[0]?.csid){
                  delete polyParams.Params.Waypoints[0].csid;
                }
                localStorage.setItem("waypoints", JSON.stringify(polyParams.Params.Waypoints));
              }
              this.mapComponent.setMarker(new LatLng(rangeParams.Waypoints[0].lat, rangeParams.Waypoints[0].lng), 0);

              this.mapComponent.updatePolys(rhaData, this.compare);
              this.mapComponent.initWind(rhaData.Wind);
              this.setWindComponent(rhaData.Wind, rhaData.Temperature, rhaData.WeatherCode);
              if (this.webviewService.IsGPSTWebview()) {
                this.webviewService.RhaPolygonUpdate(JSON.stringify(resp));
              }
            }
            this.cdr.detectChanges();
          },
          error: () => {
            this.setLoadingMap(null);
            this._ngZone.run(() => this.openErrorDialog(this.languageService.languageJSON.App_ErrorDialog_CannotCalculateRange, "/assets/icons/" + this.settingsService.getMap().Skin + "/error/popup_icon_nomap.svg"));
            if (this.webviewService.IsGPSTWebview()) {
              this.webviewService.RhaPolygonUpdate("");
            }
            this.cdr.detectChanges();
          }
        });
    }
  }

  // plan route http call for route data
  private getRoute(rangeParams: RangeParams): void {
    this.cdr.detectChanges();
    let distanceBetweenCoordinates = 0;
    for (let i = 0; i < rangeParams.Waypoints.length - 1; i++) {
      distanceBetweenCoordinates += this.utilsService.distanceBetweenCoordinates([rangeParams.Waypoints[i].lat, rangeParams.Waypoints[i].lng],
        [rangeParams.Waypoints[i + 1].lat, rangeParams.Waypoints[i + 1].lng]);
    }
    if (distanceBetweenCoordinates / 1000 > 4500) {
      this._ngZone.run(() => this.openErrorDialog(this.languageService.languageJSON.App_ErrorDialog_RouteLimit, "/assets/icons/" + this.settingsService.getMap().Skin + "/error/popup_icon_toofar.svg"));
      this.failedPlan = true;
      this.inputParamsService.setFailedPlan({ failedPlan: true, maxReachedWaypoint: 0 });
      this.inputParamsService.setOpenGoogleUrl(false);
      this.navigationRecalculateRoute = false;
      this.setLoadingMap(null);
      if (this.inputParamsService.getSelectedMode() == "rha") {
        this.inputParamsService.setWaypointParams([this.inputParamsService.getWaypointsParams()[0]]);
      }
      if (this.mapComponent) {
        this.mapComponent.drawFailedRouteToMap(0);
      }
    }
    else {
      let polyParams: any = {};
      polyParams.Car = { ...this.mapService.getSelectedCar() };
      if (polyParams.Car.ChargerTypes && polyParams.Car.ChargerTypes.length == 0) {
        this._ngZone.run(() => this.openErrorDialog(this.languageService.languageJSON.App_ErrorDialog_SelectChargingStation, "/assets/icons/" + this.settingsService.getMap().Skin + "/error/popup_icon_select_charging_station.svg"));
      }
      else {
        if (polyParams.Car.Name != null) {
          polyParams.Params = { ...rangeParams };
          polyParams.Params.WindMan = 0;

          if (this.inputParamsService.AcceptCookies) {
            polyParams.Params.DisabledChargingStation = [];

            const disabledChargingStation = localStorage.getItem("skippedChargingStation");
            if (disabledChargingStation != "null" && disabledChargingStation != "undefined") {
              polyParams.Params.DisabledChargingStation = JSON.parse(disabledChargingStation);
            }

            polyParams.Params.DisabledChargingStation = polyParams.Params.DisabledChargingStation.concat(this.settingsService.getDisabledChargingStationIds());
            polyParams.Params.PreferedChargingStation = this.settingsService.getFavoriteChargingStationIds();
            polyParams.Params.DisabledOperator = this.settingsService.getDisabledChargingOperatorIds().map((el) => { return el.toString() });
            polyParams.Params.PreferedOperator = this.settingsService.getFavoriteChargingOperatorIds().map((el) => { return el.toString() });
          }
          if (this.inputParamsService.getSelectedMode() == "rha") {
            polyParams.Params.RangeInputParams.batterySafetyLimit = 15;
          }
          if (this.inputParamsService.getBearing()) {
            polyParams.Params.Bearing = this.inputParamsService.getBearing();
            this.inputParamsService.setBearing(null);
          }

          // routing options
          let routingsettings = this.settingsService.getRouting();
          polyParams.Params.RouteParams = {
            "Tollroads": routingsettings.TollRoads == true ? 1 : 0,
            "Ferries": routingsettings.Ferries == true ? 1 : 0,
            "Highways": routingsettings.Highways == true ? 1 : 0
          };

          // user not selected a charger type
          if (polyParams.Car.ChargerTypes && polyParams.Car.ChargerTypes.length == 0 && !this.navigation) {
            this.mapComponent.polyLayer.clearLayers();
            this.mapComponent.markerLayer.clearLayers();
            this.mapComponent.clearWind();
          }
          else {
            this.setLoadingMap("line");
            if (!this.navigation) {
              this.inputParamsService.setFailedPlan({ failedPlan: false, maxReachedWaypoint: 0 });
              this.mapComponent.drawRoutePlanningAnimationToMap(polyParams.Params.Waypoints);
            }
            else if (!this.demo) {
              polyParams.Params.Waypoints[0].lat = this.locationService.getLastPosition().Latitude;
              polyParams.Params.Waypoints[0].lng = this.locationService.getLastPosition().Longitude;
            }

            /*if (!this.carNewCalc) {
              polyParams.Car.CarWeight = 0;
              polyParams.Car.DragCoefficient = 0;
              polyParams.Car.DragCross = 0;
            }*/

            let routeUrl = environment.API_URL + "api/routing";

            polyParams.Params.RangeInputParams.batterySafetyLimit = polyParams.Params.RangeInputParams.batterySafetyLimitRoute;
            if (polyParams.Params.RangeInputParams.batteryStateOfHealth) {
              polyParams.Car.Range = polyParams.Car.Range * polyParams.Params.RangeInputParams.batteryStateOfHealth / 100;
            }

            /** remove car name from polyparams */
            delete polyParams.Car.Name;
            delete polyParams.Car.Subtype;
            delete polyParams.Car.Type;

            if (this.accountService.getIsAuthorized()) {
              polyParams.UserId = this.accountService.getUserProfile().Profile.Id;
            }
            
            polyParams.Params.PKG = 0;

            let VqrzrxFGyHNUVIRU = this.utilsService.VqrzrxFGyHNUVIRU(polyParams);
            let headers = new HttpHeaders({
              'Vqrzrxfgyhnuviru': VqrzrxFGyHNUVIRU.toString()
            });

            this.consoleLoggerService.log(polyParams);

            this.httpSub = this.http.post(routeUrl, window.btoa(JSON.stringify(polyParams)), { headers })
              .subscribe({
                next: (resp: any) => {
                  if (this.showOnMap) {
                    return;
                  }
                  /** Country not supported error */
                  if (resp?.error == "country_not_supported") {
                    this.setLoadingMap(null);
                    this.failedPlan = true;
                    this.inputParamsService.setFailedPlan({ failedPlan: true, maxReachedWaypoint: 0 });
                    this.inputParamsService.setOpenGoogleUrl(false);
                    this._ngZone.run(() => this.openErrorDialog(this.languageService.languageJSON.App_ErrorDialog_CountryNotSupported, "/assets/icons/" + this.settingsService.getMap().Skin + "/error/popup_icon_nomap.svg"));
                    this.navigationRecalculateRoute = false;
                    if (this.inputParamsService.getSelectedMode() == "rha") {
                      this.inputParamsService.setWaypointParams([this.inputParamsService.getWaypointsParams()[0]]);
                    }
                    if (this.mapComponent) {
                      this.mapComponent.drawFailedRouteToMap(0);
                    }
                    if (this.webviewService.IsGPSTWebview()) {
                      this.webviewService.RouteUpdate("");
                    }
                  }
                  /** Cannot calculate route error */
                  else if (resp?.error?.message == "can_not_calculate_route") {
                    if (this.navigation) {
                      if (this.navigationService.skipChargingStation) {
                        this._ngZone.run(() => {
                          this.openSkipChargingStationFailedDialog();
                        });
                        localStorage.setItem("skippedChargingStation", JSON.stringify(null));
                        this.navigationService.ObservableCannotSkipMoreChargingStation.next(true);
                      }
                      else {
                        this.navigationService.ObservableChangeNavigation.next("exit");
                      }
                    }
                    else {
                      this.setLoadingMap(null);
                      this.failedPlan = true;
                      const maxReachedWaypoint = resp.error.maxReachedWaypoint != undefined ? resp.error.maxReachedWaypoint : 0;
                      this.inputParamsService.setFailedPlan({ failedPlan: true, maxReachedWaypoint: maxReachedWaypoint });
                      this.inputParamsService.setOpenGoogleUrl(false);
                      this._ngZone.run(() => this.openErrorDialog(this.languageService.languageJSON.App_ErrorDialog_CannotCalculateRoute, "/assets/icons/" + this.settingsService.getMap().Skin + "/error/popup_icon_nomap.svg"));
                      this.navigationRecalculateRoute = false;

                      if (this.inputParamsService.getSearchedRangeOrRoute() == false) {
                        for (let i = 0; i < resp.waypointsInfo.length; i++) {
                          (resp.waypointsInfo[i] as WaypointAddress).Location = polyParams.Params.Waypoints[i];
                        }
                        if (resp.waypointsInfo.length > 0) {
                          this.inputParamsService.setReverseGeocodedLocations(resp.waypointsInfo);
                        }
                      }
                      if (this.inputParamsService.getSelectedMode() == "rha") {
                        this.inputParamsService.setWaypointParams([this.inputParamsService.getWaypointsParams()[0]]);
                      }
                      if (this.mapComponent) {
                        this.mapComponent.drawFailedRouteToMap(resp.error.maxReachedWaypoint);
                      }
                    }
                    if (this.webviewService.IsGPSTWebview()) {
                      this.webviewService.RouteUpdate("");
                    }
                  }
                  /** Invalid response error */
                  else if (resp == null || resp.route.length == 0 || resp.route == undefined) {
                    this.setLoadingMap(null);
                    this.failedPlan = true;
                    this.inputParamsService.setFailedPlan({ failedPlan: true, maxReachedWaypoint: 0 });
                    this.inputParamsService.setOpenGoogleUrl(false);
                    this._ngZone.run(() => this.openErrorDialog(this.languageService.languageJSON.App_ErrorDialog_CannotCalculateRoute, "/assets/icons/" + this.settingsService.getMap().Skin + "/error/popup_icon_nomap.svg"));
                    this.navigationRecalculateRoute = false;

                    if (this.inputParamsService.getSearchedRangeOrRoute() == false && resp?.waypointsInfo) {
                      for (let i = 0; i < resp.waypointsInfo.length; i++) {
                        (resp.waypointsInfo[i] as WaypointAddress).Location = polyParams.Params.Waypoints[i];
                      }
                      this.inputParamsService.setReverseGeocodedLocations(resp.waypointsInfo);
                    }
                    if (this.inputParamsService.getSelectedMode() == "rha") {
                      this.inputParamsService.setWaypointParams([this.inputParamsService.getWaypointsParams()[0]]);
                    }
                    if (this.mapComponent) {
                      this.mapComponent.drawFailedRouteToMap(0);
                    }
                    if (this.webviewService.IsGPSTWebview()) {
                      this.webviewService.RouteUpdate("");
                    }
                  }
                  /** Successful calculation */
                  else {
                    this.setLoadingMap(null);
                    this.failedPlan = false;
                    this.inputParamsService.setFailedPlan({ failedPlan: false, maxReachedWaypoint: 0 });
                    if (this.inputParamsService.AcceptCookies) {
                      localStorage.setItem("waypoints", JSON.stringify(polyParams.Params.Waypoints));
                    }
                    const routeData: Route = this.dataParserService.parseRouteData(resp);

                    if (this.inputParamsService.getSearchedRangeOrRoute() == false) {
                      for (let i = 0; i < routeData.WaypointsAddresses.length; i++) {
                        routeData.WaypointsAddresses[i].Location = polyParams.Params.Waypoints[i];
                      }
                      this.inputParamsService.setReverseGeocodedLocations(routeData.WaypointsAddresses);
                    }
                    if (this.inputParamsService.getOpenGoogleUrl() == true) {
                      this.initGoogleUrl(routeData);
                    }

                    this.mapService.setLastRouteData(routeData);

                    if (!this.navigation) {
                      this.mapComponent.drawRouteToMap(routeData);
                      if (this.feedbackCounter != -1 && this.feedbackCounter < 30) {
                        this.feedbackCounter++;
                        localStorage.setItem("feedbackCounter", this.feedbackCounter.toString());
                        if (this.feedbackCounter == 30) {
                          timer(10000).pipe(first()).subscribe(() => {
                            if (this.navigation) {
                              this.feedbackCounter--;
                            }
                            else {
                              this.openFeedbackDialog();
                            }
                          });
                        }
                      }
                    }
                    else {
                      this.navigationRecalculateRoute = false;
                      this.navigationComponent.setPlannedRoute(routeData);
                    }

                    if (!this.navigation) {
                      this.mapComponent.initWind(routeData.Wind);
                    }
                    this.setWindComponent(routeData.Wind, routeData.Temperature, routeData.WeatherCode);

                    if (this.webviewService.IsGPSTWebview()) {
                      this.webviewService.RouteUpdate(JSON.stringify(resp));
                    }
                  }
                  this.navigationService.skipChargingStation = false;
                  this.setMobileVisibleRoutePanel('routepoints');
                  this.cdr.detectChanges();
                },
                error:
                  (error) => {
                    if (this.navigation) {
                      timer(5000).pipe(first()).subscribe(() => {
                        this.getRoute(this.inputParamsService.getRangeParams());
                      });
                    }
                    else {
                      this.setLoadingMap(null);
                      this.failedPlan = true;
                      this.inputParamsService.setOpenGoogleUrl(false);
                      this.inputParamsService.setFailedPlan({ failedPlan: true, maxReachedWaypoint: 0 });
                      this._ngZone.run(() => this.openErrorDialog(this.languageService.languageJSON.App_ErrorDialog_CannotCalculateRoute, "/assets/icons/" + this.settingsService.getMap().Skin + "/error/popup_icon_nomap.svg"));
                      this.navigationRecalculateRoute = false;
                      if (this.mapComponent) {
                        this.mapComponent.drawFailedRouteToMap(0);
                      }
                      this.cdr.detectChanges();
                    }
                    if (this.webviewService.IsGPSTWebview()) {
                      this.webviewService.RouteUpdate("");
                    }
                  }
              });
          }
        }
      }
    }
  }

  private initGoogleUrl(routeData: Route): void {
    this.inputParamsService.setOpenGoogleUrl(false);
    let googleUrl = `https://www.google.com/maps/dir/?api=1&destination=${routeData.RoutePoints[routeData.RoutePoints.length - 1].Location.lat},${routeData.RoutePoints[routeData.RoutePoints.length - 1].Location.lng}&travelmode=driving&waypoints=`;
    routeData.RoutePlan.forEach((routePlanElement) => {
      if (routePlanElement.Type == RoutePlanType.Charger || routePlanElement.Type == RoutePlanType.WayPoint) {
        if (googleUrl.endsWith("waypoints=") == false) {
          googleUrl += "%7C";
        }
        googleUrl += routePlanElement.Location.lat + "," + routePlanElement.Location.lng;
      }
    });

    routeData.GoogleUrl = googleUrl;
    if (this.webviewService.IsGPSTWebview() && device.ios()) {
      this.webviewService.OpenUrl(googleUrl);
    }
    else {
      this.openUrl(googleUrl);
    }
  }

  private setLoadingMap(loading: string): void {
    if (this.loadingMap == "line" && loading != "line" && !this.navigation) {
      this.mapComponent.polyLayer.clearLayers();
      this.mapComponent.markerLayer.clearLayers();
      this.mapComponent.clearWind();
    }
    this.loadingMap = loading;
    this.cdr.detectChanges();
  }

  public loadingHistoryChanged(loading: boolean): void {
    if (loading) {
      this.setLoadingMap("circle");
    }
    else {
      this.setLoadingMap(null);
    }
  }

  //Mobile resolution
  public setMobileVisiblePanel(panelName: string): void {
    // reposition map during opening/closing car sliders
    if (panelName === "car" && !this.navigation) {
      this.mapComponent.positionMapOnSlidersPanelOpen();
    }
    if ((this.mobileVisiblePanel === "car" || this.mobileVisiblePanel === "car-open") && !this.navigation) {
      this.mapComponent.positionMapOnSlidersPanelClose();
    }
    this.mobileVisiblePanel = panelName;
    this.mobileResolutionService.setMobileVisiblePanel(panelName);
    this.cdr.detectChanges();
  }

  private setMobileVisibleRoutePanel(panelName: string): void {
    this.mobileVisibleRoutePanel = panelName;
    this.mobileResolutionService.setMobileVisibleRoutePanel(panelName);
    this.cdr.detectChanges();
  }

  private openUrl(url: string): void {
    if (device.ios() && !device.desktop()) {
      //chrome
      if (navigator.userAgent.match('CriOS')) {
        window.open(url, "_blank");
      }
      //safari
      else {
        location.assign(url);
      }
    }
    else {
      window.open(url, "_blank");
    }
  }

  private resolveOngoingHttpCall(): void {
    if (this.httpSub && !this.httpSub.closed) {
      this.httpSub.unsubscribe();
      this.setLoadingMap(null);
    }
  }

  // changing wind-temperature component mode
  public ChangeManActMode(): void {
    if (this.manactText == "Man") {
      this.manactText = "Act";
      this.manactTextX = 27;
    }
    else {
      this.mapComponent.windDatas = [];
      this.mapComponent.canvasLayer.needRedraw();
      this.manactText = "Man";
      this.manactTextX = 24;
    }
    if (this.inputParamsService.AcceptCookies) {
      localStorage.setItem("manactText", this.manactText);
    }
    // reload rha/route with wind
    this.resolveOngoingHttpCall();
    if (this.inputParamsService.getRangeParams().Waypoints.length >= 2) {
      this.getRoute(this.inputParamsService.getRangeParams());
    }
    else {
      this.getPolys(this.inputParamsService.getRangeParams());
    }
  }

  private setWindComponent(windElements: WindElement[], temperature: number, weathercode: number): void {
    const StartCoordsParams = this.inputParamsService.getStartCoordsParams();
    let closestIdx = 0;
    let closestDist = this.utilsService.distanceBetweenCoordinates([StartCoordsParams.lat, StartCoordsParams.lng],
      [windElements[0].Location.lat, windElements[0].Location.lng]);
    // search for the closest wind to startpoint
    for (let i = 1; i < windElements.length; i++) {
      if (this.utilsService.distanceBetweenCoordinates([StartCoordsParams.lat, StartCoordsParams.lng],
        [windElements[i].Location.lat, windElements[i].Location.lng]) < closestDist) {
        closestIdx = i;
        closestDist = this.utilsService.distanceBetweenCoordinates([StartCoordsParams.lat, StartCoordsParams.lng],
          [windElements[i].Location.lat, windElements[i].Location.lng]);
      }
    }

    this.windTabComponent.setTemperature(temperature, weathercode, windElements[closestIdx].Angle, windElements[closestIdx].Speed);
  }

  /** Dialogs */
  private openWelcomeScreenDialog(): void {
    this.stackedModalsService.openModal(WelcomeDialogComponent, {
      autoFocus: false,
      panelClass: ['dialog-snap-to-fullscreen'],
      data: { activeComponent: "welcome" }
    });
    this.stackedModalsService.openModal(CarSelectorDialogComponent, {
      data: {},
      autoFocus: false,
      panelClass: ['car-selector-outer', 'dialog-snap-to-fullscreen']
    });

    if (!this.webviewService.IsGPSTWebview()) {
      let modalStackRef = this.stackedModalsService.ObservableModalStackLenght.subscribe((resp) => {
        if (resp == 0) {
          this.cookiePolicyAgreed = false;
          this.cdr.detectChanges();
          modalStackRef.unsubscribe();
        }
      });
    }
  }

  public openSubscriptionsPromo() {
    this.stackedModalsService.openModal(SubscriptionsPromoDialogComponent, {
      autoFocus: false,
      panelClass: 'dialog-snap-to-fullscreen'
    });
  }

  public openChargerUpdateInfoDialog() {
    this.stackedModalsService.openModal(ChargerUpdateInfoDialogComponent, {
      autoFocus: false,
      panelClass: 'dialog-snap-to-fullscreen'
    });
  }

  // open privacy policy dialog modal
  public openPrivacyPolicyDialog(): void {
    this.stackedModalsService.openModal(TabbedInfoDialogComponent, {
      panelClass: 'dialog-snap-to-fullscreen',
      autoFocus: false,
      data: { openMenu: "privacy" }
    });
  }

  private openSkipChargingStationFailedDialog(): void {
    this._ngZone.run(() => {
      this.matDialog.open(ConfirmationDialogComponent,
        {
          data: {
            title: this.languageService.languageJSON.Global_Alert,
            message: this.languageService.languageJSON.App_CannotSkipChargingation,
            icon: "/assets/icons/" + this.settingsService.getMap().Skin + "/error/skip_chargingstation_image.svg",
            buttons: [this.languageService.languageJSON.Global_Ok, null]
          }, autoFocus: false
        });
    });
  }

  // open error dialog modal
  private openErrorDialog(title: string, icon: string, message: string = null): void {
    if ((!this.navigation || this.navigationRecalculateRoute) &&
      document.getElementsByClassName("mat-dialog-title").length == 0) {
      let war: string = this.selectedMode == "route" && this.inputParamsService.getECarSettings().batteryLevel < 10 ? this.languageService.languageJSON.App_WarningLowBattery : null;
      this._ngZone.run(() => {
        this.errorDialog.open(ConfirmationDialogComponent, { data: { title: title, icon: icon, message: message, warning: war, buttons: [this.languageService.languageJSON.Global_Ok, null] }, autoFocus: false });
      });

      if (this.navigation) {
        this.navigationService.ObservableChangeNavigation.next("exit");
      }
    }
  }

  private openGpsErrorDialog(): void {
    if (document.getElementsByClassName("mat-dialog-title").length == 0 && !this.webviewService.IsGPSTWebview()) {
      this._ngZone.run(() => { let dialogRef = this.errorDialog.open(GpsErrorDialogComponent); });
    }
  }

  public openFeedbackDialog(): void {
    this.stackedModalsService.openModal(TabbedInfoDialogComponent, {
      panelClass: 'dialog-snap-to-fullscreen',
      autoFocus: false,
      data: { openMenu: "feedback" }
    });
  }

  // change unit
  public openSettings(addressType?): void {
    this.stackedModalsService.openModal(SettingsDialogComponent,
      { panelClass: 'dialog-snap-to-fullscreen', disableClose: true, autoFocus: false, data: addressType });
  }

  private openHistory(historyElement): void {
    this.stackedModalsService.openModal(HistoryDialogComponent,
      { panelClass: 'dialog-snap-to-fullscreen', disableClose: true, autoFocus: false, data: historyElement });
  }

  // enable fullscreen mode
  public toggleFullscreen(): void {
    if (screenfull && screenfull.isEnabled) {
      screenfull.toggle();
    }
    this.cdr.detectChanges();
  }

  public windHiderClick(): void {
    this.windHider = !this.windHider;
    if (!this.windHiderAnim) {
      this.windHiderAnim = true;
    }
    else {
      setTimeout(() => {
        this.windHiderAnim = false;
      }, 400);
    }
  }

  public setStartCoordsToYourGeolocation(): void {
    if (!this.navigation) {
      this.inputParamsService.setStartCoordsToYourGeolocation().subscribe((resp) => {
        if (resp == "nogps") {
          this._ngZone.run(() => this.openGpsErrorDialog());
        }
        else {
          const startcoord = this.inputParamsService.getStartCoordsParams();
          if (this.mapComponent) {
            this.mapComponent.map.flyTo(startcoord);
          }

          this.mapService.getReverseGeocode(startcoord.lat, startcoord.lng).subscribe((reversegeocode) => {
            if (reversegeocode) {
              this.inputParamsService.ObservableSetStartPointGeocode.next({
                display_name: reversegeocode,
                lat: startcoord.lat,
                lng: startcoord.lng,
                wp: 0
              });
            }
          }, () => { });
        }
      });
    }
    else {
      this.navigationComponent.setToCenter();
    }
  }

  public start

  public hudOpenedChangedEvent(event: boolean): void {
    this.hudOpened = event;
    this.cdr.detectChanges();
  }

  public computersChangedEvent(event: number): void {
    this.hudComputersLength = event;
    this.cdr.detectChanges();
  }

  public acceptCookies(): void {
    this.cookiePolicyAgreed = true;
    localStorage.setItem("cookiesAccepted", "true");
  }

  // unsubscribe when element destroy
  ngOnDestroy() {
    this.mapService.setLastCoordinates(this.mapComponent.map.getCenter().lat, this.mapComponent.map.getCenter().lng);
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];
  }
}
