import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountService } from 'src/app/services/account.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { LanguageService } from 'src/app/services/language.service';
import { MapService } from 'src/app/services/map.service';
import { SettingsService } from 'src/app/services/settings.service';
import { MapElementType } from '../../map/map.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { HistoryElement } from '../../../models/history';
import { ECar } from 'src/app/models/ecar';

/**
   * display connected user previous trips
   */
@Component({
  selector: 'app-history-dialog',
  templateUrl: './history-dialog.component.html',
  styleUrls: ['./history-dialog.component.scss']
})

export class HistoryDialogComponent implements OnInit {

  public historyLoader: boolean = false;
  public historyList: HistoryElement[];
  public selectedUserVehicle: ECar;

  constructor(public dialogRef: MatDialogRef<HistoryDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public languageService: LanguageService, public accountService: AccountService,
    public settingsService: SettingsService, private _ngZone: NgZone, private matDialog: MatDialog,
    private mapService: MapService, private consoleLoggerService: ConsoleLoggerService) { }

  ngOnInit(): void {
  }

  public setSelectedUserVehicle(selectedECar: ECar): void {
    // setTimeout for fixing ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.historyLoader = true;
      this.accountService.getHistory(selectedECar.userVehicleID).subscribe((resp) => {
        if (resp) {
          this.consoleLoggerService.log(resp);
          this.historyList = resp;
          this.historyLoader = false;
          this.selectedUserVehicle = selectedECar;
          const openedTracks = this.historyList.length > 4 ? 4 : this.historyList.length;
          for (let i = 0; i < openedTracks; i++) {
            this.historyList[i].opened = true;
          }
        }
      });
    });
  }

  public openRouteHistoryOnMap(historyElement: HistoryElement): void {
    this.accountService.setHistoryLastSelectedVehicle(this.selectedUserVehicle);
    this.mapService.ObservableShowOnMap.next({ type: MapElementType.RouteHistory, data: historyElement });
    this.dialogRef.close();
  }

  public removeHistoryElement(event, historyItem: HistoryElement): void {
    event.stopPropagation();
    this._ngZone.run(() => {
      let dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          title: this.languageService.languageJSON.HistoryDialog_Delete_Title,
          message: this.languageService.languageJSON.HistoryDialog_Delete_Desc,
          buttons: [this.languageService.languageJSON.Global_Delete, this.languageService.languageJSON.Global_Cancel]
        },
        panelClass: 'delete-confirmation',
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.consoleLoggerService.log(historyItem.id);
          this.accountService.deleteLogById(this.selectedUserVehicle.userVehicleID, historyItem.id).subscribe(() => {
            this.setSelectedUserVehicle(this.selectedUserVehicle);
          });
        }
      });
    });
  }

  public removeAllHistoryElement(): void {
    this._ngZone.run(() => {
      let dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          title: this.languageService.languageJSON.HistoryDialog_DeleteAll_Title,
          message: this.languageService.languageJSON.HistoryDialog_DeleteAll_Desc,
          buttons: [this.languageService.languageJSON.Global_Delete, this.languageService.languageJSON.Global_Cancel]
        },
        panelClass: 'delete-confirmation',
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.accountService.deleteLogByVehicle(this.selectedUserVehicle.userVehicleID).subscribe(() => {
            this.setSelectedUserVehicle(this.selectedUserVehicle);
          });
        }
      });
    });
  }

  public close(): void {
    this.dialogRef.close();
  }
}
