import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-charger-update-info-dialog',
  templateUrl: './charger-update-info-dialog.component.html',
  styleUrl: './charger-update-info-dialog.component.scss'
})
export class ChargerUpdateInfoDialogComponent {
  constructor(public dialogRef: MatDialogRef<ChargerUpdateInfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public languageService: LanguageService) {

  }

  ngOnInit(): void {
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public action(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    localStorage.setItem("chargerUpdateInfo", "true");
  }
}
