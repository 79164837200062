import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { first, fromEvent, Observable, skip } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-operator-search',
  templateUrl: './operator-search.component.html',
  styleUrls: ['./operator-search.component.scss']
})
export class OperatorSearchComponent implements OnInit {

  @Input() OperatorList: any[];
  @Output() operatorSelectedEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('searchInput') searchInput: any;
  private firstClick = fromEvent(document, 'mousedown').pipe(skip(1));
  private searchDropdownListener;

  public inputValue: string;
  public dropdownVisibility: boolean = false;

  constructor(public languageService:LanguageService) { }

  ngOnInit(): void {
  }

  public searchActivated(): void {
    this.dropdownVisibility = true;

    if (this.searchDropdownListener) {
      this.searchDropdownListener.unsubscribe();
    }

    this.searchDropdownListener = this.firstClick.subscribe((val: any) => {
      this.dropdownVisibility = false;
      this.searchDropdownListener.unsubscribe();
    });
  }

  public addOperator(operatorEl: any): void {
    this.operatorSelectedEvent.emit(operatorEl);
    this.dropdownVisibility = false;
  }

  ngOnDestroy(): void {
    if (this.searchDropdownListener) {
      this.searchDropdownListener.unsubscribe();
    }
  }
}
