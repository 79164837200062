import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef, NgZone, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AccountService } from 'src/app/services/account.service';
import { HttpClient } from '@angular/common/http';
import { TabbedInfoDialogComponent } from '../tabbed-info-dialog/tabbed-info-dialog.component';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgScrollbar } from 'ngx-scrollbar';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { UtilsService } from 'src/app/services/utils.service';

import { environment } from 'src/environments/environment';
import { WebviewService } from 'src/app/services/webview.service';
import { skip } from 'rxjs/operators';
import device from 'current-device';
import { SettingsService } from 'src/app/services/settings.service';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';
import { UserProfile } from 'src/app/models/user-profile';

@Component({
  selector: 'app-account-dialog',
  templateUrl: './account-dialog.component.html',
  styleUrls: ['./account-dialog.component.scss']
})
export class AccountDialogComponent implements OnInit {

  @HostListener('window:resize') onResize() {
    this.initGoogleButton();
  }
  @ViewChild('accountScrollbar', { static: true }) accountScrollbar: NgScrollbar;

  public activeStepIndex: number;
  public accountDialogTitles: string[] = [];
  public firstStepError: string = "";
  public registerError: boolean = false;
  public countryList: { name: string, dial_code: string, code: string }[] = [];
  public invalidImgType: boolean = false;
  public invalidImgSize: boolean = false;
  public avatarImg: string = null;
  public backendErrorMsg: string = null;
  public emailSent: boolean = false;
  public invalidActualPassword: boolean = false;
  public existingEmailMsg: string = null;
  public registerLoader: boolean = false;
  public resendEmailAddress: string = null;
  public verifyFailed: boolean = false;
  public passwordChangeSuccess: boolean = false;
  public preiOS13WebView: boolean = false;
  public androidWebView: boolean = false;

  /* form groups */
  public firstStepFormGroup: FormGroup;
  public secondStepFormGroup: FormGroup;
  public thirdStepFormGroup: FormGroup;
  public sixthStepFormGroup: FormGroup;
  public seventhStepFormGroup: FormGroup;

  private subscriptions: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<AccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public accountService: AccountService,
    private http: HttpClient, private matDialog: MatDialog, private fb: FormBuilder,
    private utilsSerivce: UtilsService, private cdr: ChangeDetectorRef, private _ngZone: NgZone,
    public webviewService: WebviewService, public settingsService: SettingsService,
    public languageService: LanguageService) {
  }

  /* set variables, load countrylist */
  ngOnInit() {
    this.accountDialogTitles =
      [this.languageService.languageJSON.Global_Account,
      this.languageService.languageJSON.AccountDialog_Registration,
      this.languageService.languageJSON.AccountDialog_Registration,
      this.languageService.languageJSON.AccountDialog_Registration,
      this.languageService.languageJSON.AccountDialog_Registration,
      this.languageService.languageJSON.Global_Account,
      this.languageService.languageJSON.AccountDialog_ChangePassword,
      this.languageService.languageJSON.AccountDialog_ForgotPassword];

    this.firstStepFormGroup = this.fb.group({
      loginEmail: ['', [
      ]],
      loginPassword: ['', [
      ]],
      rememberMe: ['', [
      ]]
    });

    this.firstStepFormGroup.valueChanges.subscribe(val => {
      this.firstStepError = "";
    });

    this.secondStepFormGroup = this.fb.group({
      registerEmail: ['', [
        Validators.required,
        Validators.email
      ]],
      registerPassword: ['', [
        Validators.required,
        Validators.pattern(/^(?=.*\d)(?=.*[A-Za-z])(?!.*\s).{8,200}$/)
      ]],
      registerPasswordConfirmation: ['', [
        Validators.required,
        Validators.pattern(/^(?=.*\d)(?=.*[A-Za-z])(?!.*\s).{8,200}$/)
      ]],
      registerNewsletter: ['', [
      ]]
    }, { validators: this.checkPasswords });

    this.thirdStepFormGroup = this.fb.group({
      registerFirstName: ['', [
        Validators.required,
        Validators.pattern(/^.{2,64}$/)
      ]],
      registerLastName: ['', [
        Validators.required,
        Validators.pattern(/^.{2,64}$/)
      ]],
      registerCountry: ['', [
        Validators.pattern(/^[a-zA-Z ]{2}$/)
      ]],
      registerPhone: ['', [
        Validators.pattern(/^[-0-9+/ ()]*$/)
      ]]
    });

    this.sixthStepFormGroup = this.fb.group({
      actualPassword: ['', [
        Validators.required
      ]],
      newPassword: ['', [
        Validators.required,
        Validators.pattern(/^(?=.*\d)(?=.*[A-Za-z])(?!.*\s).{8,200}$/)
      ]],
      newPasswordConfirmation: ['', [
        Validators.required,
        Validators.pattern(/^(?=.*\d)(?=.*[A-Za-z])(?!.*\s).{8,200}$/)
      ]]
    }, { validators: this.checkNewPasswords });

    this.seventhStepFormGroup = this.fb.group({
      recoveryEmail: ['', [
        Validators.required,
        Validators.email
      ]]
    })

    this.http.get("../../assets/json/countries_en.json").subscribe((resp: any) => {
      this.countryList = resp;
    });

    this.activeStepIndex = 0;
    if (this.data.process == "profileEdit") {
      this.initEditProfile();
    }
    if (this.data.process == "verifySuccess") {
      this.activeStepIndex = 4;
    }
    if (this.data.process == "verifyFailed") {
      this.activeStepIndex = 4;
      this.verifyFailed = true;
    }

    this.subscriptions.push(this.accountService.ObservableSocialLoginResponse.pipe(skip(1)).subscribe((loginResponse) => {
      if (loginResponse) {
        if (loginResponse.AuthResponse) {
          this._ngZone.run(() => {
            this.close();
          })

        }
        if (loginResponse.ErrorMessage) {
          this._ngZone.run(() => {
            this.matDialog.open(ConfirmationDialogComponent,
              {
                data: {
                  title: this.languageService.languageJSON.Global_Error,
                  message: loginResponse.ErrorMessage,
                  buttons: [this.languageService.languageJSON.Global_Ok, null]
                }, autoFocus: false
              }).afterClosed().subscribe((resp) => { this.accountService.logout() });
          });
        }
      }
    }));

    this.preiOS13WebView = this.webviewService.IsGPSTWebview() && device.ios() && !device.desktop() &&
      (navigator.userAgent.includes('OS 12') || navigator.userAgent.includes('OS 11') ||
        navigator.userAgent.includes('OS 10') || navigator.userAgent.includes('OS 9') ||
        navigator.userAgent.includes('OS 8') || navigator.userAgent.includes('OS 7'));

    this.androidWebView = this.webviewService.IsGPSTWebview() && device.android();
  }

  ngAfterViewInit(): void {
    if (this.activeStepIndex == 5) {
      this.initAvatar();
    }

    this.initGoogleButton();
  }

  private initGoogleButton(): void {
    if (!this.webviewService.IsGPSTWebview()) {
      this.cdr.detectChanges();
      if (document.getElementsByClassName("facebook-btn")[0]) {
        let languageCode = this.settingsService.getLanguage()
        if (languageCode == "se") {
          languageCode = "sv";
        }

        window['google'].accounts.id.renderButton(
          document.getElementById("google-button"),
          {
            theme: "filled_blue", size: "large", width: document.getElementsByClassName("facebook-btn")[0].clientWidth,
            logo_alignment: "center", locale: languageCode
          }
        );
      }
    }
  }

  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private initEditProfile(): void {
    this.activeStepIndex = 5;
    const userProfile = this.accountService.getUserProfile();

    this.thirdStepFormGroup.addControl('registerNewsletter', new FormControl());
    this.thirdStepFormGroup.setValue({
      registerFirstName: userProfile.Profile.FirstName ? userProfile.Profile.FirstName : "",
      registerLastName: userProfile.Profile.LastName ? userProfile.Profile.LastName : "",
      registerCountry: userProfile.Profile.Country ? userProfile.Profile.Country : "",
      registerPhone: userProfile.Profile.Phone ? userProfile.Profile.Phone : "",
      registerNewsletter: userProfile.Profile.Newsletter ? userProfile.Profile.Newsletter : ""
    });

    this.registerFirstName.markAllAsTouched();
    this.registerLastName.markAllAsTouched();

    this.avatarImg = userProfile.Profile.Avatar;
  }

  private checkPasswords(group: FormGroup): { notSame: boolean } {
    let pass = group.get('registerPassword').value;
    let confirmPass = group.get('registerPasswordConfirmation').value;

    return pass === confirmPass ? null : { notSame: true }
  }

  private checkNewPasswords(group: FormGroup): { notSame: boolean } {
    let pass = group.get('newPassword').value;
    let confirmPass = group.get('newPasswordConfirmation').value;

    return pass === confirmPass ? null : { notSame: true }
  }

  get loginEmail() {
    return this.firstStepFormGroup.get("loginEmail");
  }

  get loginPassword() {
    return this.firstStepFormGroup.get("loginPassword");
  }

  get rememberMe() {
    return this.firstStepFormGroup.get("rememberMe");
  }

  get registerEmail() {
    return this.secondStepFormGroup.get("registerEmail");
  }

  get registerPassword() {
    return this.secondStepFormGroup.get("registerPassword");
  }

  get registerPasswordConfirmation() {
    return this.secondStepFormGroup.get("registerPasswordConfirmation");
  }

  get registerPasswordsMatch() {
    return this.secondStepFormGroup.hasError('notSame');
  }

  get registerNewsletter() {
    return this.secondStepFormGroup.get("registerNewsletter");
  }

  get registerFirstName() {
    return this.thirdStepFormGroup.get("registerFirstName");
  }

  get registerLastName() {
    return this.thirdStepFormGroup.get("registerLastName");
  }

  get registerCountry() {
    return this.thirdStepFormGroup.get("registerCountry");
  }

  get registerPhone() {
    return this.thirdStepFormGroup.get("registerPhone");
  }

  get actualPassword() {
    return this.sixthStepFormGroup.get("actualPassword");
  }

  get newPassword() {
    return this.sixthStepFormGroup.get("newPassword");
  }

  get newPasswordConfirmation() {
    return this.sixthStepFormGroup.get("newPasswordConfirmation");
  }

  get newPasswordsMatch() {
    return this.sixthStepFormGroup.hasError('notSame');
  }

  get recoveryEmail() {
    return this.seventhStepFormGroup.get("recoveryEmail");
  }

  get secondFormGroupValid() {
    return this.secondStepFormGroup.valid;
  }

  get thirdFormGroupValid() {
    return this.thirdStepFormGroup.valid;
  }

  get sixthFormGroupValid() {
    return this.sixthStepFormGroup.valid;
  }

  get seventhFormGroupValid() {
    return this.seventhStepFormGroup.valid;
  }

  /* avatar image upload */
  public onAvatarChange(event): void {
    this.invalidImgSize = false;
    this.invalidImgType = false;
    const file = event.target.files[0];
    if (file) {
      // valid image
      if (file.size / 1024 / 1024 <= 5 && (file.type == "image/png" || file.type == "image/jpeg")) {
        let reader = new FileReader();

        reader.addEventListener("load", () => {
          // convert image file to base64 string
          this.avatarImg = file;
          document.getElementById("avatar-img").setAttribute("style", "display: none");
          document.getElementById("avatar-div").setAttribute("style", "background-image:url(" + reader.result + ");");
        }, false);

        if (file) {
          reader.readAsDataURL(file);
        }
      }
      // invalid upload (>5mb, !jpeg !png)
      else {
        if (!(file.size / 1024 / 1024 <= 5)) {
          this.invalidImgSize = true;
        }
        if (!(file.type == "image/png" || file.type == "image/jpeg")) {
          this.invalidImgType = true;
        }
      }
    }
    // empty selection
    else {
      this.invalidImgType = false;
    }
  }

  /* remove avatar img */
  public deleteAvatarImg(): void {
    this.avatarImg = null;
    (document.getElementById("avatarimg") as any).value = '';
    document.getElementById("avatar-img").setAttribute("style", "display:none");
    document.getElementById("avatar-div").setAttribute("style", "display:none");
  }

  private initAvatar(): void {
    if (this.avatarImg) {
      document.getElementById("avatar-img").setAttribute("style", "");
      document.getElementById("avatar-img").setAttribute("src", environment.API_URL + "storage" + this.avatarImg);
    }
  }

  /* signing in a user */
  public signIn(): void {
    const login = {
      email: this.loginEmail.value,
      password: this.loginPassword.value,
      rememberMe: this.rememberMe.value
    };
    this.registerLoader = true;
    this.accountService.login(login).subscribe((resp) => {
      this.registerLoader = false;
      if (resp.failed && resp.failed == "USER_ERR_205") {
        this.resendEmailAddress = this.loginEmail.value;
        this.activeStepIndex = 3;
      }
      else {
        this.dialogRef.close();
      }
    },
      (error) => {
        this.registerLoader = false;
        if (error && error.name === "TimeoutError") {
          this.firstStepError = this.languageService.languageJSON.Global_SomethingWentWrong;
        }
        else {
          this.firstStepError = this.languageService.languageJSON.AccountDialog_SignIn_Error;
        }
      });
  }

  /**
   * take to the next step of the form
   * validate user inputs
   */
  public nextStep(): void {
    if (!this.registerLoader) {
      if ((this.activeStepIndex == 1 && !this.secondFormGroupValid)) {
        this.secondStepFormGroup.markAllAsTouched();
      }
      else if (this.activeStepIndex == 2 && !this.thirdFormGroupValid) {
        this.thirdStepFormGroup.markAllAsTouched();
      }
      else {
        if (this.activeStepIndex == 2) {
          this.registerUser();
        }
        else {
          if (this.activeStepIndex == 1) {
            this.registerLoader = true;
            this.registerError = false;
            this.accountService.isExist(this.registerEmail.value).subscribe((resp) => {
              this.registerLoader = false;
              if (resp.success && resp.success.message == "USER_MSG_001") {
                this.existingEmailMsg = this.registerEmail.value + " " + this.languageService.languageJSON.AccountDialog_Email_Taken;
              }
              if (resp.failed && resp.failed.error == "USER_ERR_201") {
                this.existingEmailMsg = null;
                this.activeStepIndex++;
                this.accountService.getCountry().subscribe((resp) => {
                  this.registerCountry.setValue(resp.success);
                })
              }
            },
              (error) => {
                this.registerLoader = false;
                this.registerError = true;
              });
          }
          else {
            this.activeStepIndex++;

            if (this.activeStepIndex == 1) {
              this.initGoogleButton();
            }
          }
        }
        this.accountScrollbar.scrollTo({ top: 0, duration: 0 });
        this.cdr.detectChanges();
      }
    }
  }

  /* go back to the form previous step */
  public goBack(): void {
    if (!this.registerLoader) {
      this.activeStepIndex--;
      this.backendErrorMsg = null;
      this.cdr.detectChanges();
      if (this.activeStepIndex == 5) {
        this.initAvatar();
      }
      if (this.activeStepIndex == 1 || this.activeStepIndex == 0) {
        this.initGoogleButton();
      }
    }
  }

  /* register a user */
  public registerUser(): void {
    let profile = new UserProfile;
    profile = {
      Email: this.registerEmail.value,
      FirstName: this.registerFirstName.value,
      LastName: this.registerLastName.value,
      Country: this.registerCountry.value,
      Phone: this.registerPhone.value,
      Avatar: this.avatarImg,
      Newsletter: this.registerNewsletter.value
    };

    this.registerLoader = true;
    this.registerError = false;
    this.accountService.registerUser(profile, this.registerPassword.value).subscribe((resp) => {
      this.registerLoader = false;
      if (resp && resp.success) {
        this.resendEmailAddress = this.registerEmail.value;
        this.activeStepIndex++;
      }
    },
      (error) => {
        this.registerLoader = false;
        if (error && error.name === "TimeoutError") {
          this.registerError = true;
        }
        if (error && error.failed) {
          this.backendErrorMsg = error.failed.error;
        }
      });
  }

  /* sending a new email with the verification code */
  public resendEmail(): void {
    this.registerError = false;
    this.registerLoader = true;
    this.accountService.resendActivationEmail(this.resendEmailAddress).subscribe((resp) => {
      this.registerLoader = false;
      if (resp.success) {
        this.emailSent = true;
      }
    }, (error) => {
      this.registerLoader = false;
      this.registerError = true;
    });
  }

  /* save a modified profile */
  public saveProfile(): void {
    this.thirdStepFormGroup.markAllAsTouched();
    this.registerError = false;
    this.backendErrorMsg = null;
    this.cdr.detectChanges();
    if (this.thirdFormGroupValid) {
      let profile = new UserProfile;
      profile = {
        Email: this.registerEmail.value,
        FirstName: this.registerFirstName.value,
        LastName: this.registerLastName.value,
        Country: this.registerCountry.value || "",
        Phone: this.registerPhone.value,
        Avatar: this.avatarImg,
        Newsletter: this.thirdStepFormGroup.get("registerNewsletter").value
      };

      this.registerLoader = true;
      this.accountService.setUser(profile).subscribe((resp) => {
        this.registerLoader = false;
        if (resp && resp.success) {
          this.dialogRef.close();
        }
      },
        (error) => {
          this.registerLoader = false;
          if (error && error.name === "TimeoutError") {
            this.registerError = true;
          }
          else if (error && error.error && error.error.error && error.error.error.avatar) {
            this.backendErrorMsg = error.error.error.avatar;
            this.cdr.detectChanges();
          }
          else if (error && error.status && error.status == 406) {
            this.backendErrorMsg = error.error.error;
            this.cdr.detectChanges();
          }
          else if (error && error.status && error.status == 401) {
            this.dialogRef.close();
          }
        });
    }
  }

  public deleteProfile(): void {
    this._ngZone.run(() => {
      // delete data global warning message
      let deleteData: { title: string, message: string, warning: string, buttons: string[] } = {
        title: this.languageService.languageJSON.AccountDialog_DeleteAccount,
        message: this.languageService.languageJSON.AccountDialog_DeleteAccount_Message,
        warning: this.languageService.languageJSON.AccountDialog_DeleteAccount_Warning,
        buttons: [this.languageService.languageJSON.Global_Delete, this.languageService.languageJSON.Global_Cancel]
      };

      let dialogRef = this.matDialog.open(ConfirmationDialogComponent, { data: deleteData, panelClass: 'delete-confirmation', autoFocus: false });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (this.accountService.user.Subscriptions.length > 0) {
            // delete data subscription warning message
            let deleteWarningData: { title: string, message: string, warning: string, buttons: string[] } = {
              title: this.languageService.languageJSON.AccountDialog_DeleteAccount,
              message: this.languageService.languageJSON.AccountDialog_DeleteAccount_Message,
              warning: this.languageService.languageJSON.AccountDialog_DeleteAccount_SubscriptionWarning,
              buttons: [this.languageService.languageJSON.Global_Delete, this.languageService.languageJSON.Global_Cancel]
            };

            let dialogRef = this.matDialog.open(ConfirmationDialogComponent, { data: deleteWarningData, panelClass: 'delete-confirmation', autoFocus: false });

            dialogRef.afterClosed().subscribe((result) => {
              if (result) {
                this.deleteConfirmationHandler();
              }
            });
          }
          else {
            this.deleteConfirmationHandler();
          }
        }
      });
    });
  }

  private deleteConfirmationHandler(): void {
    this.registerLoader = true;
    this.registerError = false;
    this.accountService.deleteUser().subscribe((result) => {
      this.registerLoader = false;
      this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          title: this.languageService.languageJSON.AccountDialog_DeleteAccount,
          message: this.languageService.languageJSON.AccountDialog_DeleteAccount_Confirm,
          buttons: [this.languageService.languageJSON.Global_Ok, null]
        }, autoFocus: false
      });
      this.accountService.logout();
      this.dialogRef.close();
    },
      (error) => {
        this.registerLoader = false;
        if (error && error.name === "TimeoutError") {
          this.registerError = true;
        }
        else if (error && error.status && error.status == 401) {
          this.dialogRef.close();
        }
      });
  }

  public changePassword(): void {
    if (this.passwordChangeSuccess) {
      this.dialogRef.close();
    }
    else {
      if ((!this.sixthFormGroupValid)) {
        this.sixthStepFormGroup.markAllAsTouched();
      }
      else {
        const email = this.accountService.getUserProfile().Profile.Email;
        this.registerLoader = true;
        this.registerError = false;
        this.accountService.changePassword(email, this.actualPassword.value, this.newPassword.value, this.newPassword.value).subscribe((resp) => {
          this.registerLoader = false;
          if (resp.success) {
            this.invalidActualPassword = false;
            this.passwordChangeSuccess = true;
          }
        },
          (error) => {
            this.registerLoader = false;

            if (error && error.status && error.status == 401) {
              if (error.error.failed && error.error.failed.error == "USER_ERR_202") {
                this.invalidActualPassword = true;
              }
              else {
                this.dialogRef.close();
              }
            }
            if (error && error.name === "TimeoutError") {
              this.registerError = true;
            }

          });
      }
    }
  }

  public goBackToLogin(): void {
    this.activeStepIndex = 0;
    this.initGoogleButton();
  }

  public recoverPassword(): void {
    this.existingEmailMsg = null;
    if ((!this.seventhFormGroupValid)) {
      this.seventhStepFormGroup.markAllAsTouched();
    }
    else {
      this.registerLoader = true;
      this.accountService.getForgottenPassword(this.recoveryEmail.value).subscribe((resp) => {
        this.registerLoader = false;
        if (resp && resp.status == true) {
          this.emailSent = true;
          this.existingEmailMsg = null;
        }
      }, (error) => {
        this.registerLoader = false;
        if (error && error.name === "TimeoutError") {
          this.registerError = true;
        }
        if (error && error.error && error.error.message === "USER_ERR_206") {
          this.existingEmailMsg = this.recoveryEmail.value + " " + this.languageService.languageJSON.AccountDialog_ForgotPassword_Error;
        }
      });
    }
  }

  public openForgotPassword(): void {
    this.activeStepIndex = 7;
    this.emailSent = false;
    this.accountScrollbar.scrollTo({ top: 0, duration: 0 });
  }

  /* open privacy policy in a modal window */
  public openPrivacyPolicy(): void {
    this._ngZone.run(() => {
      this.matDialog.open(TabbedInfoDialogComponent, {
        panelClass: 'dialog-snap-to-fullscreen',
        autoFocus: false,
        data: { openMenu: "privacy" }
      });
    });
  }

  public openTermsOfUse(): void {
    this._ngZone.run(() => {
      this.matDialog.open(TabbedInfoDialogComponent, {
        panelClass: 'dialog-snap-to-fullscreen',
        autoFocus: false,
        data: { openMenu: "legal" }
      });
    });
  }


  /* close dialog */
  public close(): void {
    if (this.data.process == "verifySuccess") {
      window.history.pushState("", "", '/');
    }
    this.dialogRef.close();
  }
}
