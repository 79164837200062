<div id="wind-tab">
    <div class="wind-el" *ngIf="temperatureValue!=null && temperatureValue!=undefined">
        <img [src]="'assets/icons/' + settingsService.getMap().Skin + '/weather/' + temperatureImg" />
        <div class="temperature-value wind-text">
            <h6>{{ temperatureValueText }} </h6>
            <h6 class="unit-text">{{ temperatureUnit }}</h6>
        </div>
    </div>
    <div class="wind-el" *ngIf="windForce">
        <img class="wind-arrow" [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/wind_icon.svg'"
            [ngStyle]="{'transform': 'rotate(' + windDeg + 'deg)' }" />
        <div class="wind-text">
            <h6>{{ windForceText }} </h6>
            <h6 class="unit-text"> {{ windUnit }}</h6>
        </div>
    </div>
</div>